import { useNavigate } from "react-router-dom";

import "./ToolsPage.css";
import ToolsHerosection from "../../Components/toolsPage/ToolsHerosection";
import Coming_Soon from "../../Components/toolsPage/assets/coming-soon 1.png";
import Bizowl_Logo from "../../Components/toolsPage/assets/bizowl-logo.png";
import ToolCard from "../../Components/toolsPage/toolsCard/toolCard";
import prImg from "./assets/pr.png";
import img1 from "./assets/img1.png";
import { Helmet } from "react-helmet";
import NavBar from "../../Components/Header/NavBar";
import Footer from "../../Components/Footer/Footer";
import { useEffect } from "react";
import ApplyBodyStylesOnLoad from "../../Components/ApplyBodyStylesOnLoad ";

function ToolsPage() {

  const navigate = useNavigate();


  return (
    <div className="ToolsPage">
    <ApplyBodyStylesOnLoad />
      <Helmet>
        <title>
          AI Tools for Every Stage of Your Business Journey | Startup Tools
        </title>
        <meta charset="UTF-8" />
        <meta name="viewport" content="width=device-width, initial-scale=1.0" />
        <meta
          name="description"
          content="Discover powerful AI tools for every stage of your business journey. From business name generators to marketing plan creators, our startup tools help you build and grow your startup efficiently."
        />
        <meta
          name="keywords"
          content="Startup Tools, AI Tools for Startups, Business Name Generator, Press Release Generator, Marketing Plan Generator, Branding Tools, Business Planning Tools, AI Business Tools, Startup Growth Tools, AI Marketing Tools"
        />
        <meta name="robots" content="index, follow" />
        <meta name="author" content="Bizowl" />
        <meta http-equiv="Content-Language" content="en" />
        <link rel="canonical" href="https://bizzowl.com/ai-startup-tools" />
        <meta
          property="og:title"
          content="AI Tools for Every Stage of Your Business Journey | Startup Tools"
        />
        <meta
          property="og:description"
          content="Enhance your startup with AI tools designed for business planning, marketing, branding, and more. Start using our startup tools to streamline your business growth."
        />
        <meta
          property="og:image"
          content="https://bizzowl.com/path-to-image.jpg"
        />
        <meta
          property="og:url"
          content="https://bizzowl.com/ai-startup-tools"
        />
        <meta name="twitter:card" content="summary_large_image" />
        <meta
          name="twitter:title"
          content="AI Tools for Every Stage of Your Business Journey | Startup Tools"
        />
        <meta
          name="twitter:description"
          content="Leverage AI tools for your startup’s success. Our platform offers everything from business name generators to marketing plan creators, tailored for each stage of your business journey."
        />
        <meta
          name="twitter:image"
          content="https://bizzowl.com/path-to-image.jpg"
        />

        <script type="application/ld+json">
          {`{
  "@context": "https://schema.org",
  "@type": "WebPage",
  "name": "AI Tools for Every Stage of Your Business Journey",
  "url": "https://bizzowl.com/ai-startup-tools",
  "description": "Explore a wide range of AI tools designed for startups, including business name generators and press release generators. These tools are built to assist you at every stage of your business journey.",
  "mainEntity": {
    "@type": "ItemList",
    "name": "Startup AI Tools",
    "itemListElement": [
      {
        "@type": "SoftwareApplication",
        "name": "Business Name Generator",
        "url": "https://bizzowl.com/ai-startup-tools/company-name-generator",
        "applicationCategory": "Business Tool",
        "operatingSystem": "All",
        "offers": {
          "@type": "Offer",
          "price": "0.00",
          "priceCurrency": "INR"
        }
      },
      {
        "@type": "SoftwareApplication",
        "name": "Press Release Generator",
        "url": "https://bizzowl.com/ai-startup-tools/ai-press-release-generator-free",
        "applicationCategory": "Business Tool",
        "operatingSystem": "All",
        "offers": {
          "@type": "Offer",
          "price": "0.00",
          "priceCurrency": "INR"
        }
      }
    ]
  },
  "about": {
    "@type": "Thing",
    "name": "AI Tools for Startups",
    "description": "AI-powered tools to assist startups in business planning, marketing, branding, and growth."
  },
  "creator": {
    "@type": "Organization",
    "name": "Bizowl",
    "url": "https://bizzowl.com"
  }
  }`}
        </script>
      </Helmet>
        <NavBar />
      <div className="Main-section">
        <ToolsHerosection />
      </div>
      <div className="Cards-Section">
        <div className="Card-Container">
          <div className="Container-Title">
            <p>
              Business Planning <span>& Strategy</span>
            </p>
            <div className="Title-Line" id="Title-Line1"></div>
          </div>

          <div  >
            <ToolCard img={img1} 
            className="cursor-pointer"
              h1={"Business Name Generator "}
              p={" Get the first step of your business right"}
              l1={"Quick Name Generation"}
              l2={"Creative Suggestions"}
              l3={"Overcome Name Blocks"}
              l4={"Brand Alingment"}
              link={"company-name-generator"}
              // onClick={()=>navigate("/ai-startup-tools/company-name-generator")} 
              />
          </div>
        </div>
        <div className="Card-Container">
          <div className="Container-Title">
            <p>
              Public <span> Relations</span>
            </p>
            <div className="Title-Line" id="Title-Line2"></div>
          </div>
          <div
            className="cursor-pointer"
            onClick={() => navigate("/ai-startup-tools/ai-press-release-generator-free")}
            style={{width: "fit-content"}}
          >
            <ToolCard
              img={prImg}
              h1={"Press Release Generator"}
              p={"Get the first step of your business right."}
              l1={"Quick Name Generation"}
              l2={"Creative Writing"}
              l3={"Unique template"}
              l4={"Brand Alingment"} />

          </div>
        </div>

        <div className="Card-Container">
          <div className="Container-Title">
            <p>
              Marketing <span>Tools</span>
            </p>
            <div className="Title-Line" id="Title-Line3"></div>
          </div>
          <div
            className="cursor-pointer"
            onClick={() => navigate("/ai-startup-tools/ai-marketing-plan-generator")}
            style={{width: "fit-content"}}
          >
            <ToolCard
              img={prImg}
              h1={"Marketing Plan Generator"}
              p={"Get the first step of your business right."}
              l1={"Quick Plan Generation"}
              l2={"Strategy Builder"}
              l3={"Unique template"}
              l4={"Tailored to your business"} />

          </div>
        </div>

        <div className="Card-Container">
          <div className="Container-Title">
            <p>
              Advertisement <span>Tools</span>
            </p>
            <div className="Title-Line" id="Title-Line4"></div>
          </div>
          <div className="Container-Content-Empty">
            <div className="Coming-soon">
              <img src={Coming_Soon}></img>
              <p className="Coming-soon-text1">COMING SOON</p>
              <p className="Coming-soon-text2">
                We are cooking great stuff for you.{" "}
              </p>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
}

export default ToolsPage;
