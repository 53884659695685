import React, { useState } from "react";
import plus from "../assets/plus.png";

const Faq = ({faq}) => {
  const [open, setOpen] = useState();

  const handleClick = (index) => {
    setOpen((preVal) => (preVal === index ? null : index));
  };

  // const data = [
  //   {
  //     que: "How can this service help me with my idea?",
  //     ans: "Our service helps you test your idea with real potential customers, gather their feedback, and see if there’s a genuine interest. This way, you can make informed decisions about moving forward with your idea.",
  //   },
  //   {
  //     que: "How long does the validation process take?",
  //     ans: "The timeline varies depending on the complexity of your idea and the methods we use, but typically it takes a few weeks to gather and analyze feedback.",
  //   },
  //   {
  //     que: "Can you guarantee that my idea will be successful?",
  //     ans: "While we can’t guarantee success, our service significantly increases your chances by providing you with valuable insights and data. We help you make better decisions based on real feedback.",
  //   },
  //   {
  //     que: "What if I don’t get the feedback I was hoping for?",
  //     ans: "If the feedback isn’t what you expected, it’s an opportunity to understand what might need to change. We’ll help you analyze the results and suggest ways to improve or pivot your idea.",
  //   },
  //   {
  //     que: "How do I know if the people giving feedback are the right audience?",
  //     ans: "We target feedback from people who match your ideal customer profile. This ensures that the insights you receive are relevant and useful for your specific market.",
  //   },
  //   {
  //     que: "What  happens if I need further assistance after validation?",
  //     ans: "We’re here to help! After validation, you can choose additional services for refining your idea, developing your product, or creating a go-to-market strategy. Just let us know what you need, and we’ll be happy to assist.",
  //   },
  //   {
  //     que: "How do you protect the privacy of my business idea and sensitive information?",
  //     ans: "We take confidentiality very seriously. All your information is securely handled, and we use non-disclosure agreements (NDAs) to protect your idea and sensitive data.",
  //   },
  // ];

  return (
    <div className="flex flex-col mt-14 !items-start w-[100%] md:w-[68%] ">
      <h3 className="font-cabin font-[500] text-xl md:text-3xl">
        <span className="text-[#1C6ED0]">Frequently </span>{" "}
        <span className="text-[#263238]">Asked</span> Questions
      </h3>
      <div className="w-[100%] h-[1px] bg-[#263238] opacity-20 mt-4 mb-4"></div>

      {faq.map((item, index) => (
        <div
          className="flex flex-col w-[100%] !justify-start !items-start text-left"
          key={index}
        >
          <div className="justify-between flex w-[100%] ">
            <p className="text-xs md:text-[22px] md:leading-7">{item.que}</p>

            <img
              src={plus}
              alt="plus"
              onClick={() => handleClick(index)}
              className={`transform transition-all duration-100  ${
                open === index && "-rotate-45"
              }`}
            />
          </div>

          <div className={`mt-3 hidden ${open === index && "flex"}`}>
            <p className="text-xs md:text-xl font-cabin">{item.ans}</p>
          </div>

          <div className="w-[100%] h-[1px] bg-[#263238] opacity-20 mt-4 mb-4"></div>
        </div>
      ))}
    </div>
  );
};

export default Faq;
