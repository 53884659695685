import React from 'react'
import CircleContainer from './CircleContainer'
import { useMediaQuery } from '@mui/material';

const ImageContainer3 = () => {
  
  const isMobile = useMediaQuery("(max-width:724px)");
  const isTab = useMediaQuery("(max-width:1024px)");
  const isLaptop = useMediaQuery("(max-width:1224px)");
  return (
    <div className='relative' style={{borderRadius:isMobile?"15px": "25px", backgroundColor : "#fcd982", 
      width:isMobile ? "140px" :"225px", 
      height:isMobile ? "88px" :"145px",right:isMobile?"0px":"20px"}}>
        <img className='relative bottom-0' style={{top:isMobile?"":"5px",zIndex:1, width:isMobile&& "40px", height:isMobile&& "40px"}} src="Images/guidanceSection/hugeicons_mentor.png" alt="" />
        <img className='relative bottom-0' style={{top:isMobile?"5px":"10px",left:isMobile?"95px":"150px",zIndex:1, width:isMobile&& "40px", height:isMobile&& "40px"}} src="Images/guidanceSection/Ellipse766.png" alt="" />
        <img className='relative' style={{bottom:isMobile?"28px":"45px",left:isMobile?"107px":"170px",zIndex:1, width:isMobile&& "20px", height:isMobile&& "20px"}} src="Images/guidanceSection/tabler_arrow-up.png" alt="" />
        <p className='text-[13px] font-medium absolute text-[#292929]' style={{fontSize: !isMobile && "20px",width:"max-content", top: isMobile ? "8px" : "20px", right:isMobile ? "0px" :"42px", left:isMobile ? "35px" :"60px"}}>Talk to Experts</p>
    <CircleContainer width={isMobile ? "14px" :"26px"} height={isMobile ? "14px" :"26px"} color={"#fdd36b"} bottom={isMobile?"55px":"90px"} left={isMobile?"5px":"10px"}/>
    <CircleContainer width={isMobile ? "9px":"15px"} height={isMobile ? "9px":"15px"} color={"#fdd36b"} bottom={isMobile?"45px":"80px"} left={isMobile?"25px":"40px"} />
</div>
  )
}

export default ImageContainer3