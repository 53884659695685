import PaymentFailureComponent from "../../Components/PaymentFailure/paymentFailureComponent";


function PaymentFailurePage(){
    return(
        <div className="flex justify-center py-10">
            <PaymentFailureComponent/>
        </div>
    )
}

export default PaymentFailurePage;