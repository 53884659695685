export const BrandingStrategyServiceObj = {
  serviceName: "Branding Strategy",
  icon: "/Images/homepage/AfterAssets/Brand Identity 2.png",
  heroSection: {
    image:
      "/Images/selfService/brandingStratedgyService/Branding-StrategyHeroImg.png",
    message: "Because a Strong Brand Sets You Apart",
    heading: "Build Your Legacy: ",
    headingPortion: "Develop a Branding Strategy",
    highlightedText:
      "A well-defined branding strategy helps you build a memorable brand that resonates with your audience. ",
    content:
      "From brand identity development to positioning and messaging, we create strategies that strengthen your business and connect you with your customers.",
  },
  aboutService: {
    content1:
      "Our Branding Strategy service focuses on creating, positioning, and developing your brand to ensure it stands out in a crowded market. We help businesses of all sizes build strong, recognizable brands that resonate with customers, drive loyalty, and communicate their values effectively.",
    content2: "",
  },
  features: [
    {
      icon: "/Images/selfService/brandingStratedgyService/Brand.png",

      title: "Brand Identity Development",
      description:
        "Establish a unique identity that resonates with your target audience and communicates your core values.",
    },
    {
      icon: "/Images/selfService/brandingStratedgyService/Position.png",
      title: "Brand Positioning",
      description:
        "Differentiate your brand from competitors by creating a distinct and compelling positioning strategy.",
    },
    {
      icon: "/Images/selfService/brandingStratedgyService/Rebranding.png",
      title: "Rebranding",
      description:
        "Refresh your brand’s look, feel, and messaging to stay relevant in an ever-changing market.",
    },
    {
      icon: "/Images/selfService/brandingStratedgyService/Megaphone.png",
      title: "Brand Voice Creation",
      description:
        "Develop a consistent and authentic voice that reflects your brand’s personality and resonates with your customers.",
    },
    {
      icon: "/Images/selfService/brandingStratedgyService/Strategy.png",
      title: "Brand Messaging Strategy",
      description:
        "Create messaging that aligns with your brand’s mission and engages your target audience effectively.",
    },
    {
      icon: "/Images/selfService/brandingStratedgyService/Brand.png",
      title: "Brand Reputation Management",
      description:
        "Protect and enhance your brand’s reputation by proactively managing public perception.",
    },
  ],
  payemnetSummary: {
    totalPayment: "5211",
    gst: "937",
    Payable: "6149",
  },
  benefits: {
    title: "Benefits of the Service",
    data: [
      {
        title: "Clear Identity",
        description: "Develop a strong, recognizable brand identity.",
        sno: "01",
      },
      {
        title: "Customer Loyalty",
        description: "Build lasting relationships with your audience.",
        sno: "02",
      },
      {
        title: "Competitive Edge",
        description: "Stand out from competitors in your industry.",
        sno: "03",
      },
      {
        title: "Consistency",
        description: "Ensure your brand message is clear across all channels.",
        sno: "04",
      },
      {
        title: "Relevance",
        description: "Stay ahead with timely rebranding solutions.",
        sno: "05",
      },
      {
        title: "Long-Term Growth",
        description:
          "Create a brand that supports sustainable business growth.",
        sno: "06",
      },
    ],
  },
  featuresOverview: [
    {
      title: "Comprehensive Brand Strategy",
      icon: "/Images/selfService/brandingStratedgyService/Strategy.png",
    },
    {
      title: "Target Audience Research",
      icon: "/Images/selfService/brandingStratedgyService/Customer.png",
    },
    {
      title: "Brand Messaging Framework",
      icon: "/Images/selfService/brandingStratedgyService/Diagram.png",
    },
    {
      title: "Rebranding Solutions",
      icon: "/Images/selfService/brandingStratedgyService/Rebranding.png",
    },
    {
      title: "Visual Identity Creation",
      icon: "/Images/selfService/brandingStratedgyService/Branding.png",
    },
    {
      title: "Brand Positioning Analysis",
      icon: "/Images/selfService/Radar.png",
    },
    {
      title: "Brand Story Creation",
      icon: "/Images/selfService/brandingStratedgyService/Writing.png",
    },
    {
      title: "Brand Voice Development",
      icon: "/Images/selfService/brandingStratedgyService/Voice search.png",
    },
    {
      title: "Brand Consistency Audit",
      icon: "/Images/selfService/brandingStratedgyService/Audit.png",
    },
    {
      title: "Ongoing Brand Management",
      icon: "/Images/selfService/brandingStratedgyService/People.png",
    },
  ],

  howItWorks: [
    {
      bg: "/Images/selfService/IdeaValidationImages/IdeaValWorks/fg1.png",
      fg: "/Images/selfService/IdeaValidationImages/IdeaValWorks/bg1.png",
      step: "Initial Consultation",
      description: "We discuss your brand’s goals and challenges.",
    },
    {
      bg: "/Images/selfService/IdeaValidationImages/IdeaValWorks/fg2.png",
      fg: "/Images/selfService/brandingStratedgyService/BCS---Strategy--Review1.png",
      step: "Strategy Creation",
      description: "A tailored branding strategy is developed.",
    },
    {
      bg: "/Images/selfService/IdeaValidationImages/IdeaValWorks/fg3.png",
      fg: "/Images/selfService/brandingStratedgyService/Customised-Consultation2.png",
      step: "Implementation",
      description: "We assist with executing your branding plan.",
    },

    {
      bg: "/Images/selfService/IdeaValidationImages/IdeaValWorks/fg4.png",
      fg: "/Images/selfService/IdeaValidationImages/IdeaValWorks/bg4.png",
      step: "Ongoing Support",
      description: "Post-launch, we provide continued brand management.",
    },
  ],
  targetAudience: [
    {
      icon: "/Images/servicePage/targeticon1.png",
      iconMobile: "/Images/servicePage/targeticonMobile1.png",
      title: "Aspiring Founders",
      content1:
        "New entrepreneurs can benefit from our branding strategy to establish a strong identity and differentiate their business in the market.",
      content2: "",
    },
    {
      icon: "/Images/servicePage/targeticon2.png",
      iconMobile: "/Images/servicePage/targeticonMobile2.png",
      title: "Early-Stage Businesses",
      content1:
        "We help early-stage businesses develop a cohesive brand strategy that resonates with their target audience and supports growth.",
      content2: "",
    },
    {
      icon: "/Images/servicePage/targeticon3.png",
      iconMobile: "/Images/servicePage/targeticonMobile3.png",
      title: "Small Business Owners",
      content1:
        "For small business owners, our branding services ensure you create a memorable brand that attracts and retains customers.",
      content2: "",
    },
    {
      icon: "/Images/servicePage/targeticon4.png",
      iconMobile: "/Images/servicePage/targeticonMobile4.png",
      title: "Product Managers",
      content1:
        "Product managers can leverage our branding strategy to align product messaging with market expectations and consumer needs.",
      content2: "",
    },
  ],

  faq: [
    {
      que: "What is a branding strategy?",
      ans: "A branding strategy is a comprehensive plan that outlines how your brand will be positioned in the market, how it will communicate with customers, and how it will stand out from competitors.",
    },
    {
      que: "How does branding strategy help my business?",
      ans: "A strong branding strategy helps build recognition, loyalty, and trust, making it easier to attract and retain customers over time.",
    },
    {
      que: "What’s included in your branding strategy service?",
      ans: "Our service includes brand identity development, positioning, messaging, rebranding, and ongoing brand reputation management.",
    },
    {
      que: "How long does it take to develop a branding strategy?",
      ans: "Depending on the complexity, it usually takes 4-8 weeks to create and begin implementing a comprehensive branding strategy.",
    },
    {
      que: "Can you help with rebranding?",
      ans: "Yes, we offer rebranding services to help you refresh your brand’s identity, positioning, and messaging to stay relevant.",
    },
    {
      que: "How does brand reputation management work?",
      ans: "We monitor public perception and implement strategies to enhance your brand’s reputation and address any negative feedback.",
    },
    {
      que: "What industries do you specialize in?",
      ans: "We work with businesses across various industries, including retail, tech, finance, and services.",
    },
    {
      que: "How do I get started?",
      ans: "Book a consultation with our team to discuss your business needs and branding goals.",
    },
    {
      que: "What’s the difference between brand development and rebranding?",
      ans: "Brand development is for businesses building a brand from the ground up, while rebranding is for established businesses looking to refresh their identity.",
    },
    {
      que: "Will you help with logo and visual identity design?",
      ans: "Yes, logo and visual identity design are part of our branding strategy services.",
    },
  ],
};
