import React from 'react';
import { Card, CardContent, Grid, Box, Typography, Checkbox, Button } from '@mui/material';
import { MdKeyboardArrowUp, MdKeyboardArrowDown } from 'react-icons/md';
import { Center } from "@chakra-ui/react";

const MobileViewCards = ({ data, isMobile, isChecked, handleCheckboxChange, selectedServices, handleMoreOption, visibleItemId, navigate }) => {
  return (
    isMobile && (
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          marginTop: "20px",
        }}
      >
        {data.map((item, index) => (
          <Center key={index}>
            <div className="flex flex-col" style={{ marginBottom: "25px" }}>
              <Card
                sx={{
                  maxWidth: "95%",
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  borderRadius: "20px",
                  background: `linear-gradient(328deg, #ffffff 38%, #E9D8E3 50%)`,
                }}
              >
                <CardContent>
                  <Grid container spacing={isMobile ? 1 : 2}>
                    <Grid item xs={12} sm={4}>
                      <Box>
                        <div className="flex justify-between">
                          <Box
                            className="flex justify-center content-center border-2 border-black rounded-[15px]"
                            sx={{
                              display: "flex",
                              justifyContent: "center",
                              borderColor: item?.borderColor,
                              padding: "0.5rem",
                              width: "30%",
                              backgroundColor: "white",
                            }}
                          >
                            {item["Partner Name"].slice(0, 3).toUpperCase()}
                          </Box>
                          <div className="flex gap-0">
                            <Checkbox
                              checked={isChecked[item.id] || false}
                              onChange={(event) => handleCheckboxChange(event, item.id)}
                              disabled={selectedServices.length >= 3 && !item.isChecked}
                              className={`appearance-none border-2 border-gray-300 rounded-lg`}
                            />
                            <Typography variant="body2" sx={{ fontSize: "0.65rem" }}>Add To Compare</Typography>
                          </div>
                        </div>
                      </Box>
                    </Grid>

                    <Grid item xs={12} sm={8} sx={{ padding: "0" }}>
                      <Grid container spacing={1}>
                        <Grid item xs={4}>
                          <Box sx={{ display: "flex", flexDirection: "column", alignItems: "flex-start", width: "100%" }}>
                            <Typography variant="body2" sx={{ textAlign: "left", fontSize: "0.65rem" }}>
                              Concepts
                            </Typography>
                            <Typography variant="body2" fontWeight="bold" sx={{ textAlign: "left", fontSize: "0.65rem" }}>
                              {item.Plans[0]?.Concepts}+
                            </Typography>
                            <Typography variant="body2" sx={{ textAlign: "left", marginTop: "10px", fontSize: "0.65rem" }}>
                              TAT
                            </Typography>
                            <Typography variant="body2" fontWeight="bold" sx={{ textAlign: "left", fontSize: "0.65rem" }}>
                              {item.Plans[0]?.["TAT (days)"]} Days
                            </Typography>
                          </Box>
                        </Grid>

                        <Grid item xs={4}>
                          <Box sx={{ display: "flex", flexDirection: "column", alignItems: "flex-start", width: "100%" }}>
                            <Typography variant="body2" sx={{ textAlign: "left", fontSize: "0.65rem" }}>
                              Additional Assets
                            </Typography>
                            <Typography variant="body2" fontWeight="bold" sx={{ textAlign: "left", fontSize: "0.65rem" }}>
                              {(() => {
                                let assets = item.Plans[0]?.["Details of Assets"];
                                return assets ? assets.replace(/,/g, '\n') : "None";
                              })()}
                            </Typography>
                          </Box>
                        </Grid>

                        <Grid item xs={4}>
                          <Box sx={{ display: "flex", flexDirection: "column", alignItems: "flex-start", width: "100%" }}>
                            <Typography variant="body2" sx={{ textAlign: "left", fontSize: "0.65rem" }}>
                              Revisions
                            </Typography>
                            <Typography variant="body2" fontWeight="bold" sx={{ textAlign: "left", fontSize: "0.65rem" }}>
                              {item.Plans[0]?.["Revisions Included"]}
                            </Typography>
                          </Box>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                </CardContent>

                <div>
                  <CardContent className="flex justify-between items-center">
                    <div className="flex flex-col">
                      <Typography variant="body2" gutterBottom sx={{ fontSize: "0.65rem" }}>
                        Total Price Included GST
                      </Typography>
                      <Typography variant="body1" sx={{ mb: "10px", fontWeight: "bold", fontSize: "0.65rem" }}>
                        ₹{item.Plans[0]?.Price}
                      </Typography>
                    </div>

                    <Button
                      variant="contained"
                      color="primary"
                      style={{
                        textTransform: "none",
                        fontSize: "0.65rem",
                        borderRadius: "0.3rem",
                        padding: "0.3rem 0.3rem",
                      }}
                      onClick={() => navigate()}
                    >
                      Get Started
                    </Button>
                  </CardContent>
                </div>
              </Card>
              <div onClick={() => handleMoreOption(item.id)} style={{
                width: "239px",
                color: "rgb(69, 90, 100)",
                display: "flex",
                justifyContent: "space-around",
                alignItems: "center",
                left: "20%",
                borderTop: "0px",
                fontSize: "x-small",
                borderBottomRightRadius: "8px",
                borderBottomLeftRadius: "8px",
                zIndex: 100,
                backgroundColor: "white",
                boxShadow: "rgb(196, 198, 198) 0px 2px 4px 0px",
                cursor: "pointer"
              }}>
                {visibleItemId === item.id ? (
                  <>
                    <p style={{ fontSize: "0.6rem" }}>hide options</p>
                    <p style={{ fontSize: "medium" }}>
                      <MdKeyboardArrowUp />
                    </p>
                  </>
                ) : (
                  <div className='flex gap-2'>
                    <p style={{ fontSize: "0.6rem" }}>More option from {item["Partner Name"].toUpperCase()}</p>
                    <p style={{ fontSize: "medium" }}>
                      <MdKeyboardArrowDown />
                    </p>
                  </div>
                )}
              </div>
            </div>
          </Center>
        ))}
      </div>
    )
  );
};

export default MobileViewCards;
