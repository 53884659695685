import { useEffect } from 'react';

const ApplyBodyStylesOnLoad = () => {
  useEffect(() => {
    // Apply styles to body when the component mounts
    document.body.style.overflow = 'scroll';
    document.body.style.paddingRight = '0';

    // Optional: Clean up styles when the component unmounts (optional)
    return () => {
      document.body.style.overflow = 'auto'; // Reset to default or adjust as needed
      document.body.style.paddingRight = '0'; // Reset padding
    };
  }, []); // The empty dependency array ensures this effect runs only once on page load

  return null; // This component doesn't render anything
};

export default ApplyBodyStylesOnLoad;
