import React from "react";
import NavBar from "../Header/NavBar";
import Bottom from "./components/Bottom";
import Footer from "../Footer/Footer";
import { Helmet } from "react-helmet";
import ServicePage from "../../pages/ServicePage.jsx";
import { GTM_Obj } from "../../data/GTM_Obj/GTM_Obj.js";

const GTM_Service = () => {
  return (
    <>
      <Helmet>
        <meta charset="UTF-8" />
        <meta name="viewport" content="width=device-width, initial-scale=1.0" />
        <title>
          Go to Market Strategy | Launch Your Product with Success | Bizowl
        </title>
        <meta
          name="description"
          content="Bizowl's Go-to-Market (GTM) Strategy helps you build a comprehensive marketing and launch plan, ensuring your product or service reaches the right audience effectively."
        />
        <meta
          name="keywords"
          content="Go to Market Strategy, GTM Strategy, Go to Market Plan, Go to Market Strategy Framework, Go to Market Marketing Strategy, GTM Framework, Go-to-Market Service, Product Launch Strategy"
        />
        <meta name="robots" content="index, follow" />
        <meta name="author" content="Bizowl" />
        <meta http-equiv="Content-Language" content="en" />
        <link rel="canonical" href="https://www.bizzowl.com/services/go-to-market-strategy" />
        <meta property="og:title" content="Go to Market Strategy | Launch Your Product with Success | Bizowl" />
        <meta
          property="og:description"
          content="Ensure your product launch is a success with Bizowl’s Go to Market Strategy. Plan your marketing, target audience, and channels effectively."
        />
        <meta property="og:image" content="https://res.cloudinary.com/dcduojrhf/image/upload/v1728309795/GTM_e3l24a.png" />
        <meta property="og:url" content="https://www.bizzowl.com/services/go-to-market-strategy" />
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:title" content="Go to Market Strategy | Launch Your Product with Success | Bizowl" />
        <meta
          name="twitter:description"
          content="Use Bizowl's expert Go to Market Strategy to ensure your product launch reaches the right customers with effective marketing strategies."
        />
        <meta name="twitter:image" content="https://res.cloudinary.com/dcduojrhf/image/upload/v1728309795/GTM_e3l24a.png"
        />
        <script type="application/ld+json">
          {`
  "@context": "https://schema.org",
  "@type": "Product",
  "name": "Go to Market Strategy",
  "description": "Bizowl's Go-to-Market Strategy service provides a comprehensive approach to launching your product or service and reaching your target audience.",
  "brand": {
    "@type": "Organization",
    "name": "Bizowl",
    "sameAs": "https://bizzowl.com",
    "logo": "https://www.bizzowl.com/Images/logos/logo-of-BIZOWL--Business-Services.png"
  },
  "offers": {
    "@type": "Offer",
    "url": "https://bizzowl.com/services/go-to-market-strategy",
    "priceCurrency": "INR",
    "price": "5958",
    "priceValidUntil": "2024-12-31",
    "availability": "https://schema.org/InStock",
    "itemCondition": "https://schema.org/NewCondition"
  },
  "review": {
    "@type": "Review",
    "reviewRating": {
      "@type": "Rating",
      "ratingValue": "4.8",
      "bestRating": "5"
    },
    "author": {
      "@type": "Person",
      "name": "Team Bizowl"
    }
  },
  "aggregateRating": {
    "@type": "AggregateRating",
    "ratingValue": "4.7",
    "reviewCount": "38"
  },
  "category": "Go-to-Market Strategy"
`}
        </script>
      </Helmet>
      <div className="bg-[#F5F5F5] w-[100vw] overflow-x-hidden ">
        <NavBar />
        <div className=" md:!p-16 p-3 pt-0  w-[100%] md:w-[80%] mx-auto bg-[#F5F5F5]  mb-2">
          <ServicePage serviceObj={GTM_Obj} />
        </div>
        <Footer />
        <Bottom serviceObj={GTM_Obj} />
      </div>
    </>
  );
};

export default GTM_Service;
