import React from "react";

const CircleContainer = ({ width, height, color,bottom,left }) => {
  return (
    <div
      className="relative"
      style={{
        width: width,
        height: height,
        borderRadius: "100%",
        backgroundColor: color,
        bottom:bottom,
        left:left,
      }}
    ></div>
  );
};

export default CircleContainer;
