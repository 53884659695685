import React, {useState} from "react";
import PaymentDetailsModal from "./utils/PaymentDetailsModal";

const MobilePaymentSummaryTray = ({ totalPayment, gst }) => {
  const totalAmount = totalPayment + gst;

  const [showModal, setShowModal] = useState(false);

  const handleShowModal = () => {
    setShowModal(true);
  };

  const handleHideModal = () => {
    setShowModal(false);
  };

  return (
    <div className="fixed bottom-0 left-0 right-0 bg-white shadow-md rounded-t-3xl text-blue-900 p-4">
      <div className="flex justify-between mb-2">
        <div>
          <p>₹{totalAmount.toFixed(2)}</p>
          <p className="text-xs text-gray-500">GST Included</p>
          <p className="text-xs text-blue-900 cursor-pointer" onClick={handleShowModal}>More Details</p>
        </div>
        <button className="bg-blue-900 text-white py-2 px-4 rounded-md" onClick={() => console.log("Continue button clicked")}>Continue</button>
      </div>
      {showModal && (
        <PaymentDetailsModal
          totalPayment={totalPayment}
          gst={gst}
          totalAmount={totalAmount}
          onClose={handleHideModal}
        />
      )}
    </div>
  );
};

export default MobilePaymentSummaryTray;
