// FilterCard.js
import React, { useState } from 'react';
import SliderSection from './SliderSection';
import RadioSection from './RadioSection';
import ReactSlider from 'react-slider';
import { styled } from '@mui/material/styles';
import { FormControl, FormControlLabel, Radio, Checkbox } from '@mui/material';
import BlueDownArrow from "../assets/Vector (6).png";

const StyledFormControl = styled(FormControl)({
    display: "flex",
    flexDirection: "column",
    alignItems: "start",
    color: "gray",
});

const StyledFormControlLabel = styled(FormControlLabel)({
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    fontSize: "5px",
});

const FilterCard = ({ filters, setFilters, handleFilter, handleSliderChange,
    handleRevisionSliderChange, handleConceptSliderChange, handleReset }) => {
    const [openMenu, setOpenMenu] = useState(null);

    const handleAdditionalAssetsChange = (option) => {
        console.log("Options ", option);
        setFilters((prevFilters) => {
            const newAdditionalBrandingAssets = prevFilters.AdditionalBrandingAssets.includes(option)
            ? prevFilters.AdditionalBrandingAssets.filter(item => item !== option)
            : [...prevFilters.AdditionalBrandingAssets, option];

            return {
            ...prevFilters,
            AdditionalBrandingAssets: newAdditionalBrandingAssets,
            };
        });
    };

    const handleClick = (section) => {
        setOpenMenu(openMenu === section ? null : section);
    };

    const PostDeliveryOptions = ["Yes", "No"];
    const TATOptions = ["Same Day", "2 Days", "3 Days", "4 Days", "5 Days"];
    const AdditionalBrandingAssetsOptions = ["Business Card",
        "Letterhead Design",
        "Email Signature",
        "Brand Style",
        "Social Media", "Website Favicon", "Icon Set Design", "Packaging Design"];
    const DeliverablesOptions = ["PNG", "AI", "SVG"];

    return (
        <div
            className="card"
            style={{ marginTop: "1rem", borderRadius: "25px" }}
        >
            <div
                className="card-body"
                style={{
                    boxShadow: "0rem 0.25rem 0.25rem 0rem rgba(0, 0, 0, 0.25)",
                    borderRadius: "25px",
                }}
            >
                <div
                    className="flex flex-col mb-2"
                    style={{
                        alignItems: "start",
                    }}
                >
                    <h5 className="text-xl mt-3">Filter</h5>
                    <div className="border border-b-gray-500 w-[100%] mt-2 mb-3"></div>
                </div>

                {/* budget section */}
                <div
                    className="flex-col justify-content-between align-items-center cursor-pointer"
                    onClick={() => handleClick("budget")}
                >
                    <div className="flex justify-between w-[100%] mb-[20px]">
                        <h6 className="text-sm">Budget</h6>
                        <img
                            src={BlueDownArrow}
                            alt="BlueDownArrow"
                            width="17rem"
                            height="10rem"
                        />
                    </div>
                    {openMenu === "budget" && (
                        <div
                            style={{
                                display: "block",
                            }}
                        >
                            <div style={{ position: "relative" }}>
                                <ReactSlider
                                    value={filters.priceRange}
                                    onChange={handleSliderChange}
                                    min={999}
                                    max={10000}
                                    step={500}
                                    className="w-full h-2 bg-gray-300 rounded-full"
                                    thumbClassName="w-4 h-4 bg-blue-500 rounded-full cursor-pointer -mt-[3px]"
                                    trackClassName="bg-blue-500 rounded-full"
                                    renderThumb={(props) => <div {...props} />}
                                />
                                <div className="flex justify-between text-sm text-gray-600 mt-2">
                                    <span>₹{filters.priceRange[0]}</span>
                                    <span>₹{filters.priceRange[1]}</span>
                                </div>
                            </div>
                        </div>
                    )}
                    <div className="border border-b-gray-500 w-[100%] mt-2 mb-[20px]"></div>
                </div>

                <SliderSection
                    title="Revision"
                    isOpen={openMenu === "revision"}
                    onToggle={() => handleClick("revision")}
                    value={filters.revisions}
                    onChange={handleRevisionSliderChange}
                    min={1}
                    max={5}
                    step={1}
                    displayValue={`${filters.revisions}+`}
                />

                <SliderSection
                    title="Concepts"
                    isOpen={openMenu === "concepts"}
                    onToggle={() => handleClick("concepts")}
                    value={filters.concepts}
                    onChange={handleConceptSliderChange}
                    min={1}
                    max={5}
                    step={1}
                    displayValue={`${filters.concepts}+`}
                />

                <RadioSection
                    title="TAT"
                    isOpen={openMenu === "TAT"}
                    onToggle={() => handleClick("TAT")}
                    options={TATOptions}
                    value={filters.TAT}
                    onChange={(value) => handleFilter({ target: { name: 'TAT', value } })}
                />

                <RadioSection
                    title="Post Delivery Support"
                    isOpen={openMenu === "PostDeliverySupport"}
                    onToggle={() => handleClick("PostDeliverySupport")}
                    options={PostDeliveryOptions}
                    value={filters.PostDeliverySupport}
                    onChange={(value) => handleFilter({ target: { name: 'PostDeliverySupport', value } })}
                />

                {/* Additional Assets */}
                <div className="flex-col justify-content-between align-items-center mt-2 cursor-pointer" onClick={() => handleClick("AdditionalAssets")}>
                    <div className="flex justify-between w-[100%] mb-[20px]">
                        <h6 className="text-sm">Additional Assets</h6>
                        <img src={BlueDownArrow} alt="BlueDownArrow" width="17rem" height="10rem" />
                    </div>
                    {openMenu === "AdditionalAssets" && (
                        <div>
                            <StyledFormControl component="fieldset">
                                {AdditionalBrandingAssetsOptions.map((option) => (
                                    <StyledFormControlLabel
                                        key={option}
                                        control={
                                            <Checkbox
                                                checked={filters.AdditionalBrandingAssets.includes(option)}
                                                onChange={() => handleAdditionalAssetsChange(option)}
                                                name="AdditionalBrandingAssets"
                                                value={option}
                                            />
                                        }
                                        label={option}
                                    />
                                ))}
                            </StyledFormControl>
                        </div>
                    )}
                    <div className="border border-b-gray-500 w-[100%] mt-2 mb-[20px]"></div>
                </div>

                <RadioSection
                    title="Deliverables"
                    isOpen={openMenu === "Deliverables"}
                    onToggle={() => handleClick("Deliverables")}
                    options={DeliverablesOptions}
                    value={filters.DeliverablesOptions}
                    onChange={(value) => handleFilter({ target: { name: 'Deliverables', value } })}
                />

                <button
                    type="reset"
                    onClick={handleReset}
                    style={{
                        padding: "5px 20px",
                        marginTop: "10px",
                        borderRadius: "10px",
                        backgroundColor: "#1c6ed0",
                        color: "white",
                        cursor: "pointer",
                        marginLeft: "10px",
                        fontWeight: "bold",
                        fontSize: "16px",
                        boxShadow: "0 4px 6px rgba(0, 0, 0, 0.1)",
                    }}
                >
                    Reset
                </button>
            </div>
        </div>
    );
};

export default FilterCard;