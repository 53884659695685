import React from "react";

import { FaChevronRight } from "react-icons/fa";
import { blue } from "../../constants/color";

const Ideaavalidation = () => {
  return (
    <div className="flex flex-col !px-5">
      <div className=" !pt-5 md:!pt-10 !pb-0 !order-1 md:!order-2 !items-start">
        <hr />
        <div>
          <div className="flex !items-start !gap-4 !mt-3">
            <img src="/Images/selfService/ideaIcon1.png" alt="featuresIcon" />
            <div>
              <p className="text-[18px] font-semibold !text-start md:!text-[24px]">
                Market Research 
              </p>
              <p className="text-[15px] text-gray-500 !text-start md:!text-[18px]">
                Analysis of current trends, target audience needs, and
                competitor positioning.
              </p>
            </div>
          </div>
          <div className="flex !items-start !gap-4 !mt-3">
            <img src="/Images/selfService/ideaIcon2.png" alt="featuresIcon" />
            <div>
              <p className="text-[18px] font-semibold !text-start md:!text-[24px]">
                Competitive Landscape Evaluation
              </p>
              <p className="text-[15px] text-gray-500 !text-start md:!text-[18px]">
                A clear view of your competitors’ strengths and weaknesses, so
                you can position your idea uniquely in the market.
              </p>
            </div>
          </div>
          <div className="flex !items-start !gap-4 !mt-3">
            <img src="/Images/selfService/ideaIcon3.png" alt="featuresIcon" />
            <div>
              <p className="text-[18px] font-semibold !text-start md:!text-[24px]">
                Target Audience Validation
              </p>
              <p className="text-[15px] text-gray-500 !text-start md:!text-[18px]">
                Direct feedback from your target audience to ensure your idea
                resonates and addresses their specific needs.
              </p>
            </div>
          </div>
          <div className="flex !items-start !gap-4 !mt-3">
            <img src="/Images/selfService/ideaIcon4.png" alt="featuresIcon" />
            <div>
              <p className="text-[18px] font-semibold !text-start md:!text-[24px]">
                1:1 Video Call
              </p>
              <p className="text-[15px] text-gray-500 !text-start md:!text-[18px]">
                Unlimited Personalized sessions with experts to discuss your
                idea, clarify doubts, and receive tailored advice.
              </p>
            </div>
          </div>
          <div className="flex !items-start !gap-4 !mt-3">
            <img src="/Images/selfService/ideaIcon5.png" alt="featuresIcon" />
            <div>
              <p className="text-[18px] font-semibold !text-start md:!text-[24px]">
                Actionable Validation Report
              </p>
              <p className="text-[15px] text-gray-500 !text-start md:!text-[18px]">
                A detailed report with practical recommendations, clear
                insights, and steps to improve or pivot your idea.
              </p>
            </div>
          </div>
          <div className="flex !items-start !gap-4 !mt-3">
            <img src="/Images/selfService/ideaIcon6.png" alt="featuresIcon" />
            <div>
              <p className="text-[18px] font-semibold !text-start md:!text-[24px]">
                Post Validation Support
              </p>
              <p className="text-[15px] text-gray-500 !text-start md:!text-[18px]">
                Continued guidance after validation, helping you implement
                recommendations and plan the next steps confidently.
              </p>
            </div>
          </div>
        </div>
      </div>
      <div className="bg-[#f5f5f5] flex flex-col !items-start  !gap-3 !pt-5  md:!pt-10  !pb-0 !order-2 md:!order-1">
        <h2 className="!text-[20px] !font-semibold md:!text-[35px]">About the Service</h2>
        {/* <hr className="" /> */}
        <p className="!text-start !text-[14px] !text-gray-700 md:!text-[18px]">
          Idea validation is like a reality check for your business idea. Before
          you dive in and spend time, money, and energy, you want to make sure
          there's actually a need for what you’re planning to offer.{" "}
        </p>
        <p className="!text-start !text-[14px] !text-gray-700 hidden md:block md:!text-[18px]">
          It’s about testing your idea with real people to see if they’re
          interested and willing to pay for it. Think of it as a way to ensure
          you’re not building something that no one wants.
        </p>
        <button className="bg-none p-0 flex gap-2 md:!hidden">
          <span style={{ color: blue }} className="underline">
            Show More
          </span>{" "}
          <FaChevronRight />
        </button>
      </div>
    </div>
  );
};

export default Ideaavalidation;
