import React from 'react'
import { blue } from '../../constants/color'

const PayemtCardMobile = () => {
  return (
    <div className='flex justify-between bg-white border-t-2 p-4 fixed bottom-0 w-full md:!hidden md:!pl-[195px]'>
        <div className='flex flex-col !items-start'>
            <p className='font-bold text-[22px]'>₹6419 /-</p>
            <p className='font-medium text-[15px] text-gray-500'>GST Included</p>
        </div>
        <button className="text-white" style={{backgroundColor:`${blue}`}} >Validate Now</button>
    </div>
  )
}

export default PayemtCardMobile