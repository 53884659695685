import React from "react";

function VideoSearchBar(){
    return(
    <div className="bg-white h-14 mt-3 flex justify-center items-center rounded-lg mb-4" style={{border:"1px solid black"}}>
        <input className="focus:outline-none p-4 text-sm" style={{height:"50%",width:"90%"}} type="text" placeholder="Search for Services" />
        <button className="bg-blue-500 text-white text-sm w-36 m-3 rounded-md hover:bg-blue-700 transition-all duration-600">Search</button>
    </div>
    )
    
}

export default VideoSearchBar;