export const serviceFormQuestions = [
  {
    serviceName: "Idea Validation",
    questions: [
      "What is your idea about?",
      "Who is your target audience?",
      "Who are your key competitors?",
      "What problem does your startup solve?",
      "Have you done any market research?",
      "What is your timeline for launching this idea?",
      "Do you have any competitors? If so, who are they?",
    ],
  },
  {
    serviceName: "SWOT Analysis Service",
    questions: [
      "What are your business's current strengths?",
      "What weaknesses or challenges is your business currently facing?",
      "Who are your key competitors?",
      "What opportunities do you see in your market?",
      "What external threats are you concerned about?",
      "What is your main goal for conducting a SWOT analysis?",
    ],
  },
  {
    serviceName: "Business Feasibility Analysis",
    questions: [
      "What product or service does your business offer?",
      "Have you identified your target market? If so, please provide details.",
      "What is the estimated size of the market you are targeting?",
      "Have you conducted any competitor research? If so, who are they?",
      "What resources (financial, human, etc.) do you have available for your business?",
      "What is your expected timeline for launching your business?",
      "What are your key concerns regarding the feasibility of your business idea?",
    ],
  },
  {
    serviceName: "MVP Development Service",
    questions: [
      "What is the core feature or functionality of your product/service?",
      "What problem does your MVP aim to solve?",
      "Who is your target audience for the MVP?",
      "What stage is your product currently in (concept, design, development)?",
      "Have you identified key metrics to measure the success of the MVP? If so, what are they?",
      "What is your budget and timeline for MVP development?",
      "Do you have any existing competitors with a similar product?",
    ],
  },
  {
    serviceName: "Product Development",
    questions: [
      "What is the current stage of your product (concept, prototype, development)?",
      "What is the primary problem your product will solve?",
      "Who is your target customer for this product?",
      "Do you have a unique selling proposition (USP) for your product? If so, what is it?",
      "Have you conducted any market research? If yes, please provide details.",
      "What is your expected timeline for product launch?",
      "What are your budget constraints for product development?",
    ],
  },
  {
    serviceName: "Branding Strategy",
    questions: [
      "What is your business's core mission or value proposition?",
      "Who is your target audience for branding?",
      "What is the current perception of your brand, if any?",
      "What are your main competitors' branding strategies?",
      "What message or emotions do you want your brand to convey?",
      "Do you have any existing branding materials (logo, colors, etc.)?",
      "What is your primary goal for improving your branding?",
    ],
  },
  {
    serviceName: "Business Consulting Service",
    questions: [
      "What are your business’s current challenges or pain points?",
      "What areas of your business do you need consultancy in (operations, marketing, finance, etc.)?",
      "What is the main goal or outcome you hope to achieve from consulting services?",
      "Have you worked with a consultant before? If yes, what was the outcome?",
      "What is your budget and timeline for consulting services?",
      "Do you have a specific project or task you need guidance on?",
      "Are there specific competitors or market trends affecting your business?",
    ],
  },
  {
    serviceName: "Market Entry Strategy",
    questions: [
      "What is your current product or service offering?",
      "What new market are you planning to enter (region, industry, etc.)?",
      "Have you conducted any research on this new market? If yes, please provide details.",
      "What are your main concerns regarding entering this market (regulations, competition, etc.)?",
      "Who are your key competitors in the new market?",
      "What is your expected budget for market entry?",
      "What is your timeline for entering the new market?",
    ],
  },
  {
    serviceName: "Business Model Canvas",
    questions: [
      "What is the main product or service your business offers?",
      "Who is your primary target customer?",
      "What is your unique value proposition?",
      "What are your current revenue streams?",
      "What key resources and partners do you rely on?",
      "What are the key activities needed to deliver your product/service?",
      "What is your main goal for refining your business model?",
    ],
  },
  {
    serviceName: "Go-To-Market",
    questions: [
      "What is your product or service?",
      "What target market or segment are you aiming to reach?",
      "What are your primary marketing and sales goals for this launch?",
      "Have you conducted any competitor research? If yes, who are your key competitors?",
      "What are your expected distribution channels (online, retail, partnerships, etc.)?",
      "What is your marketing budget and timeline for the launch?",
      "What metrics will you use to measure the success of your go-to-market strategy?",
    ],
  },
  {
    serviceName: "Customer & Target Market Analysis Service",
    questions: [
      "Who is your primary target audience for your product or service?",
      "Have you identified key demographic information (age, gender, income, etc.) about your target audience?",
      "What are the key behaviors or pain points of your target customer?",
      "What is the geographical location of your target market?",
      "Have you conducted any customer feedback or surveys? If yes, please provide details.",
      "What is your primary goal for better understanding your target audience?",
      "Do you have competitors targeting the same audience? If so, who are they?",
    ],
  },
  {
    serviceName: "Competitor Analysis Service",
    questions: [
      "Who are your main competitors in the market?",
      "What product or service are you comparing against competitors?",
      "What do you believe is your competitors' main advantage?",
      "What are the main challenges you face against competitors?",
      "Have you identified any gaps or opportunities in the competitive landscape?",
      "What is your main goal for conducting this competitor analysis?",
      "Do you have a specific market or region where competition is tougher?",
    ],
  },
];
