import React from 'react'
import CircleContainer from './CircleContainer'
import { useMediaQuery } from '@mui/material';

const ImageContainer2 = () => {
  
  const isMobile = useMediaQuery("(max-width:724px)");
  const isTab = useMediaQuery("(max-width:1024px)");
  const isLaptop = useMediaQuery("(max-width:1224px)");
  return (
    <div className='relative' style={{borderRadius:isMobile?"15px":"35px", backgroundColor : "#ddbed1", width: isMobile ? "159px" :"260px", height:isMobile ? "186px" :"292px",bottom:isMobile?"110px":"135px",right:isMobile?"0px":"20px"}}>
    <img className='relative bottom-0' style={{height:isMobile?"165px":"265px",width:isMobile?"250px":"350px",top:isMobile?"20px":"28px",left:isMobile?"15px":"30px",zIndex:2}} src="Images/guidanceSection/young-handsome.png" alt="" />
    <CircleContainer width={isMobile ? "10px" :"16px"} height={isMobile ? "10px" :"16px"} color={"#dab6cc"} bottom={isMobile?"105px":"170px"} left={isMobile?"45px":"80px"}/>
    <CircleContainer width={isMobile ? "10px" :"16px"} height={isMobile ? "10px" :"16px"} color={"#dab6cc"} bottom={isMobile?"160px":"260px"} left={isMobile?"40px":"75px"}/>
    <CircleContainer width={isMobile ? "10px" :"16px"} height={isMobile ? "10px" :"16px"} color={"#dab6cc"} bottom={isMobile?"150px":"260px"} left={isMobile?"10px":"30px"}/>
    <CircleContainer width={isMobile ? "10px" :"16px"} height={isMobile ? "10px" :"16px"} color={"#dab6cc"} bottom={isMobile?"120px":"200px"} left={isMobile?"15px":"35px"}/>
    <img className='relative' style={{height:isMobile?"180px":"295px",bottom:isMobile?"205px":"330px",left:isMobile?"60px":"110px"}} src="Images/guidanceSection/Vector89.png" alt="" />
</div>
  )
}

export default ImageContainer2