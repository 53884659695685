import React, { useRef, useState } from 'react'
import { Helmet } from 'react-helmet'
import pdfLogo from "./assets/pdfLogo.png"
import { GiRotaryPhone } from "react-icons/gi";
import { FaPaperPlane } from 'react-icons/fa';
import { TbWorld } from "react-icons/tb";
import { TfiLocationPin } from "react-icons/tfi";
import { useMediaQuery } from '@mui/material';
import useStore from './store';
import html2pdf from 'html2pdf.js';
import { useNavigate } from 'react-router-dom';

const PrtermsAndCondition = () => {
    const navigate= useNavigate();
    const [showDownloadLink, setShowDownloadLink] =useState(false);

    const { isChecked, toggleCheckbox } = useStore();
    const isMobile = useMediaQuery("(max-width: 768px)");
    const currentDate = new Date().toLocaleDateString('en-IN',{
        year:"numeric",
        month:"long",
        day:"numeric"

    });
    const contentRef = useRef();

    const handleCheckboxChange = (e) => {
        toggleCheckbox();
        setShowDownloadLink(e.target.checked);
      };

      const downloadPDF = () => {
        const element = contentRef.current;
        const opt = {
          margin: 0.5,
          filename: 'terms_and_conditions.pdf',
          image: { type: 'jpeg', quality: 0.98 },
          html2canvas: { scale: 2 },
          jsPDF: { unit: 'in', format: 'letter', orientation: 'portrait' }
        };
        html2pdf().from(element).set(opt).save();
      };
    
  return (
   <div ref={contentRef}  style={{backgroundColor:"white",textAlign:"left", fontSize:isMobile?"small":"medium",
       fontFamily:"Cabin",}}>
     <Helmet>
        <meta name="robots" content="noindex" />
        <title>Terms and Conditions</title>
      </Helmet>
      <div className='mt-4'>
<div  className='w-[100vw] ' ><img src={pdfLogo} className='w-[90%] object-fill  mx-auto' style={{
    height:"3rem",


}}/></div>

{/* actual page */}
<div  style={{
    width:"90%",
    padding:"2rem"
 ,
 display:"flex",
 flexDirection:"column",
 gap:"1rem",
 justifyContent:"",
 alignItems:"start",
 margin:"0 auto"
 
}} >

<p className='font-bold'>Service Terms and Conditions</p>
<p className='font-bold'>Effective Date: <span>{currentDate}</span></p>
<p className='font-bold'>1. Introduction</p>
<p>Welcome to Bizowl. By making a payment and booking our press release distribution service, you agree to the following
terms and conditions. Please read them carefully before proceeding.</p>

<p className='font-bold'>2. Service Overview</p>
<p>Bizowl provides press release distribution services, which include the drafting, distribution, and management of press
releases. These services are fulfilled by Bizowl's team or its authorized representatives to ensure the highest quality and
timely delivery.</p>

<p className='font-bold'>3. Payment Terms</p>
<ol style={{ listStyleType: 'disc', paddingInlineStart: '1.5rem' }}>
    <li style={{ marginBottom: '0.5rem' }}>
      <span style={{ fontWeight: 'bold' }}>Client Payment:</span>
      <span> Full payment is required at the time of booking through the Bizowl platform</span>
    </li>
    <li style={{ marginBottom: '0.5rem' }}>
      <span style={{ fontWeight: 'bold' }}>Payment Processing:</span>
      <span> Payments will be processed securely through our payment gateway, and an invoice will be
      generated upon successful payment.</span>
    </li>

    <li style={{ marginBottom: '0.5rem' }}>
      <span style={{ fontWeight: 'bold' }}>Refunds and Cancellations:</span>
      <span>Clients may request a cancellation within 24 hours of booking if work has not yet
commenced. Refunds will be issued minus any applicable payment gateway charges.
</span>
    </li>
  </ol>

  <p className='font-bold'>4. Service Fulfillment</p>
<ol style={{ listStyleType: 'disc', paddingInlineStart: '1.5rem' }}>
    <li style={{ marginBottom: '0.5rem' }}>
      <span style={{ fontWeight: 'bold' }}>Delivery Timeline:</span>
      <span> : Bizowl guarantees the completion of services within the timeframe specified at the time of
      booking. Any delays will be communicated promptly.</span>
    </li>
    <li style={{ marginBottom: '0.5rem' }}>
      <span style={{ fontWeight: 'bold' }}>Quality Assurance:</span>
      <span> Bizowl ensures that all services are delivered as described, meeting the agreed-upon
      standards and quality.</span>
    </li>

    <li style={{ marginBottom: '0.5rem' }}>
      <span style={{ fontWeight: 'bold' }}>Post-Service Support:</span>
      <span>Clients may request additional support following the completion of services, subject to
availability and the terms of the service package.

</span>
    </li>
  </ol>

  <p className='font-bold'>5. Dispute Resolution</p>
<ol style={{ listStyleType: 'disc', paddingInlineStart: '1.5rem' }}>
    <li style={{ marginBottom: '0.5rem' }}>
      <span style={{ fontWeight: 'bold' }}>Client Disputes:</span>
      <span> : Any disputes related to service quality, delivery, or other issues must be raised with Bizowl
      directly. Bizowl will work to resolve the issue promptly and fairly.</span>
    </li>

    <li style={{ marginBottom: '0.5rem' }}>
      <span style={{ fontWeight: 'bold' }}>Resolution Process:</span>
      <span> If a dispute cannot be resolved through initial discussions, it may be escalated to arbitration
under Indian law.

</span>
    </li>
  </ol>

  <p className='font-bold'>6. Limitation of Liability</p>
<ol style={{ listStyleType: 'disc', paddingInlineStart: '1.5rem' }}>
    <li style={{ marginBottom: '0.5rem' }}>
      <span style={{ fontWeight: 'bold' }}>Bizowl Liability:</span>
      <span>Bizowl is not liable for any indirect or consequential damages arising from the use of our
      services. Our liability is limited to the amount paid by the client for the service in question.</span>
    </li>
    <li style={{ marginBottom: '0.5rem' }}>
      <span style={{ fontWeight: 'bold' }}>Service Guarantees:</span>
      <span> Bizowl guarantees that services will be delivered as described. In the rare event that this is
not possible, clients will be offered a full or partial refund or the opportunity to have the work redone.
</span>
    </li>
  </ol>

  <p className='font-bold'>7. Intellectual Property</p>
<ol style={{ listStyleType: 'disc', paddingInlineStart: '1.5rem' }}>
    <li style={{ marginBottom: '0.5rem' }}>
      <span style={{ fontWeight: 'bold' }}>Client’s Content:</span>
      <span>Clients retain ownership of all content they provide. Bizowl will use this content solely for the
purpose of fulfilling the service.
</span>
    </li>
    <li style={{ marginBottom: '0.5rem' }}>
      <span style={{ fontWeight: 'bold' }}>Produced Work:</span>
      <span> Any work produced by Bizowl as part of the service remains the property of the client, with a
      limited license for Bizowl to use it for service fulfillment and promotional purposes.</span>
    </li>

  </ol>

  <p className='font-bold'>8. Privacy Policy</p>
<ol style={{ listStyleType: 'disc', paddingInlineStart: '1.5rem' }}>
    <li style={{ marginBottom: '0.5rem' }}>
      <span style={{ fontWeight: 'bold' }}>Data Handling:</span>
      <span>Bizowl is committed to protecting client data and will handle all personal and business
information in accordance with our Privacy Policy. We do not share client data with third parties without explicit
consent.
</span>
    </li>

  </ol>


  <p className='font-bold'>9. Amendments</p>
<p>Bizowl reserves the right to amend these terms and conditions at any time. Clients will be notified of any changes prior
to their booking.
</p>

<p className='font-bold'>10. Governing Law</p>
<p>This agreement is governed by and construed in accordance with the laws of India. Any disputes arising under this
agreement shall be subject to the exclusive jurisdiction of the courts of New Delhi, India.
</p>
   <div className='flex items-center ' style={{
    gap:"1rem",
    width:"60%",
    margin:"2rem auto"
   }}>
      <input type='checkbox' id='termsCheckbox' 
      checked={isChecked}
      onChange={handleCheckboxChange}
      className='mr-2 ' 
        style={{
        transform: 'scale(2)', // Scale the checkbox
        transformOrigin: 'center', // Ensure scaling happens from the center
      }}
      />
      <label htmlFor='termsCheckbox' style={{
        fontWeight:"800"
      }}>
        By proceeding with your booking and payment, you acknowledge that you have read, understood, and agree to be
        bound by these terms and conditions.
      </label>
    </div>
    {/* Download link */}
    {showDownloadLink && (
          <div style={{ textAlign: 'center', marginTop: '1rem', }} onClick={()=>navigate(-1)}>
            <button
              onClick={downloadPDF}
              style={{ padding: '0.5rem 1rem', backgroundColor: '#346eb5', color: 'white', border: 'none', borderRadius: '0.25rem' }}
            >
              Download as PDF
            </button>
          </div>
        )}

   
</div>
     
   

<div style={{
    backgroundColor:"black",
    width:"100%",
    height:"100px",
    color:"white",
  
}}>
    <div className='flex' style={{
        justifyContent:isMobile?"space-around":"center",
        alignItems:"center",
        height:"100%",
        gap:isMobile?"0.5rem":"2rem",
          flexWrap:"wrap",
          padding:isMobile?"0 10px":""
        
    }}>
        <div className='flex gap-1'>
        <GiRotaryPhone style={{color:"#346eb5"}}/>
        <p style={{
            fontSize:isMobile?"x-small":"small"
        }}>+919354651433</p>
        </div>

        <div className='flex gap-1'>
        <FaPaperPlane style={{color:"#346eb5"}}/>
        <p style={{
            fontSize:isMobile?"x-small":"small"
        }}>contact@bizzowl.com</p>
        </div>

        <div className='flex gap-1'>
        <TbWorld style={{color:"#346eb5"}} />
        <p style={{
            fontSize:isMobile?"x-small":"small"
        }}>www.bizowl.com</p>
        </div>

        <div className='flex gap-1'>
        <TfiLocationPin style={{color:"#346eb5"}} />
        <p style={{
            fontSize:isMobile?"x-small":"small"
        }}>RZF-870, Raj Nagar-2, Delhi</p>
        </div>
    </div>

</div>
 


      </div>
   </div>
  )
}

export default PrtermsAndCondition