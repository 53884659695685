// Todo: Options change and error handling and that image in options in typeoflogo
import React, { useEffect, useRef, useState } from "react";
import logo from "./assets/logo.svg";
import image from "./assets/Logo-Design.png";
import talkExpert from "./assets/talkToExpert.svg";
import percentage from "./assets/percentage.svg"; 
import { Helmet } from "react-helmet";
import "../../Styles/pr.css";
import { useNavigate } from "react-router-dom";
import { useMediaQuery } from "@mui/material";
import ReactSlider from "react-slider";
import { IoIosArrowDown } from "react-icons/io";
import { addDoc, collection } from "firebase/firestore";
import { partnerDB, primaryDB } from "../../config/firebase";
import useFormData from "../Services/util/useFormData";
import Apple from "./assets/OptionImages/Apple.png";
import BurgerKing from "./assets/OptionImages/BurgerKing.png";
import Harley from "./assets/OptionImages/Harley.png";
import IBM from "./assets/OptionImages/IBM.png";
import MAC from "./assets/OptionImages/MAC.png";
import netflix from "./assets/OptionImages/netflix.png";
import NIKE from "./assets/OptionImages/NIKE.png";
import Pringles from "./assets/OptionImages/Pringles.png";

const FirstForm = () => {
  const { updateFormData } = useFormData();

  const [currStep, setCurrStep] = useState(1);
  // for budget slider

  const [errors, setErrors] = useState([]);
  const [formData, setFormData] = useState({
    TypeOfLogo: [],
    priceRange: [999, 10000],
    Color: [],
    LogoStyle: [],
    Assests: [],
    Revisions: "",
    LogoConcepts: "",
    deliveryTime: "",
    fullName: "",
    email: "",
    phone: "",
    website: "",
    business: "",
  });
  const TypeOfLogo_Value = [
    { label: "Wordmark", value: netflix },
    { label: "Lettermark", value: IBM },
    { label: "Icon-Based", value: Apple },
    { label: "Abstract Mark", value: NIKE },
    { label: "Combination Mark", value: BurgerKing },
    { label: "Emblem", value: Harley },
    { label: "Mascot Logo", value: Pringles },
    { label: "Letterform", value: MAC },
  ];
  const LogoStyle = [
    "Minimalist",
    "Vintage",
    "Modern",
    "Playful",
    "Corporate/Professional",
    "Elegant",
    "Hand-Drawn",
  ];
  const Assets = [
    "Business Card Design",
    "Letterhead Design",
    "Email Signature",
    "Brand Style Guide",
    "App Icon Design",
  ];
  const Color = [
    " Red - (Energy, passion, excitement)",
    "Yellow (Optimism, warmth, happiness)",
    "Green (Growth, health, nature)",
    "Blue (Trust, professionalism, calm)",
    "Purple (Luxury, creativity, imagination)",
    "Pink (Femininity, playfulness, compassion)",
    "Black (Sophistication, elegance, power)",
    "Orange (Creativity, enthusiasm, friendliness)",
    "White (Simplicity, purity, cleanliness)",
    "Grey (Neutrality, balance, professionalism)",
    "Gold (Luxury, success, wealth)",
    "Multicolor (Diversity, fun, playfulness)",
  ];
  const navigate = useNavigate();

  const handleSubmit = async (event) => {
    event.preventDefault();
    const newErrors = [];
    console.log("Form Data", formData);

    try {
      // Validate purposeOfPr
      if (!formData.fullName) {
        newErrors.fullName = "Full name is required.";
      }
      // Validate time
      if (!formData.email) {
        newErrors.email = "Email is required.";
      }
      // LogoStyle
      if (!formData.phone) {
        newErrors.phone = "Phone number is required.";
      } else if (formData.phone.length !== 10) {
        // Assuming a 10-digit phone number
        newErrors.phone = "Phone number must be 10 digits long.";
      }

      if (Object.keys(newErrors).length > 0) {
        setErrors(newErrors);
      } else {
        const docRef = await addDoc(collection(primaryDB, "prUsers"), formData);
        // console.log("Document written with ID: ", docRef.id);
        updateFormData(formData);
        navigate("/services/logo-design-distribution/quote-details");
      }
    } catch (error) {
      // console.error("Error adding document: ", error);
    }
  };

  const handleNext = (event) => {
    event.preventDefault();
    console.log("Form Data -> ", formData);

    const newErrors = [];

    // Validate purposeOfPr
    if (!formData.Revisions) {
      newErrors.Revisions = "Purpose of Press Release is required.";
    }
    // Validate time
    if (!formData.deliveryTime) {
      newErrors.deliveryTime = "Time is required.";
    }
    // LogoStyle
    if (formData.LogoStyle.length === 0) {
      newErrors.LogoStyle = "At least one LogoStyle is required.";
    }
    // If there are errors, update state and do not go forward
    if (Object.keys(newErrors).length > 0) {
      setErrors(newErrors);
    } else {
      // Clear errors if no validation issues
      setErrors([]);
      setCurrStep(2);
    }
  };

  // console.log(errors);

  // Function to handle slider value change
  const handleSliderChange = (newRange) => {
    setFormData((prevData) => ({
      ...prevData,
      priceRange: newRange,
    }));
  };

  const handleCheckboxChange = (group, event) => {
    const { value, checked } = event.target;
    setFormData((prevData) => {
      const newSelections = checked
        ? [...prevData[group], value]
        : prevData[group].filter((item) => item !== value);

      return { ...prevData, [group]: newSelections };
    });
  };
  const handleFormData = (event) => {
    const { name, value } = event.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));

    // console.log(formData);
  };

  const isMobile = useMediaQuery("(max-width: 768px)");
  const isTab = useMediaQuery("(min-width: 769px) and (max-width:1476px) ");
  const labelStyle = {
    width: !isMobile ? "400px" : "", // Set width to 400px if not mobile
    textAlign: "left",
    // gap:isMobile?"5px":""
  };

  const dropdownRef = useRef({});
  const buttonRef = useRef({});

  // Close the dropdown if a click is detected outside of it
  useEffect(() => {
    const handleClickOutside = (event) => {
      Object.keys(dropdownRef.current).forEach((key) => {
        if (
          dropdownRef.current[key] &&
          !dropdownRef.current[key].contains(event.target) &&
          !buttonRef.current[key].contains(event.target)
        ) {
          dropdownRef.current[key].classList.add("hidden");
        }
      });
    };

    document.addEventListener("click", handleClickOutside);

    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, []);

  // Toggle the dropdown when the button is clicked
  const toggleDropdown = (id) => {
    dropdownRef.current[id].classList.toggle("hidden");
  };

  return (
    <>
      <Helmet>
        <title>
          Logo Design | Custom Logo Design Services | Bizowl Logo Design
        </title>
        <meta
          name="description"
          content="Get your news noticed with our custom press release distribution service. Submit your details and view tailored PR plans designed to maximize your media coverage."
        />
        <meta
          name="keywords"
          content="press release distribution, custom PR plans, media coverage, PR distribution service"
        />
        <meta name="author" content="Bizowl" />
        <meta name="robots" content="index, follow" />
        <link
          rel="canonical"
          href="https://bizzowl.com/services/press-release-distribution"
        />
        <script type="application/ld+json">
          {`{
            "@context": "https://schema.org",
            "@type": "Service",
            serviceType: "Press Release Distribution",
            provider: {
              "@type": "Organization",
              name: "Bizowl",
              url: "https://www.bizzowl.com",
              logo: "https://www.bizzowl.com/logo.png",
              contactPoint: {
                "@type": "ContactPoint",
                telephone: "+91-935465143",
                contactType: "Customer Service",
                areaServed: "Global",
                availableLogoStyle: "English",
              },
            },
            description:
              "Get your news noticed with our custom press release distribution service. Submit your details and view tailored PR plans designed to maximize your media coverage.",
            areaServed: {
              "@type": "Place",
              name: "Global",
            },
            offers: {
              "@type": "Offer",
              url: "https://www.bizzowl.com/services/press-release-distribution",
              priceCurrency: "INR",
              price: "Variable",
              eligibleRegion: {
                "@type": "Place",
                name: "Global",
              },
              availability: "https://schema.org/InStock",
              validFrom: "2024-07-24",
            },
            aggregateRating: {
              "@type": "AggregateRating",
              ratingValue: "4.6",
              reviewCount: "17",
            },
          }`}
        </script>
      </Helmet>
      <div className="main">
        <div
          className={isMobile ? "" : "left-side"}
          style={{
            // display: isMobile ? "flex" : "",
            className: isMobile ? "" : "left-side",
            flexDirection: isMobile ? "column" : "",
            justifyContent: isMobile ? "start" : "",
            alignItems: isMobile ? "flex-start" : "", // Align items to the start (left) of the container
            padding: isMobile ? "0 30px" : "", // Optional: Add padding to avoid elements touching the container edges
            gap: isMobile ? "15px" : "",
          }}
        >
          <br />
          <br />
          {/* Talk to an expert */}
          <div
            className="logo"
            style={{
              display: isMobile ? "flex" : "",
              justifyContent: isMobile ? "space-between" : "flex-start", // Align items based on screen size
              // gap: isMobile ?"40px":"",
              width: isMobile ? "100%" : "", // Ensure the logo div takes up the full width for proper alignment
              marginTop: isMobile ? "-20px" : "",
            }}
          >
            <img
              src={logo}
              alt="logo"
              onClick={() => navigate("/")}
              style={{
                width: !isMobile && "35%",
              }}
            />
            <p style={{ textAlign: "left" }}>
              <img src={talkExpert} alt="" />
              <a
                href="tel:+919354651433"
                style={{ textDecoration: "none", color: "inherit" }}
              >
                Talk to an Expert ( +919354651433)
              </a>
            </p>
          </div>

          {/* inner comopnent */}
          <div style={{ display: isMobile ? "flex" : "" }}>
            <div
              className=" "
              style={{
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "start",
                gap: isMobile ? "8px" : "10px",
                margin: isMobile ? "20px auto" : "20px auto",
                marginRight: isMobile ? "0" : "10%",
                // fontSize: isMobile ? "14px" : "10px auto auto",
              }}
            >
              <p
                className="text-left text-base"
                style={{ fontSize: isMobile ? "10px" : "10px auto auto" }}
              >
                Graphic Design Services
              </p>

              <p
                className="text-[30px] space-y-1"
                style={{
                  textAlign: "left",
                  lineHeight: isMobile ? "1.5" : "1.2",
                  fontSize: isMobile ? "15px" : "",
                }}
              >
                Get Your Logo Designed with the{" "}
                <span className="text-[#1C6ED0] font-semibold ">
                  Best Deals and Creative Expertise
                </span>
              </p>

              <ul
                className="flex-wrap list-disc mt-2 sm:gap-6 text-[#1C6ED0] text-[12px] justify-start"
                style={{
                  textAlign: "left",
                  paddingInlineStart: "20px",
                  fontSize: isMobile ? "12px" : "15px",
                  display: isMobile ? "flex-col" : "flex",
                }}
              >
                <li>Business Branding</li>
                <li>Company Rebranding</li>
                <li>Custom Logo Design</li>
              </ul>
            </div>

            {/* Main image */}
            <div className=" flex align-baseline">
              <img
                src={image}
                alt=""
                width={isMobile ? "300px" : "80%"}
                style={{
                  display: "block",
                  margin: isMobile ? "0 auto" : "10px auto auto",
                }}
                className=""
              />
            </div>
          </div>
        </div>
        {/* end here */}

        <div className="md:w-[50%] md:absolute left-[50%]">
          <br />
          <br />
          <p
            className={`text-[#1B2559] hidden  text-[12px] font-bold md:flex items-center justify-end pr-2 ${
              isMobile ? "" : isTab ? "auto" : "w-[90%]"
            }`}
          >
            <img src={talkExpert} alt="" />
            <p>Talk to an Expert ( +919354651433)</p>
          </p>

          <div className="flex items-end justify-center text-[15px] mt-3">
            <br />
            <div className="flex flex-col items-center justify-center">
              <p>Logo Details</p>
              <svg
                width="20"
                height="20"
                viewBox="0 0 25 25"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <circle cx="12.5" cy="12.5" r="12.5" fill="#1C6ED0" />
              </svg>
              <dot />
            </div>
            <div className="mt-4">
              <svg
                width="200"
                height="1"
                viewBox="0 0 300 1"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <line
                  y1="0.5"
                  x2="300"
                  y2="0.5"
                  stroke="#455A64"
                  stroke-opacity="0.75"
                />
              </svg>
            </div>

            <div className="flex flex-col items-center justify-center">
              <p>Contact Details</p>
              <svg
                width="20"
                height="20"
                viewBox="0 0 25 25"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <circle
                  cx="12.5"
                  cy="12.5"
                  r="12.5"
                  fill={currStep === 1 ? "#455A64" : "#1C6ED0"}
                />
              </svg>
            </div>
          </div>

          <br />
          <div
            className={`flex flex-col justify-center items-center w-[95%] md:w-[85%] lg:w-[65%] m-auto rounded-b-3xl form  ${
              isMobile ? "max-w-[375px]" : ""
            }`}
          >
            <p className="flex items-center bg-gradient-to-r from-[#1C6ED0] to-[#0E386A] text-white w-full justify-center rounded-t-3xl p-1 gap-2">
              <img src={percentage} alt="" />
              <span className="text-xs">
                Compare and Get the Best Logo design Deals
              </span>
              {/* Compare and Get the Best Logo design Deals */}
            </p>
            <div className="flex flex-col justify-start">
              {/* <p
                className="font-bold text-[18px] lg:text-[24px] "
                style={{
                  marginTop: isMobile ? "19px" : isTab ? "23px" : "10px",
                  marginBottom: isMobile ? "9px" : "11px",
                  marginLeft: isMobile ? "-48px" : isTab ? "1%" : "-90px",
                  fontWeight: !isMobile && "600",
                }}
              > */}
              <div
                style={{
                  marginTop: isMobile ? "19px" : "23px",
                  marginBottom: isMobile ? "9px" : "11px",
                  marginLeft: isMobile ? "-48px" : isTab ? "" : "-40px",
                }}
              >
                {currStep === 1 ? (
                  <p
                    className={`${
                      isMobile
                        ? "text-xl container pl-10 text-left"
                        : isTab
                        ? "w-[320px] text-left"
                        : "w-[320px] text-left"
                    } `}
                  >
                    {/* Get Your Custom Logo{" "} */}
                    <div className="text-base  font-medium">
                      <span className="">Get Your Custom Logo </span>
                      <span className="text-[#1C6ED0]">Designed Today</span>
                    </div>
                  </p>
                ) : (
                  <p
                    className={`${
                      isMobile
                        ? ""
                        : isTab
                        ? "w-[320px] text-left"
                        : "w-[320px] text-left"
                    }`}
                  >
                    Enter Your Contact Details
                  </p>
                )}
              </div>
              {/* </p> */}

              <form
                action=""
                className="mainForm"
                style={{
                  marginLeft: isMobile ? "16px" : isTab ? "1%" : "-80px",
                  gap: isMobile ? "12px" : "25px",
                  width: isMobile ? "" : isTab ? "320px" : "375px",
                }}
              >
                {currStep === 1 ? (
                  <>
                    {/* Form starts here ------------------------------------------------------------------------------- */}
                    {/* Type of logo */}
                    <div
                      style={{
                        position: "relative",
                        width: isMobile ? "90%" : isTab ? "300px" : "400px",
                      }}
                    >
                      <label htmlFor="TypeOfLogo" className="formLabels">
                        What type of logo are you looking for?
                      </label>

                      <div
                        ref={(el) => (buttonRef.current["dropdown1"] = el)}
                        style={{
                          padding: "5px",
                          cursor: "pointer",
                          position: "relative",
                          background: "#18141f05",
                          textAlign: "left",
                        }}
                        onClick={() => toggleDropdown("dropdown1")}
                      >
                        {formData.TypeOfLogo.length > 0 ? (
                          formData.TypeOfLogo.join(", ")
                        ) : (
                          <div className="flex justify-between text-[#808080]">
                            <p>Wordmark, Icon-based, Combination logo</p>
                            <p>
                              <IoIosArrowDown />
                            </p>
                          </div>
                        )}

                        <div
                          id="dropdown1"
                          ref={(el) => (dropdownRef.current["dropdown1"] = el)}
                          className="hidden"
                          style={{
                            position: "absolute",
                            background: "#fff",
                            border: "1px solid #ccc",
                            borderRadius: "5px",
                            width: "100%",
                            maxHeight: "150px",
                            overflowY: "auto",
                            zIndex: 1000,
                          }}
                        >
                          {TypeOfLogo_Value.map((option, index) => (
                            <label
                              key={index}
                              style={{
                                display: "flex",
                                flexDirection: "row",
                                justifyContent: "space-between",
                                alignItems: "center",
                                padding: "5px",
                                background: "#18141f05",
                              }}
                            >
                              <div className="flex gap-2">
                                <input
                                  type="checkbox"
                                  value={option.label}
                                  checked={formData.TypeOfLogo.includes(
                                    option.label
                                  )}
                                  onChange={(e) =>
                                    handleCheckboxChange("TypeOfLogo", e)
                                  }
                                  className="ml-2"
                                />
                                {option.label}
                              </div>
                              <img
                                src={option.value}
                                alt={option.label}
                                className="w-[100px] h-[50px] object-contain mr-5 border border-gray-300 p-2 rounded shadow"
                              />
                            </label>
                          ))}
                        </div>
                      </div>
                    </div>

                    {/* Logo Style */}

                    <div
                      style={{
                        position: "relative",
                        width: isMobile ? "90%" : isTab ? "300px" : "400px",
                      }}
                    >
                      <label htmlFor="LogoStyle" classname="formLabels ">
                        Which logo style best suits your brand?
                      </label>
                      {errors.LogoStyle && (
                        <div
                          style={{
                            color: "red",
                            display: "flex",
                            alignItems: "center",
                          }}
                        >
                          {errors.LogoStyle}
                          <sup>*</sup>
                        </div>
                      )}
                      <div
                        ref={(el) => (buttonRef.current["dropdown3"] = el)}
                        style={{
                          padding: "5px",
                          cursor: "pointer",
                          position: "relative",
                          background: "#18141f05",
                          textAlign: "left",
                        }}
                        onClick={() => toggleDropdown("dropdown3")}
                      >
                        {formData.LogoStyle.length > 0 ? (
                          formData.LogoStyle.join(", ")
                        ) : (
                          <div className="flex justify-between text-[#808080]">
                            <p>
                              {" "}
                              Minimalist, Vintage, Modern, Playful,
                              Corporate/Professional
                            </p>
                            <p>
                              <IoIosArrowDown />
                            </p>
                          </div>
                        )}
                        <div
                          id="dropdown3"
                          ref={(el) => (dropdownRef.current["dropdown3"] = el)}
                          className="hidden"
                          style={{
                            position: "absolute",
                            background: "#fff",
                            border: "1px solid #ccc",
                            borderRadius: "5px",
                            width: "100%",
                            maxHeight: "150px",
                            overflowY: "auto",
                            zIndex: 1000,
                          }}
                          aria-required
                        >
                          {LogoStyle.map((option) => (
                            <label
                              key={option}
                              style={{
                                display: "flex",
                                flexDirection: "row",
                                alignItems: "center",
                                padding: "5px",
                                background: "#18141f05",
                                gap: "10px",
                              }}
                            >
                              <input
                                type="checkbox"
                                value={option}
                                checked={formData.LogoStyle.includes(option)}
                                onChange={(e) =>
                                  handleCheckboxChange("LogoStyle", e)
                                }
                              />
                              {option.charAt(0).toUpperCase() + option.slice(1)}
                            </label>
                          ))}
                        </div>
                      </div>
                    </div>

                    {/* Inital Logo concepts */}
                    <label style={labelStyle} htmlFor="LogoConcepts">
                      How many initial logo concepts would you like to receive?
                      <br />
                      <select
                        style={{
                          width: isMobile ? "" : isTab ? "300px" : "400px",
                        }}
                        id="LogoConcepts"
                        name="LogoConcepts"
                        defaultValue=""
                        value={formData.LogoConcepts}
                        onChange={handleFormData}
                        className="bg-[#18141f05] w-[300px] text-[grey] h-[30px]"
                      >
                        <option disabled value="">
                          Select
                        </option>
                        <option value="1">1</option>
                        <option value="2">2</option>
                        <option value="3">3</option>
                        <option value="3+">3+</option>
                      </select>
                    </label>

                    {/* Revisions */}
                    <label
                      style={labelStyle}
                      className="formLabels"
                      htmlFor="Revisions"
                    >
                      How many revisions would you like to have in the package?
                      <br />
                      {errors.Revisions && (
                        <div style={{ color: "red", zIndex: 10000 }}>
                          {errors.Revisions}
                          <sup>*</sup>
                        </div>
                      )}
                      <select
                        style={{
                          width: isMobile ? "" : isTab ? "300px" : "400px",
                        }}
                        name="Revisions"
                        defaultValue=""
                        value={formData.Revisions}
                        onChange={handleFormData}
                        id="Revisions"
                        className="bg-[#18141f05] w-[300px] text-[grey] h-[30px] "
                      >
                        <option disabled value="">
                          Select
                        </option>
                        <option value="1"> 1</option>
                        <option value="2">2</option>
                        <option value="3">3</option>
                        <option value="4">4</option>
                        <option value="5+">5+</option>
                      </select>
                    </label>

                    {/* Branding assets */}
                    <div
                      style={{
                        position: "relative",
                        width: isMobile ? "90%" : isTab ? "300px" : "400px",
                      }}
                    >
                      <label htmlFor="Assests">
                        Do you need additional branding assets along with the
                        logo?
                      </label>
                      <div
                        ref={(el) => (buttonRef.current["dropdown4"] = el)}
                        style={{
                          padding: "5px",
                          cursor: "pointer",
                          position: "relative",
                          background: "#18141f05",
                          textAlign: "left",
                        }}
                        onClick={() => toggleDropdown("dropdown4")}
                      >
                        {formData.Assests.length > 0 ? (
                          formData.Assests.join(", ")
                        ) : (
                          <div className="flex justify-between text-[#808080]">
                            <p>
                              Social media graphics, Business card design, Other
                            </p>
                            <p>
                              <IoIosArrowDown />
                            </p>
                          </div>
                        )}
                        <div
                          id="dropdown4"
                          ref={(el) => (dropdownRef.current["dropdown4"] = el)}
                          className="hidden"
                          style={{
                            position: "absolute",
                            background: "#fff",
                            border: "1px solid #ccc",
                            borderRadius: "5px",
                            width: "100%",
                            maxHeight: "150px",
                            overflowY: "auto",
                            zIndex: 10001,
                          }}
                        >
                          {Assets.map((option) => (
                            <label
                              key={option}
                              style={{
                                display: "flex",
                                flexDirection: "row",
                                alignItems: "center",
                                padding: "5px",
                                background: "#18141f05",
                                gap: "10px",
                              }}
                            >
                              <input
                                type="checkbox"
                                value={option}
                                checked={formData.Assests.includes(option)}
                                onChange={(e) =>
                                  handleCheckboxChange("Assests", e)
                                }
                              />
                              {option.charAt(0).toUpperCase() + option.slice(1)}
                            </label>
                          ))}
                        </div>
                      </div>
                    </div>

                    {/* Budget */}
                    <label className="block" htmlFor="budget">
                      <div
                        className="flex flex-col "
                        style={{
                          alignItems: "start",
                        }}
                      >
                        <span>Budget</span>
                        <br />
                        <div
                          className="relative "
                          style={{
                            width: isMobile ? "90%" : isTab ? "300px" : "400px",
                          }}
                        >
                          <ReactSlider
                            value={formData.priceRange}
                            onChange={handleSliderChange}
                            min={999}
                            max={10000}
                            step={500}
                            className="w-full h-2 bg-gray-300 rounded-full"
                            thumbClassName="w-4 h-4 bg-blue-500 rounded-full cursor-pointer -mt-[3px] "
                            trackClassName="bg-blue-500 rounded-full "
                            renderThumb={(props) => <div {...props} />} // Remove default value display
                          />
                          <div className="flex justify-between text-sm text-gray-600 mt-2">
                            <span>₹{formData.priceRange[0]}</span>
                            <span>₹{formData.priceRange[1]}</span>
                          </div>
                        </div>
                      </div>
                    </label>

                    {/* Color */}
                    <div
                      style={{
                        position: "relative",
                        width: isMobile ? "90%" : isTab ? "300px" : "400px",
                      }}
                    >
                      <label
                        htmlFor="Color"
                        classname="formLabels "
                        style={{ textAlign: "" }}
                      >
                        Any Color Scheme or Color You have in your Mind?
                      </label>
                      <div
                        ref={(el) => (buttonRef.current["dropdown2"] = el)}
                        style={{
                          padding: "5px",
                          cursor: "pointer",
                          position: "relative",
                          background: "#18141f05",
                          textAlign: "left",
                        }}
                        onClick={() => toggleDropdown("dropdown2")}
                      >
                        {formData.Color.length > 0 ? (
                          formData.Color.join(", ")
                        ) : (
                          <div className="flex justify-between text-[#808080]">
                            <p>Red, Yellow, Blue, Black, Green Etc</p>
                            <p>
                              <IoIosArrowDown />
                            </p>
                          </div>
                        )}
                        <div
                          id="dropdown2"
                          ref={(e1) => (dropdownRef.current["dropdown2"] = e1)}
                          className="hidden"
                          style={{
                            position: "absolute",
                            background: "#fff",
                            border: "1px solid #ccc",
                            borderRadius: "5px",
                            width: "100%",
                            maxHeight: "150px",
                            overflowY: "auto",
                            zIndex: 1000,
                          }}
                        >
                          {Color.map((option) => (
                            <label
                              key={option}
                              style={{
                                display: "flex",
                                flexDirection: "row",
                                alignItems: "center",
                                padding: "5px",
                                background: "#18141f05",
                                gap: "10px",
                              }}
                            >
                              <input
                                type="checkbox"
                                value={option}
                                checked={formData.Color.includes(option)}
                                onChange={(e) =>
                                  handleCheckboxChange("Color", e)
                                }
                              />
                              {option.charAt(0).toUpperCase() + option.slice(1)}
                            </label>
                          ))}
                        </div>
                      </div>
                    </div>

                    {/* Delivery time */}
                    <label
                      style={labelStyle}
                      classname="formLabels"
                      htmlFor="deliveryTime"
                    >
                      Delivery Time
                      <br />
                      {errors.deliveryTime && (
                        <div
                          style={{
                            color: "red",
                            display: "flex",
                            alignItems: "center",
                            zIndex: 1000,
                          }}
                        >
                          {errors.deliveryTime}
                          <sup>*</sup>
                        </div>
                      )}
                      <select
                        style={{
                          width: isMobile ? "" : isTab ? "300px" : "400px",
                        }}
                        id="deliveryTime"
                        name="deliveryTime"
                        value={formData.deliveryTime}
                        onChange={handleFormData}
                        defaultValue=""
                        className="bg-[#18141f05] w-[300px] text-[grey] h-[30px]"
                      >
                        <option disabled value="">
                          Same Day, 2 Days, 3 Days, 4 Days, 5 Days+
                        </option>
                        <option value="sameDay">Same day</option>
                        {/* <option value="1Day">1 day</option> */}
                        <option value="2 Days">2 days</option>
                        <option value="3 Days">3 days</option>
                        <option value="4 Days">4 days</option>
                        <option value="5 days">5+ days</option>
                      </select>
                    </label>

                    <button
                      className="flex bg-[#1C6ED0] w-fit text-white px-7 py-1 rounded-sm"
                      onClick={handleNext}
                    >
                      Next
                    </button>
                    <br />
                  </>
                ) : (
                  <>
                    <label style={labelStyle}>
                      First Name
                      <br />
                      {errors.fullName && (
                        <div
                          style={{
                            color: "red",
                            display: "flex",
                            alignItems: "center",
                          }}
                        >
                          {errors.fullName}
                          <sup>*</sup>
                        </div>
                      )}
                      <input
                        type="text"
                        name="fullName"
                        value={formData.fullName}
                        onChange={handleFormData}
                        style={{
                          width: isMobile ? "" : isTab ? "300px" : "400px",
                        }}
                        className="bg-[#18141f05] w-[300px] text-[grey] h-[30px] pl-2 placeholder-gray-500 rounded"
                        placeholder="Enter your first name here"
                      />
                    </label>
                    <label style={labelStyle}>
                      Business Name
                      <br />
                      <input
                        type="text"
                        name="business"
                        value={formData.business}
                        onChange={handleFormData}
                        style={{
                          width: isMobile ? "" : isTab ? "300px" : "400px",
                        }}
                        className="bg-[#18141f05] w-[300px] text-[grey] h-[30px] pl-2 placeholder-gray-500 rounded"
                        placeholder="Company Name"
                      />
                    </label>
                    <label style={labelStyle}>
                      Email
                      <br />
                      {errors.email && (
                        <div
                          style={{
                            color: "red",
                            display: "flex",
                            alignItems: "center",
                          }}
                        >
                          {errors.email}
                          <sup>*</sup>
                        </div>
                      )}
                      <input
                        type="email"
                        style={{
                          width: isMobile ? "" : isTab ? "300px" : "400px",
                        }}
                        name="email"
                        value={formData.email}
                        onChange={handleFormData}
                        className="bg-[#18141f05] w-[300px] text-[grey] h-[30px] pl-2 placeholder-gray-500 rounded"
                        placeholder="Enter your email here"
                      />
                    </label>
                    <label style={labelStyle}>
                      Phone Number
                      <br />
                      {errors.phone && (
                        <div
                          style={{
                            color: "red",
                            display: "flex",
                            alignItems: "center",
                          }}
                        >
                          {errors.phone}
                          <sup>*</sup>
                        </div>
                      )}
                      <input
                        type="text"
                        value={formData.phone}
                        name="phone"
                        onChange={handleFormData}
                        style={{
                          width: isMobile ? "" : isTab ? "300px" : "400px",
                        }}
                        className="bg-[#18141f05] w-[300px] text-[grey] h-[30px] pl-2 placeholder-gray-500 rounded"
                        placeholder="Enter your phone number here"
                      />
                    </label>
                    <label style={labelStyle}>
                      Website
                      <br />
                      <input
                        type="text"
                        value={formData.website}
                        name="website"
                        onChange={handleFormData}
                        style={{
                          width: isMobile ? "" : isTab ? "300px" : "400px",
                        }}
                        className="bg-[#18141f05] w-[300px] text-[grey] h-[30px] pl-2 placeholder-gray-500 rounded"
                        placeholder="www.bizzowl.com"
                      />
                    </label>
                    <button
                      className="flex bg-[#1C6ED0] w-fit text-white px-7 py-1 rounded-sm"
                      onClick={handleSubmit}
                    >
                      View plans
                    </button>
                    <br />
                  </>
                )}
              </form>
            </div>
          </div>

          <br />
          <br />
          {currStep !== 1 && (
            <div className="mb-10 w-[60%] mx-auto">
              <p>
                By clicking on "View Plans", you agree to our
                <span className="text-[#1C6ED0]"> Privacy Policy</span>  and
                <span className="text-[#1C6ED0]">Terms of Use</span>
              </p>
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default FirstForm;
