import { Checkbox, Radio, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react'
import { FaChevronLeft, FaChevronDown } from "react-icons/fa";
import DownArrowPic from "./assets/Vector (5).png";
import BlueDownArrow from "./assets/Vector (6).png";
import ReactSlider from 'react-slider';



const CommonFilter = ({ showOverlay, toggleOverlay, filters, setFilters, handleFilter, handleReset, handleApplyFilter, handleClick, openMenu, StyledFormControl, StyledFormControlLabel, selectedIndexPr, selectedIndexLang, selectedIndexGeo, selectedIndexTarget, selectedIndexIndustry, handleSliderChange }) => {

  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);


  // useEffect(() => {
  //   // Log the updated state whenever filters change
  //   console.log('Press Releasing:', filters);

  // }, [filters]);

  //data 
  const industries = [
    'Technology',
    'E-commerce',
    'Fintech',
    'IOT',
    'Artificial Intelligence',
    'Food and Beverage',
    'Health & Wellness',
    'Media & Entertainment',
    'Travel & Hospitality',
    'Education',
    'Real Estate',
    'Energy',
    'Agriculture',
    'B2B SaaS',
    'BioTech',
    'Space Tech',
    'Cyber Security',
    'Legal Tech',
    'Fashion & Apparel',
    'Beauty & Personal Care',
    'Automotive',
    'Retail',
    'Cleantech',
    'Deeptech',
    'Robotics',
    'Power',
    'VR/AR',
    'Other'
  ];
  const options = [
    "Yes", "No"
  ];

  const languages = ["English", "Hindi", "Other"];
  const geoTargets = ["National", "International", "Local"]

  return (


    <div className=''>
      {showOverlay && (


        <div className="" style={{
          position: 'fixed',
          top: 0,
          left: 0,
          width: '100vw',
          height: '100vh',
          backgroundColor: 'white', // Semi-transparent background
          zIndex: 1000, // Ensure the overlay is on top of other content
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          padding: "27px",
          // overflowY:"hidden",
          // overflowX:"hidden"

        }}>

          {/* flex navbar */}

          <div style={{
            display: "flex",
            justifyContent: "space-around",
            alignItems: "center",


            width: "100vw",
            // marginLeft:"-20px",
            boxShadow: '0 5px 5px 0px rgba(0, 0, 0, 0.05)',
            gap: 50


          }}>

            <div
              style={{
                width: "90%",
                display: "flex",
                gap: 20,
                alignItems: "center",
                paddingBottom: "10px"


              }}>
              <FaChevronLeft onClick={toggleOverlay} style={{
                // marginLeft:"-3rem",
                fontSize: "30px"
              }} />
              <Typography variant='body1' style={{
                color: "#50afe6"
              }}>Filter Plans By</Typography>
            </div>

          </div>

          {/* rest all content */}

          <div
            className=""
            style={{
              boxShadow: "0rem 0.25rem 0.25rem 0rem rgba(0, 0, 0, 0.25)",
              backgroundColor: "#f7fcfb",
              minHeight: "100vh",
              minWidth: "100vw",
              padding: "20px",
              overflowY: "auto"

            }}
          >



            {/* budget section */}
            <div className="flex-col justify-content-between align-items-center mt-3 " onClick={() => handleClick("budget")}
            >

              <div className="flex justify-between w-[100%]" style={{
                marginBottom: 20
              }}>
                <h6>Budget</h6>
                <img
                  src={BlueDownArrow}
                  alt="BlueDownArrow"
                  width="17rem"
                  height="10rem"
                />
              </div>
              {/* budget slider */}
              {
                openMenu === "budget" && (
                  <div
                    style={{
                      display: "block",
                      width: '',
                      marginTop: 10,

                    }}
                  >
                    <div style={{ position: 'relative' }}>
                      <ReactSlider
                        value={filters.priceRange}
                        onChange={handleSliderChange}
                        min={0}
                        max={100000}
                        step={500}
                        className="w-full h-2 bg-gray-300 rounded-full"
                        thumbClassName="w-4 h-4 bg-blue-500 rounded-full cursor-pointer -mt-[3px] "
                        trackClassName="bg-blue-500 rounded-full "
                        renderThumb={(props) => <div {...props} />} // Remove default value display
                      />
                      <div className="flex justify-between text-sm text-gray-600 mt-2">
                        <span>₹{filters.priceRange[0]}</span>
                        <span>₹{filters.priceRange[1]}</span>
                      </div>
                      {/* <div className="flex justify-between text-sm text-gray-600 mt-2 gap-x-10">
                                <span>₹0</span>
                                <span>₹100,000</span>
                            </div> */}

                      {/* <div
            style={{
              position: 'absolute',
              left: `45%`, // Adjust the -20px offset based on the width of the box
              top: '24px', // Adjust the vertical position as needed
              backgroundColor: 'white',
              border: '1px solid gray',
              padding: '2px 8px',
              borderRadius: '10px',
              whiteSpace: 'nowrap',
              transform: 'translateX(-50%)', // Center the box horizontally
            }}
          >
            {filters.priceRange}
          </div> */}
                    </div>
                  </div>
                )
              }
              <div className="border border-b-gray-500 w-[100%] mt-2" style={{
                marginBottom: "20px"
              }} ></div>
            </div>


            {/* press release writing */}
            <div className="flex-col justify-content-between align-items-center mt-2"
              onClick={() => handleClick("prWriting")}>

              <div className="flex justify-between w-[100%] mb-20" style={{
                marginBottom: 20
              }}>        <h6>Press Releasing writing</h6>
                <img
                  src={BlueDownArrow}
                  alt="BlueDownArrow"
                  width="17rem"
                  height="10rem"
                />

              </div>

              <div className="">



                {openMenu === "prWriting" && (
                  <div >
                    <StyledFormControl component="fieldset" >
                      {options.map((option, index) => (
                        <StyledFormControlLabel
                          key={option}
                          control={
                            <Radio
                              checked={filters.pressReleasing === option}
                              name="pressReleasing"
                              onChange={handleFilter}
                              value={option}
                            />
                          }
                          label={option}

                        />
                      ))}
                    </StyledFormControl>
                  </div>
                )}
                <div className="border border-b-gray-500 w-[100%] mt-2" style={{
                  marginBottom: "20px"
                }}></div>
              </div>


            </div>


            {/* for media outlets */}
            <div className="flex-col justify-content-between align-items-center mt-2"
              onClick={() => handleClick("media")}>
              <div className="flex justify-between w-[100%] mb-20" style={{
                marginBottom: 20
              }}>
                <h6>No. Media Outlets</h6>
                <img
                  src={BlueDownArrow}
                  alt="BlueDownArrow"
                  width="17rem"
                  height="10rem"
                />
              </div>
              {
                openMenu === "media" && (
                  <div
                    style={{
                      display: "block",
                      width: '',
                      marginTop: 10
                    }}
                  >
                    <div style={{ position: 'relative' }}>
                      <input
                        id="outlets"
                        type="range"
                        name="outlets"
                        min="0"
                        max="1000"
                        step="5"
                        value={filters.outlets}
                        onChange={handleFilter}
                        className="w-full h-2 bg-gray-200 rounded-full appearance-none cursor-pointer "
                      />
                      <div
                        style={{
                          position: 'relative',
                          left: `50%`, // Adjust the -20px offset based on the width of the box
                          // Adjust the vertical position as needed
                          backgroundColor: 'white',
                          border: '1px solid gray',
                          padding: '1px 0px',
                          borderRadius: '10px',
                          whiteSpace: 'nowrap',
                          transform: 'translateX(-50%)', // Center the box horizontally
                          maxWidth: "80px"
                        }}
                      >
                        {filters.outlets}+
                      </div>

                    </div>
                  </div>
                )
              }

              <div className="border border-b-gray-500 w-[100%] mt-2" style={{
                marginBottom: "20px"
              }}></div>

            </div>

            {/* fro language */}
            <div className="flex-col justify-content-between align-items-center mt-2"
              onClick={() => handleClick("language")}>

              <div className="flex justify-between w-[100%] mb-20" style={{
                marginBottom: 20
              }}>        <h6>Language</h6>
                <img
                  src={BlueDownArrow}
                  alt="BlueDownArrow"
                  width="17rem"
                  height="10rem"
                />

              </div>





              {openMenu === "language" && (
                <div >
                  <StyledFormControl component="fieldset" >
                    {languages.map((option, index) => (
                      <StyledFormControlLabel
                        key={option}
                        control={
                          <Checkbox
                            checked={filters.language.includes(option)}
                            name="language"
                            onChange={handleFilter}
                            value={option}
                          />
                        }
                        label={option}

                      />
                    ))}
                  </StyledFormControl>
                </div>
              )}
              <div className="border border-b-gray-500 w-[100%] mt-2" style={{
                marginBottom: "20px"
              }}></div>



            </div>


            {/* for geo-tag */}
            <div className="flex-col justify-content-between align-items-center mt-2"
              onClick={() => handleClick("geo-tag")}>

              <div className="flex justify-between w-[100%] mb-20" style={{
                marginBottom: 20
              }}>        <h6>Geo Tag</h6>
                <img
                  src={BlueDownArrow}
                  alt="BlueDownArrow"
                  width="17rem"
                  height="10rem"
                />

              </div>



              {openMenu === "geo-tag" && (
                <div >
                  <StyledFormControl component="fieldset" >
                    {options.map((option, index) => (
                      <StyledFormControlLabel
                        key={option}
                        control={
                          <Radio
                            checked={filters.geoTag === option}
                            onChange={handleFilter}
                            name="geoTag"
                            value={option}
                          />
                        }
                        label={option}

                      />
                    ))}
                  </StyledFormControl>
                </div>
              )}
              <div className="border border-b-gray-500 w-[100%] mt-2" style={{
                marginBottom: "20px"
              }}></div>





            </div>

            {/* geo target */}
            <div className="flex-col justify-content-between align-items-center mt-2"
              onClick={() => handleClick("target")}>

              <div className="flex justify-between w-[100%] mb-20" style={{
                marginBottom: 20
              }}>        <h6>Geographical Target</h6>
                <img
                  src={BlueDownArrow}
                  alt="BlueDownArrow"
                  width="17rem"
                  height="10rem"
                />

              </div>

              <div className="">



                {openMenu === "target" && (
                  <div >
                    <StyledFormControl component="fieldset" >
                      {geoTargets.map((option, index) => (
                        <StyledFormControlLabel
                          key={option}
                          control={
                            <Checkbox
                              checked={filters.geoTarget.includes(option)}
                              onChange={handleFilter}
                              name="geoTarget"
                              value={option}
                            />
                          }
                          label={option}

                        />
                      ))}
                    </StyledFormControl>
                  </div>
                )}
                <div className="border border-b-gray-500 w-[100%] mt-2" style={{
                  marginBottom: "20px"
                }}></div>
              </div>


            </div>

            {/* for industries */}
            <div className="flex-col justify-content-between align-items-center mt-2 "
              onClick={() => handleClick("industry")}>

              <div className="flex justify-between w-[100%] mb-20" style={{
                marginBottom: 20
              }}>        <h6>Industry Specific</h6>
                <img
                  src={BlueDownArrow}
                  alt="BlueDownArrow"
                  width="17rem"
                  height="10rem"
                />

              </div>

              <div className="relative inline-block text-left">

                <div>

                </div>

                {openMenu === "industry" && (
                  <div >
                    <StyledFormControl component="fieldset" style={{
                      display: "flex"
                    }}>
                      {industries.map((option, index) => (
                        <StyledFormControlLabel
                          key={option}
                          control={
                            <Checkbox
                              checked={filters.industry.includes(option)}
                              onChange={handleFilter}
                              name="industry"
                              value={option}
                            />
                          }
                          label={option}

                        />
                      ))}
                    </StyledFormControl>
                  </div>
                )}
                <div className="border border-b-gray-500 w-[100%] mt-2" style={{
                  marginBottom: "20px"
                }}></div>

              </div>


            </div>

            <button type='reset' onClick={handleReset} style={{
              padding: '10px 20px',
              borderRadius: '24px',
              backgroundColor: '#1976D2',
              color: 'white',
              cursor: 'pointer',
              marginLeft: '10px',
              marginTop: "25px",
              marginBottom: "70px",
              fontWeight: 'bold',
              fontSize: '16px',
              boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)',
              width: "40%"
            }}>Reset</button>

          </div>

        </div>

      )}
    </div>
  )
}

export default CommonFilter