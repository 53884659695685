import React from 'react'
import callUsSvg from '../../assets/callUs.svg'
import { useMediaQuery } from '@mui/material';

const CallUs = () => {
  const isMobile = useMediaQuery("(max-width: 768px)");

  return (
    <div className="bg-blue-100 p-2 flex items-center justify-center">
        <img style={{width: isMobile && "25px"}} src={callUsSvg} className="mr-2"/>
        <p style={{fontSize: isMobile && "14px"}} className="text-[#1C6ED0]">Call Us</p>
    </div>
  )
}

export default CallUs