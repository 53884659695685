export const MVPDevObj={
    serviceName: "MVP Development Service",
    icon: "/Images/selfService/MVPDevImages/mvp-icon.png",

    heroSection: {
        image: "/Images/selfService/MVPDevImages/MVPDevHeroImg.png",
        message: "Validate Quickly, Scale Smartly.",
        heading: "Kickstart Your MVP with ",
        headingPortion: "Expert Development Services",
        highlightedText: "Our MVP Development Services help you",
        content:
          "bring your product idea to life by focusing on essential features, validating your concept, and ensuring scalability. Test your idea with minimal risk and grow with confidence.",
      },

      aboutService: {
        content1:
          "Our MVP Development Services guide startups, entrepreneurs, and product managers in building a Minimum Viable Product that brings their ideas to life with minimal risk and maximum potential. ",
        content2:
          " We help you define essential features, test your idea with real users, and refine it based on feedback, ensuring your product stands out in the market.",
      },

      features: [
        {
          icon: "/Images/selfService/MVPDevImages/details/det1.png",
          title: "MVP Feature Identification",
          description:
            "Identify the minimum set of features required to validate your product idea in the market, ensuring it's both effective and resource-efficient.",
        },
        {
          icon: "/Images/selfService/MVPDevImages/details/det4.png",
          title: "Product Competence Evaluation",
          description:
            "Assess the core competencies of your product, and define the features that will give it an edge over competitors.",
        },
        {
            icon: "/Images/selfService/MVPDevImages/details/det2.png",
            title: "User-Centric Design",
            description:
              "Ensure your MVP offers a user-friendly design that addresses customer pain points and meets essential needs.",
          },
        {
          icon: "/Images/selfService/MVPDevImages/details/det5.png",
          title: "Iterative Development Approach",
          description:
            "Adopt an agile, iterative process that allows for constant improvements based on user feedback and market insights.",
        },
        {
          icon: "/Images/selfService/MVPDevImages/details/det3.png",
          title: "Technical Feasibility Assessment",
          description:
            "Evaluate the technical feasibility of your MVP to ensure it can be built efficiently within the given time and budget constraints.",
        },
        {
          icon: "/Images/selfService/MVPDevImages/details/det6.png",
          title: "Scalable Architecture Design",
          description:
            "Build your MVP with scalability in mind, ensuring it can grow with your product's success.",
        },
        
      ],

      payemnetSummary: {
        totalPayment: "5049",
        gst: "908",
        Payable: "5958",
      },

      benefits: {
        title: "Benefits of MVP Development Service",
        data: [
          {
            title: "Validated Product Concept",
            description:
              "Test your idea in the market with minimal risk and feedback-driven iterations.",
            sno: "01",
          },
          {
            title: "Focused Feature Set",
            description:
              "Ensure you build only the essential features needed for early success.",
            sno: "02",
          },
          {
            title: "Cost-Efficient Development",
            description:
              "Avoid unnecessary expenses by focusing on what matters most.",
            sno: "03",
          },
          {
            title: "Faster Time to Market",
            description: "Get your product into users' hands quickly, enabling faster validation.",
            sno: "04",
          },
          {
            title: "Scalability",
            description: "Build an MVP that can grow with your business as you scale.",
            sno: "05",
          },
          {
            title: "Risk Mitigation",
            description: "Minimize potential losses by validating ideas before full-scale development.",
            sno: "06",
          },
        ],
      },







      featuresOverview: [
        {
          title: "MVP Feature Identification",
          icon: "/Images/selfService/MVPDevImages/details/det1.png",
        },
        {
          title: "Product Competence Evaluation",
          icon: "/Images/selfService/MVPDevImages/details/det2.png",
        },
        {
          title: "User-Centric Design",
          icon:  "/Images/selfService/MVPDevImages/features/ft3.png",
         
        },
        {
          title: "Iterative Development Process",
          icon: "/Images/selfService/MVPDevImages/features/ft7.png",
        },
        {
          title: "Technical Feasibility Assessment",
          icon: "/Images/selfService/MVPDevImages/features/ft4.png",
        },
        {
          title: "Scalable Architecture Design",
          icon: "/Images/selfService/MVPDevImages/features/ft8.png",
        },
        {
          title: "Agile Project Management",
          icon: "/Images/selfService/MVPDevImages/features/ft5.png",
        },
        {
          title: "Product Testing & Feedback Integration",
          icon: "/Images/selfService/MVPDevImages/features/ft9.png",
        },
        {
          title: "MVP Roadmap Creation",
          icon: "/Images/selfService/MVPDevImages/features/ft6.png",
        },
        {
          title: "Customized Consulting & Support",
          icon: "/Images/selfService/MVPDevImages/features/ft10.png",
        },
      ],


      howItWorks: [
        {
          bg:"/Images/selfService/IdeaValidationImages/IdeaValWorks/fg1.png",
          fg:"/Images/selfService/howItWorks/InitialCOnsultation.png",
          step: "Initial Consultation",
          description:
            "Discuss your product idea and goals to identify the core features.",
        },
        {
          bg:"/Images/selfService/MVPDevImages/works/Vectorpink.png",
          fg:"/Images/selfService/howItWorks/mvpDef.png",
          step: "MVP Feature Definition",
          description:
            "Define the minimum features needed to achieve product-market fit.",
        },
        {
          bg:"/Images/selfService/MVPDevImages/works/VectorDevTest.png",
          fg:"/Images/selfService/howItWorks/Dev&Testing.png",
          step: "Development & Testing",
          description:
            "Build and test your MVP using iterative cycles.",
        },
        {
          bg:"/Images/selfService/IdeaValidationImages/IdeaValWorks/fg4.png",
          fg:"/Images/selfService/howItWorks/Results.png",
          step: "Launch & Feedback",
          description:
            "Launch your MVP, gather user feedback, and iterate based on results.",
        },
      ],

      targetAudience: [
        {
          icon: "/Images/servicePage/targeticon1.png",
          iconMobile: "/Images/servicePage/targeticonMobile1.png",
          title: "Aspiring Entrepreneurs",
          content1: "For those starting out and needing a Minimum Viable Product to validate their business idea.",
          content2:
          "",
        },
        {
          icon: "/Images/servicePage/targeticon2.png",
          iconMobile: "/Images/servicePage/targeticonMobile2.png",
          title: "Small Businesses",
          content1: "Ideal for those overseeing product development and requiring efficient MVP execution.",
          content2:
          "",
        },
       
        
        {
          icon: "/Images/servicePage/targeticon3.png",
          iconMobile: "/Images/servicePage/targeticonMobile3.png",
          title: "Startups",
          content1: "For early-stage businesses looking to test their ideas before full product development. ",
          content2:
            "",
        },
        {
          icon: "/Images/servicePage/targeticon4.png",
          iconMobile: "/Images/selfService/BusinessConsultancyImages/prodManagerMobile.png",
          title: "Product Managers",
          content1:
          "Perfect for businesses planning to expand their product offering and test new markets.",
          content2:
          "",
        },
      ],

      faq: [
        {
          que: "What is MVP development?",
          ans: "MVP development focuses on building the core, minimal set of features required to validate a product idea and gather user feedback."
,
        },
        {
          que: "Why is MVP important for startups?",
          ans: "MVP helps startups test their product idea in the market with minimum risk, saving costs and time in the development process.",
        },
        {
          que: "How long does MVP development take?",
          ans: "Typically, MVP development takes between 4 to 12 weeks, depending on the complexity of the product.",
        },
        {
          que: "What industries do you work with?",
          ans: "We work across various industries, including tech, retail, healthcare, and more, providing tailored MVP solutions.",
        },
        {
            que: "Can MVP be scaled later?",
            ans: "Yes, we build MVPs with scalability in mind, ensuring you can expand your product as your business grows.",
          },
          {
            que: "Do you provide ongoing support after the MVP launch?",
            ans: "Yes, we offer continued support post-launch to help with product iterations and further development.",
          },
        
      ],
};