import React, { useEffect, useState } from "react";
import { FaBars } from "react-icons/fa";
import "../../../Styles/CompStyle/NavBarStyle.scss";
import { Link, NavLink, useNavigate } from "react-router-dom";
import { setIsVisible } from "../../../Redux/actions";
import { useDispatch, useSelector } from "react-redux";
import { WindowOpenLink } from "../../Extra/extraFunction";
import CallUs from "./utils/CallUs";
import { useMediaQuery } from "@mui/material";

const NavBar = () => {
  const dispatch = useDispatch();
  const [isActiveHoroscope, setIsActiveHoroscope] = useState(false);
  const { isVisible, isClosed, isSubmit } = useSelector(
    (state) => state.landingPageReducer
  );
  const isMobile = useMediaQuery("(max-width: 768px)");

  useEffect(() => {
    setTimeout(() => {
      if (!isClosed && !isSubmit) dispatch(setIsVisible(true));
    }, 30000); // 30 Sec
  }, []);


  const navigate = useNavigate();

  return (
    <>
      <nav style={{height: isMobile && "50px"}} className="navbar paddingPhone0 flexPhoneLeft fixedStyleForMobile bg-body-tertiary navbar-light navBarBGLight">

        <NavLink className="navbar-brand" to="/">
          <img
            alt=""
            src="/Images/logos/logo-of-BIZOWL--Business-Services.png"
            className="webSection headerImageStyle border border-0 bg-transparent"
          />
        </NavLink>
        <NavLink className="navbar-brand" to="/">
          <img
            alt=""
            src="/Images/logos/logo-of-BIZOWL--Business-Services.png"
            className="mobileSection headerImageStyle border border-0 bg-transparent"
          />
        </NavLink>

        <div style={{marginRight: isMobile && "10px"}} className="flex phonePositionRight webPaddingRight6p fontWeight500">
          <NavLink to="/business-support" onClick={() => dispatch(setIsVisible(true))}>
            <CallUs />
          </NavLink>
        </div>
      </nav>
    </>
  );
};

export default NavBar;
