import { useMediaQuery } from "@mui/material";
import React from "react";

const AdviceSection = () => {
  const isMobile = useMediaQuery("(max-width:724px)");
  const isTab = useMediaQuery("(max-width:1024px)");
  const isLaptop = useMediaQuery("(max-width:1224px)")
  return (
    <div className="p-4" style={{ marginTop: "60px", backgroundColor:"#EFF6FF"}}>
      <h3 className="" style={{ fontSize:isMobile? "18px" :"35px", fontWeight: "500", fontFamily: "Cabin" }}>
        No need to
        <span className="" style={{ color: "#407BFF" }}>
          {" "}
          Struggle Alone{" "}
        </span>
        Anymore
      </h3>
      <div className="flex justify-between" style={{gap:"60px", flexDirection:(isMobile || isTab || isLaptop) &&"column"}}>
        <img src="Images/guidanceSection/advicebanner.png" alt=""  className="w-6/12"/>
        <div className="w-6/12 flex flex-col" style={{alignItems:"flex-start", rowGap:"16px"}}>
          <h2 className="" style={{ fontWeight: "500", fontSize:isMobile? "18px" : "35px", fontFamily: "Cabin"}}>
            Get Advise when you need it most{" "}
          </h2>
          <div className="flex" style={{columnGap: "20px", marginTop:"26px"}}>
            <img src="Images/guidanceSection/icon1.png" alt="" />
            <h2 className="" style={{ fontSize:isMobile? "12px": "15px", fontWeight: "500", textAlign:"start"}}>
              Have a 1:1 call and get advice you need, when you need.
            </h2>
          </div>
          <div className="flex" style={{columnGap: "20px"}}>
            <img src="Images/guidanceSection/icon2.png" alt="" style={{width:"60px"}}/>
            <h2 className="" style={{ fontSize:isMobile? "12px": "15px", fontWeight: "500", textAlign:"start"}}>
              Get solution to your every business challenge whether it is at
              beginning or growth stage.
            </h2>
          </div>
          <div className="flex" style={{columnGap: "20px"}}>
            <img src="Images/guidanceSection/icon3.png" alt="" />
            <h2 className="" style={{ fontSize:isMobile? "12px": "15px", fontWeight: "500", textAlign:"start"}}>
              Bizowl gives you access to both free and paid advise.
            </h2>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AdviceSection;
