import React from 'react';
import ReactSlider from 'react-slider';
import BlueDownArrow from "../assets/Vector (6).png";

const SliderSection = ({ 
  title, 
  isOpen, 
  onToggle, 
  value, 
  onChange, 
  min, 
  max, 
  step,
  displayValue,
  type 
}) => {
  return (
    <div className="flex-col justify-content-between align-items-center cursor-pointer">
      <div className="flex justify-between w-[100%] mb-[20px]" onClick={onToggle}>
        <h6 className="text-sm">{title}</h6>
        <img
          src={BlueDownArrow}
          alt="BlueDownArrow"
          width="17rem"
          height="10rem"
        />
      </div>

      {isOpen && (
        <>
          {displayValue && (
            <div className="flex justify-start mb-2">
              <p className="border-slate-500 px-4 h-8 border-2 rounded bg-[#F7FCFB] shadow">
                {displayValue}
              </p>
            </div>
          )}
          <div style={{ position: "relative" }}>
            <ReactSlider
              value={value}
              onChange={onChange}
              min={min}
              max={max}
              step={step}
              className="w-full h-2 bg-gray-300 rounded-full"
              thumbClassName="w-4 h-4 bg-blue-500 rounded-full cursor-pointer -mt-[3px]"
              trackClassName="bg-blue-500 rounded-full"
              renderThumb={(props) => <div {...props} />}
            />
            {type === 'range' && (
              <div className="flex justify-between text-sm text-gray-600 mt-2">
                <span>₹{value[0]}</span>
                <span>₹{value[1]}</span>
              </div>
            )}
          </div>
        </>
      )}
      <div className="border border-b-gray-500 w-[100%] mt-2 mb-[20px]"></div>
    </div>
  );
};

export default SliderSection;