import React from "react";
import NavBar from "../Header/NavBar";
import ServicePage from "../../pages/ServicePage";
import Footer from "../Footer/Footer";
import Bottom from "../SelfServicePage/components/Bottom";
import { TargetCustomerAnalysisObj } from "../../data/TargetCustomerAnalysis/TargetCustomerAnalysisObj";
import { Helmet } from "react-helmet";

const TargetCustomerAnalysis = () => {
  return (
    <>
      <Helmet>
      
      <meta charset="UTF-8"/>
<meta name="viewport" content="width=device-width, initial-scale=1.0"/>
<title>Target Market Analysis | Understand Your Customers | Bizowl</title>
<meta name="description" content="Bizowl's Target Market Analysis helps you define and analyze your target audience and market segments to tailor your offerings and marketing strategies for success."/>
<meta name="keywords" content="Target Market Analysis, Target Market Segmentation, Target Audience Analysis, Customer Segmentation Analysis, Target Customer Segment, Customer Analysis and Segmentation, Target Consumer Segment, Market Segmentation Strategy"/>
<meta name="robots" content="index, follow"/>
<meta name="author" content="Bizowl"/>
<meta http-equiv="Content-Language" content="en"/>
<link rel="canonical" href="https://www.bizzowl.com/services/target-market-analysis" />
<meta property="og:title" content="Target Market Analysis | Understand Your Customers | Bizowl"/>
<meta property="og:description" content="Define and analyze your target market with Bizowl's Target Market Analysis service. Identify key segments and align your marketing strategy effectively."/>
<meta property="og:image" content="https://res.cloudinary.com/dcduojrhf/image/upload/v1728309795/Target_Market_Analysis_zxf0wo.png"/>
<meta property="og:url" content="https://www.bizzowl.com/services/target-market-analysis"/>
<meta name="twitter:card" content="summary_large_image"/>
<meta name="twitter:title" content="Target Market Analysis | Understand Your Customers | Bizowl"/>
<meta name="twitter:description" content="Tailor your marketing strategies with Bizowl's Target Market Analysis. Analyze customer segments and optimize your offerings for success."/>
<meta name="twitter:image" content="https://res.cloudinary.com/dcduojrhf/image/upload/v1728309795/Target_Market_Analysis_zxf0wo.png"/>
        
      </Helmet>
      <div className="bg-[#F5F5F5] w-[100vw] overflow-x-hidden ">
        <NavBar />
        <div className=" md:!p-16 p-3 pt-0  w-[100%] md:w-[80%] mx-auto bg-[#F5F5F5]  mb-2">
          <ServicePage serviceObj={TargetCustomerAnalysisObj} />
        </div>
        <Footer />
        <Bottom serviceObj={TargetCustomerAnalysisObj} />
      </div>
    </>
  );
};

export default TargetCustomerAnalysis;
