import { useMediaQuery } from "@mui/material";
import React from "react";

const ServicePageFeatures = () => {
  const isMobile = useMediaQuery("(max-width:547px)");
  const isTab = useMediaQuery("(max-width:1024px)");
  const isLaptop = useMediaQuery("(max-width:1286px)");
  return (
    <div
      className="flex relative pt-10 pb-10"
      style={{
        justifyContent: "space-evenly",
        marginTop: "95px",
        flexDirection: (isMobile || isTab) && "column",
        padding: isMobile && "8px",
        background:"linear-gradient(90deg,#FFFFFF 40%, rgba(178, 202, 255, 0.25) 45%, rgba(64, 123, 255, 0.25) 55%)"
      }}
    >
      {/* <img src="/Images/servicePage/features/Rectangle.png" alt="backgroundBlue" style={{zIndex:"0", top:"5px", width:"100vw"}} className="absolute"/> */}
      <div
        style={{
          textAlign: "start",
          width: "370px",
          marginLeft: isMobile && "30px",
          marginBottom:"2px",
          paddingRight: isMobile && "30px",
        }}
      >
        <p style={{ fontSize: isMobile ? "12px" : "15px" }}>Why Bizowl?</p>
        <h3
          style={{
            fontSize: isMobile ? "18px" : "35px",
            fontWeight: "500",
            marginBottom: isMobile?"8px":"14px",
          }}
        >
          <span style={{ color: "#407BFF" }}>Our Platform’s </span>
          Features
        </h3>
        <p
          style={{
            fontSize: isMobile ? "12px" : "15px",
            fontWeight:"500",
            fontFamily:"'Inter', sans-serif",
            color: "#5b6569",
            lineHeight: isMobile && "1.2",
          }}
        >
          We know the process of getting services is quite overwheling so we
          made sure that, whatever market lacks we focus on that.{" "}
        </p>
      </div>
      <img
        src="/Images/servicePage/features/banner1.png"
        alt="banner3"
        style={{
          width: isMobile ? "" : "587px",
          height: isMobile ? "" : "355px",
          marginTop: (isMobile || isTab) && "65px",
          marginBottom: (isMobile || isTab) && "20px",
          paddingRight:isMobile&&"30px"
        }}
      />
    </div>
  );
};

export default ServicePageFeatures;
