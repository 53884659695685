import React from "react";
import { serviceData } from "../../data/servicePage/serviceData";
import { useMediaQuery } from "@mui/material";
import { Link } from "react-router-dom";

const ServiceOageOurService = () => {
  const isMobile = useMediaQuery("(max-width:547px)");
  const isTab = useMediaQuery("(max-width:1024px)");
  return (
    <div
      className="flex flex-col items-center"
      style={{ width: "100%", height: isMobile && "540px" }}
    >
      <h1
        style={{
          fontSize: isMobile ? "25px" : "35px",
          marginBottom: isMobile ? "25px" : "45px",
          fontWeight: isMobile && "500",
        }}
      >
        Our
        <span style={{ color: "#1c6ed0" }}> Services</span>
      </h1>
      <div
        style={{
          backgroundColor: "white",
          padding: "1rem",
          height: "max",
          width: (isMobile && "98%") || "90%",
          overflowY: "scroll",
          scrollbarWidth: "none",
          borderRadius: "25px",
          marginRight: isMobile ? "0px" : "10px",
        }}
      >
        {serviceData.map((data, index) => (
          <div key={index} style={{ marginTop: "24px" }}>
            <div
              className="flex"
              style={{
                fontSize: isMobile ? "18px" : "25px",
                fontWeight: "500",
                gap: isMobile ? "10px" : "24px",
                marginBottom: "10px",
              }}
            >
              <img src="/Images/servicePage/ourService/Line.png" alt="" />
              {data.serviceHead}
            </div>
            <div
              className="flex flex-wrap"
              style={{
                gap: isMobile ? "10px" : "24px",
                alignItems: "flex-start",
              }}
            >
              {data.imageData.map((data, index) => (
                <Link
                  to={data.route}
                  style={{ textDecoration: "none", color: "inherit" }}
                >
                  <div key={index}>
                    <img
                      src={data.img}
                      alt={data.Service}
                      style={{
                        width: isMobile && "70px",
                        height: isMobile && "48px",
                      }}
                    />
                    <h3
                      style={{
                        width: isMobile ? "70px" : "168px",
                        fontSize: isMobile ? "12px" : "15px",
                        fontWeight: "500",
                        fontFamily: "'Inter', sans-serif",
                        color: "rgba(81,87,91,1)",
                        marginTop: isMobile ? "7px" : "15px",
                      }}
                    >
                      {data.Service}
                    </h3>
                  </div>
                </Link>
              ))}
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default ServiceOageOurService;
