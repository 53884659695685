import React from 'react';
import { Radio, FormControl, FormControlLabel } from '@mui/material';
import { styled } from '@mui/material/styles';
import BlueDownArrow from "../assets/Vector (6).png";

const StyledFormControl = styled(FormControl)({
    display: "flex",
    flexDirection: "column",
    alignItems: "start",
    color: "gray",
});

const StyledFormControlLabel = styled(FormControlLabel)({
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    fontSize: "5px",
});

const RadioSection = ({ title, isOpen, onToggle, options, value, onChange }) => {
    return (
        <div className="flex-col justify-content-between align-items-center mt-2 cursor-pointer">
            <div className="flex justify-between w-[100%] mb-[20px]" onClick={onToggle}>
                <h6 className="text-sm">{title}</h6>
                <img
                    src={BlueDownArrow}
                    alt="BlueDownArrow"
                    width="17rem"
                    height="10rem"
                />
            </div>

            {isOpen && (
                <div>
                    <StyledFormControl component="fieldset">
                        {options.map((option) => (
                            <StyledFormControlLabel
                                key={option}
                                control={
                                    <Radio
                                        checked={value === option}
                                        onChange={() => onChange(option)}
                                        value={option}
                                    />
                                }
                                label={option}
                                style={{ alignItems: 'center' }} // Align radio button and label vertically
                            />
                        ))}
                    </StyledFormControl>
                </div>
            )}
            <div className="border border-b-gray-500 w-[100%] mt-2 mb-[20px]"></div>
        </div>
    );
};

export default RadioSection;