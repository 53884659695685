export const BusinessCanvasObj = {
  serviceName: "Business Model Canvas",
  icon: "/Images/selfService/BusinessCanvasImages/BusinessModel_1.png",
  heroSection: {
    image: "/Images/selfService/BusinessCanvasImages/BusinessCanvasHeroImg.png",
    message: "Because Clarity is Key to Success",
    heading: "Design Your Future:",
    headingPortion: "Create a Business Model Canvas",
    highlightedText: "Utilizing a business model canvas online helps visualize your business strategy effectively.",
    content:
      "Whether you prefer a traditional business plan canvas or a lean canvas model, having a structured approach allows you to identify key elements and optimize your business for growth.",
  },
  aboutService: {
    content1:
      "Our Business Model Canvas Consultation service is designed for startups, small businesses, and aspiring entrepreneurs.",
    content2:
      "We provide expert guidance using Lean Startup principles, helping you refine your business model, improve operations, and build a sustainable long-term strategy.",
  },
  features: [
    {
      icon: "/Images/selfService/BusinessCanvasImages/Details/Business_strategy.png",
      title: "Business Strategy Development",
      description:
        "Work with our experts to refine your overall business strategy using frameworks like the Business Model Canvas and ensure alignment with your long-term goals.",
    },
    {
      icon: "/Images/selfService/BusinessCanvasImages/Details/Guidance.png",
      title: "Operational Guidance",
      description:
        "Get advice on streamlining your operations for efficiency and scalability, tailored to the Lean Startup methodology.",
    },
    {
      icon: "/Images/selfService/BusinessCanvasImages/Details/Business_model.png",
      title: "Business Model Refinement",
      description:
        "Fine-tune your business model with the Business Plan Canvas, ensuring sustainability and profitability.",
    },
    {
      icon: "/Images/selfService/BusinessCanvasImages/Details/Customer_review.png",
      title: "Customer Segment Identification",
      description:
        "Clarify your unique offering to solve customer needs and stand out in the market.",
    },
    {
      icon: "/Images/selfService/BusinessCanvasImages/Details/Value_proposal.png",
      title: "Value Proposition Refinement",
      description:
        "Identify and target specific customer groups to align your product with their needs.",
    },
    {
      icon: "/Images/selfService/BusinessCanvasImages/Details/Salary.png",
      title: "Revenue & Cost Structure Analysis",
      description:
        "Work closely with our experts to develop the best pricing and revenue model for your business.",
    },
  ],
  payemnetSummary: {
    totalPayment: "5211",
    gst: "937",
    Payable: "6149",
  },
  benefits: {
    title: "Benefits of the Service",
    data: [
      {
        title: "Expert Guidance",
        description:
          "Work with professionals experienced in the Business Model Canvas and Lean Startup frameworks.",
        sno: "01",
      },
      {
        title: "Improve Efficiency",
        description:
          "Optimise operations and reduce waste for increased profitability.",
        sno: "02",
      },
      {
        title: "Reduce Risks",
        description:
          "Identify risks early and develop strategies to address them.",
        sno: "03",
      },
      {
        title: "Business Model Efficiency",
        description: "Create a sustainable business model using proven tools like the Business Plan Canvas.",
        sno: "04",
      },
      {
        title: "Long-Term Success",
        description: "Position your business for growth with tailored strategies.",
        sno: "05",
      },
      {
        title: "Custom Solutions",
        description: "Get personalised recommendations based on your unique needs.",
        sno: "06",
      },
    ],
  },
  featuresOverview: [
    {
      title: "Business Canvas Design",
      icon: "/Images/selfService/BusinessCanvasImages/Features/Screen.png",
    },
    {
      title: "Customer Segmentation & Targeting",
      icon: "/Images/selfService/BusinessCanvasImages/Features/Customer_review.png",
    },
    {
      title: "Revenue Stream Optimization",
      icon: "/Images/selfService/BusinessCanvasImages/Features/Recurring.png",
    },
    {
      title: "Partnership Strategy",
      icon: "/Images/selfService/BusinessCanvasImages/Features/Partnership_handshake.png",
    },
    {
      title: "Cost Structure Analysis",
      icon: "/Images/selfService/BusinessCanvasImages/Features/Cost.png",
    },
    {
      title: "Value Proposition Mapping",
      icon: "/Images/selfService/BusinessCanvasImages/Features/Mind_mapping.png",
    },
    {
      title: "Key Activities & Resources Analysis",
      icon: "/Images/selfService/BusinessCanvasImages/Features/Resources.png",
    },
    {
      title: "Customer Relationship Development",
      icon: "/Images/selfService/BusinessCanvasImages/Features/Management.png",
    },
    {
      title: "Channel Optimization",
      icon: "/Images/selfService/BusinessCanvasImages/Features/Interactive.png",
    },
    {
      title: "Actionable Business Plan",
      icon: "/Images/selfService/BusinessCanvasImages/Features/Business_plan.png",
    },
  ],

  howItWorks: [
    {
      bg:"/Images/selfService/BusinessCanvasImages/HowItWorks/fg1.png",
      fg:"/Images/selfService/howItWorks/InitialConsultation.png",
      step: "Initial Consultation",
      description:
        "We assess your current business challenges and needs.",
    },
    {
      bg:"/Images/selfService/BusinessCanvasImages/HowItWorks/fg2.png",
      fg:"/Images/selfService/howItWorks/Strategic Review.png",
      step: "Strategic Review",
      description:
        "Our team analyses your business model using the Lean Startup Model Canvas.",
    },
    {
      bg:"/Images/selfService/BusinessCanvasImages/HowItWorks/fg3.png",
      fg:"/Images/selfService/howItWorks/Customised consulation.png",
      step: "Customised Consultation",
      description:
        "Receive a tailored action plan with expert recommendations.",
    },
    {
      bg:"/Images/selfService/BusinessCanvasImages/HowItWorks/fg4.png",
      fg:"/Images/selfService/howItWorks/Results.png",
      step: "Ongoing Support",
      description:
        "We provide continuous support as you implement the strategies.",
    },
  ],
  targetAudience: [
    {
      icon: "/Images/selfService/BusinessCanvasImages/WhoShouldUseService/targeticon1.png",
      iconMobile: "/Images/selfService/BusinessCanvasImages/WhoShouldUseService/targeticonMobile1.png",
      title: "Aspiring Entrepreneurs",
      content1: "You have a business idea but need strategic direction",
      content2:
      "We help you validate and refine it, ensuring you’re ready for market entry with tools like the Business Plan Canvas.",
    },
    {
      icon: "/Images/selfService/BusinessCanvasImages/WhoShouldUseService/targeticon2.png",
      iconMobile: "/Images/selfService/BusinessCanvasImages/WhoShouldUseService/targeticonMobile2.png",
      title: "Small Businesses",
      content1: "  You want to scale your operations and grow into new markets.",
      content2:
      "We offer strategic insights based on the Lean Startup Model Canvas to help you expand.",
    },
    {
      icon: "/Images/selfService/BusinessCanvasImages/WhoShouldUseService/targeticon3.png",
      iconMobile: "/Images/selfService/BusinessCanvasImages/WhoShouldUseService/targeticonMobile3.png",
      title: "Established Businesses",
      content1:
      "You need to pivot or refine your business model",
      content2:
      "Our experts guide you in using the Business Model Canvas to innovate.",
    },
    {
      icon: "/Images/selfService/BusinessCanvasImages/WhoShouldUseService/targeticon5.png",
      iconMobile: "/Images/selfService/SWOTServiceImages/freelancerMobile.png",
      title: "Freelancers",
      content1: "You’re ready to transition from freelance work to a structured business.",
      content2:
        "We provide strategic guidance for scaling your efforts.",
    },
  ],

  faq: [
    {
      que: "What is a Business Model Canvas?",
      ans: "The Business Model Canvas is a strategic management tool that helps you visualize, design, and refine your business model.",
    },
    {
      que: "How does the Lean Startup Model Canvas apply to my business?",
      ans: "The Lean Startup Model Canvas focuses on rapid experimentation and feedback to build a scalable and sustainable business model.",
    },
    {
      que: "Do you offer ongoing support?",
      ans: "Yes, we provide ongoing support during and after the consultation to ensure you successfully implement the recommendations.",
    },
    {
      que: "Can this service help me if I’m just starting out?",
      ans: "Absolutely! This service is ideal for aspiring entrepreneurs, early-stage startups, and small business owners looking to refine their strategy.",
    },
    {
      que: "What industries do you specialize in?",
      ans: "We offer consultations across various industries, including technology, retail, and services.",
    },
  ],
};