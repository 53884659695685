import {
  useMediaQuery,
  Button,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Paper,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import BizowalLogo from "./assets/logo-of-BIZOWL--Business-Services.png";
import back from "./assets/Back-to-Quotes.png"
import PhonePic from "./assets/Vector (1).png";
import svg from "./../Services/assets/compareSVG.svg";

import { collection, doc, getDoc, getDocs, query, where } from "firebase/firestore";
import { partnerDB } from "../../config/firebase";
const CompareServices = () => {
  const [searchParams] = useSearchParams();
  console.log(searchParams);
  const navigate = useNavigate();
  const [serviceIds, setServiceIds] = useState([]);
  const [indices, setIndices] = useState([]);
  const [loading, setLoading] = useState(true); // Add loading state
  const [error, setError] = useState(null); // Optional: to handle errors
  useEffect(() => {
    const services = searchParams.get("services");
    if (services) {
      const serviceEntries = services.split(",");
      const ids = [];
      const idxs = [];

      serviceEntries.forEach(entry => {
        const [id, index] = entry.split("-");
        ids.push(id);
        idxs.push(index);
      });

      setServiceIds(ids);
      setIndices(idxs);
    }
  }, [searchParams]);

  const [features, setFeatures] = useState({});
  const [servicesData, setServicesData] = useState([]);
  const isMobile = useMediaQuery("(max-width:768px)");



  useEffect(() => {
    const fetchData = async () => {
      if (serviceIds.length > 0) {
        setLoading(true); // Set loading to true before the fetch starts
        try {
          // Create a query to fetch only documents where the ID is in the serviceIds array
          const q = query(
            collection(partnerDB, "prData"),
            where('id', 'in', serviceIds.map(id => Number(id))) // Convert serviceIds to numbers if necessary
          );

          // Execute the query
          const querySnapshot = await getDocs(q);
          
          // Map over the documents to get their data
          const filteredPartners = querySnapshot.docs.map((doc) => doc.data());
          
          // Log the filtered data
          console.log("Filtered data:", filteredPartners);

          // Set the state with the filtered data
          setServicesData(filteredPartners);
        } catch (error) {
          console.error("Error fetching data: ", error);
          setError(error); // Set error if fetching fails
        } finally {
          setLoading(false); // Set loading to false after the fetch is complete
        }
      } else {
        setLoading(false); // Ensure loading is false if no IDs are present
      }
    };

    fetchData();
  }, [serviceIds]);

  if(loading){
    return <p>Loading...</p>;
  }



  function createData(name, calories, fat, carbs, protein) {
    return { 
        name, 
        calories, 
        fat,
        carbs,
        protein,
    };
  }

  const rows = [
    createData("Total no. of distribution network", `${servicesData[0]?.packages[indices[0]]?.features?.totalMediaOutlets}+`, `${servicesData[1]?.packages[indices[1]]?.features?.totalMediaOutlets}+`,`${servicesData[2]?.packages[indices[2]]?.features?.totalMediaOutlets}+`),

    createData("Medium of Distribution", `${servicesData[0]?.packages[indices[0]]?.features?.mediumOfDistribution.join(", ")
}`,`${servicesData[1]?.packages[indices[1]]
?.features?.mediumOfDistribution.join(", ")
}`, `${servicesData[2]?.packages[indices[2]]?.features?.mediumOfDistribution.join(", ")
}`),

    createData("Delivery Time", `${servicesData[0]?.packages[indices[0]]
?.features?.deliveryTime
}`,`${servicesData[1]?.
packages[indices[1]]
?.features?.deliveryTime
}`, `${servicesData[2]?.packages[indices[2]]?.features?.deliveryTime
}`),

    createData("Guaranteed Websites",`${servicesData[0]?.packages[indices[0]]
?.features?.guaranteedWebsites.join(`, `)
}`,`${servicesData[1]?.packages[indices[1]]
?.features?.guaranteedWebsites.join(`, `)
}`, `${servicesData[2]?.
packages[indices[2]]?.features?.guaranteedWebsites.join(`, `)
}`),

    createData("SEO", `${servicesData[0]?.packages[indices[0]]
?.features?.seo

}`,`${servicesData[1]?.packages[indices[1]]?.features?.seo

}`, `${servicesData[2]?.packages[indices[2]]?.features?.seo

}`),

    createData("Google News Tagging",  `${servicesData[0]?.packages[indices[0]]
?.features?.googleNewsTagging

}`,`${servicesData[1]?.packages[indices[1]]?.features?.googleNewsTagging

}`, `${servicesData[2]?.packages[indices[2]]?.features?.googleNewsTagging

}`),


    createData("Industry Specific",  `${servicesData[0]?.packages[indices[0]]?.features?.industrySpecific

}`,`${servicesData[1]?.packages[indices[1]]?.features?.industrySpecific

}`, `${servicesData[2]?.packages[indices[2]]?.features?.industrySpecific

}`),

    createData("Geo Tagging - Local Region Targetting", `${servicesData[0]?.packages[indices[0]]?.features?.geoTag

}`,`${servicesData[1]?.packages[indices[1]]?.features?.geoTag

}`, `${servicesData[2]?.packages[indices[2]]?.features?.geoTag
}`),
    createData("Press Release Writing",`${servicesData[0]?.packages[indices[0]]
?.features?.prWriting

}`,`${servicesData[1]?.packages[indices[1]]
?.features?.prWriting

}`, `${servicesData[2]?.packages[indices[2]]?.features?.prWriting

}`),

    createData("Images Included",  `${servicesData[0]?.packages[indices[0]]
?.features?.imageIncluded
}`,`${servicesData[1]?.packages[indices[1]]
?.features?.imageIncluded

}`, `${servicesData[2]?.packages[indices[2]]?.features?.imageIncluded

}`),
    createData("Words Limit", `${servicesData[0]?.packages[indices[0]]
?.features?.wordsLimit

}`,`${servicesData[1]?.packages[indices[1]]
?.features?.wordsLimit

}`, `${servicesData[2]?.packages[indices[2]]?.features?.wordsLimit

}`),

    createData("Proof Read of Press Release", `${servicesData[0]?.packages[indices[0]]
?.features?.proofRead

}`,`${servicesData[1]?.packages[indices[1]]
?.features?.proofRead

}`, `${servicesData[2]?.packages[indices[2]]?.features?.proofRead

}`),

    createData("Index on Search Engines", `${servicesData[0]?.packages[indices[0]]?.features.indexOnSearchEngines

}`,`${servicesData[1]?.packages[indices[1]]?.features.indexOnSearchEngines


}`, `${servicesData[2]?.packages[indices[2]]?.features.indexOnSearchEngines


}`),

    createData("Placement on News   Sites", `${servicesData[0]?.packages[0]?.features?.newsSitePlacement.join(`, `)

}`,`${servicesData[1]?.packages[indices[0]]
?.features?.newsSitePlacement.join(`, `)

}`, `${servicesData[2]?.packages[indices[2]]?.features?.newsSitePlacement.join(`, `)

}`),
    createData("Publish Guarantee", `${servicesData[0]?.packages[indices[0]]
?.features?.publishGuarantee

}`,`${servicesData[1]?.packages[indices[1]]
?.features?.publishGuarantee

}`, `${servicesData[2]?.packages[indices[2]]?.features?.publishGuarantee

}`),
  ];

  const handleClick = (isMobile) => {
    if (isMobile) {
      // Open dialer with the phone number
      window.location.href = "tel:+919354651433";
    } else {
      // Open WhatsApp with a predefined message
      window.location.href = "https://wa.me/919354651433?text=Hello%20I%20need%20help";
    }
  };

  return (
    <div className="card" style={{
      backgroundColor:"#f7fcfb",
      position: "relative"
    }}>
      <div className="card-body" style={{ backgroundColor: "#f7fcfb",
      position:"fixed",
     top:"0px",
     right:"0px",
     left:"0px",
     zIndex:"9999",
   
      boxShadow: "0 4px 6.4px 0 #c6cac9", }}>
        <div className="d-flex justify-content-between w-[90%] mx-auto">

        
          <div className="d-flex align-items-center">
            <a href="/">
            <img
              src={BizowalLogo}
              alt="BizowlLogo"
              width={isMobile ? "120px" : "200px"}
              onClick={()=>navigate("/")}
            />
            </a>
          </div>
          <div
    className="d-flex align-items-center cursor-pointer"
    onClick={() => handleClick(isMobile)}
  >
    <img
      src={PhonePic}
      alt="PhonePic"
      width={isMobile ? "30px" : "40px"}
    />
    <p  style={{
      color:"#407bff"
    }}>Need help?</p>
  </div>
          
        </div>
      </div>
      
      <div className=" absolute cursor-pointer" style={{
        margin:isMobile?"6rem":"7rem",
        left:isMobile?"-4rem":"-2rem"
      }} onClick={()=>navigate(-1)}>
          <img src={back} width="120px" />

      </div>
        <div style={{ height: "",width:isMobile?"100%":"80%", margin: "0px auto", border:"0px solid", borderRadius:"25px",
        padding:"10px" }}>
          <div style={{ display: "flex", flexDirection: "row", gap: isMobile?"":"5px",marginTop:isMobile?"8rem":"10rem",  }}>
            <div>
            { !isMobile && (<img alt="compare" src={svg} minWidth="300px" />)}
            </div>

            {
              servicesData.map((service,index)=>(
                <div
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: isMobile?"":"space-around",
                width: "300px",
                marginLeft: isMobile?"":"20px",
                borderRadius: "25px",
                boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.2)",
                padding: "15px",
              }}
            >
              <div
                style={{
                  border: "1px solid #70a6e6",
                  borderRadius: "10px",
                  width: "120px",
                  padding: "7px",
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <h3 style={{
                  fontSize:"1.5rem",
                  fontWeight:"520",
                  color:"#1c6ed0"
                }} >
                  {service?.name.substring(0, 3).toUpperCase()
                  }
                </h3>
              </div>
              <div>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-between",
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      marginTop: "10px",
                    }}
                  >
                    <p style={{ fontSize: "12px" }}>Total Price Included GST</p>
                    <h5>₹{service?.packages[indices[index]]?.features?.price}</h5>
                  </div>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      marginTop: "10px",
                    }}
                  >
                    <p style={{ fontSize: "12px" }}>Delivery Time</p>
                    <h5>{service?.packages[indices[index]]?.features?.deliveryTime}</h5>
                  </div>
                </div>
                <div style={{ marginTop: "10px" }}>
                  <Button fullWidth variant="contained">
                    Book Now
                  </Button>
                </div>
              </div>
            </div>

              ))
            }
          </div>
        </div>
        <div  style={{
      width: isMobile ? "95%" : "80%",
      margin: "10px auto 40px auto",
      marginTop: isMobile ? "30px" : "", // Adjust based on header size in mobile
      height: isMobile ? "calc(100vh - 370px)" : "", // Use available height on mobile
      overflowY: isMobile ? "auto" : "", // Ensure scrolling in mobile view
    }}>
          <TableContainer component={Paper} sx={{
            margin:"0 auto",
            borderRadius:"25px",
            padding:"20px 0px",
           
            maxHeight: isMobile ? "100%" : "", // Full height for mobile
            overflowY: isMobile?"auto":"", // Ensure vertical scrolling
          }}>
            <Table sx={{ minWidth: 650 }} aria-label="simple table">
              <TableBody>
                {rows.map((row) => (
                  <TableRow
                    key={row.name}
                    sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                  >
                    <TableCell component="th" scope="row" sx={{ width: isMobile?"15%":'27%' }}>
                      {row.name}
                    </TableCell>
                    <TableCell align="left" sx={{ width: isMobile?"15%":'20%'  }}>{row.calories}</TableCell>
                    <TableCell align="left" sx={{ width: isMobile?"15%":'20%' }}>{row.fat}</TableCell>
                    {
                      isMobile?"":(<TableCell align="left" sx={{ width: isMobile?"15%":'20%'}}>{row.carbs}</TableCell>)
                    }
                    
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </div>
      
    </div>
  );
};

export default CompareServices;
