import { useState } from 'react'
import './congratulation.css'
import NavBar from '../../Components/congratulation.jsx/navBar'
import Content from '../../Components/congratulation.jsx/content'


function CongratulationsPage() {
  return (
    <div className='app1'>
      <NavBar />
      <div className='section'>
          <Content/>
      </div>
    </div>
    
  )
}

export default CongratulationsPage
