import React from 'react'
import NavBar from '../../Components/Header/NavBar'
import SupportPageBody from '../../Components/supportPage/SupportPageBody'
import Footer from '../../Components/Footer/Footer'

const SupportPage = () => {
  return (
    <div className=''>
        <NavBar/>
        <SupportPageBody/>
        <Footer/>
    </div>
  )
}

export default SupportPage