export const guidanceDomain = [
  { domain: "Business Planning & Strategy" , image: "/Images/guidanceSection/guidance1.png"},
  { domain: "Marketing" , image: "/Images/guidanceSection/guidance2.png"},
  { domain: "Design" , image: "/Images/guidanceSection/guidance3.png"},
  { domain: "Advertisement" , image: "/Images/guidanceSection/guidance4.png"},
  { domain: "Market Research" , image: "/Images/guidanceSection/guidance5.png"},
  { domain: "Web Development" , image: "/Images/guidanceSection/guidance6.png"},
  { domain: "Product" , image: "/Images/guidanceSection/guidance7.png"},
  { domain: "Public Relations" , image: "/Images/guidanceSection/guidance8.png"},
];
