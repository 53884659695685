import React from "react";
import ImageContainer1 from "./ImageContainer1";
import ImageContainer2 from "./ImageContainer2";
import ImageContainer3 from "./ImageContainer3";
import ImageContainer4 from "./ImageContainer4";
import { useMediaQuery } from "@mui/material";
import { NavLink, useNavigate } from "react-router-dom";

const GuidanceHeroSection = () => {
  const navigate = useNavigate();
  const isMobile = useMediaQuery("(max-width:724px)");
  const isTab = useMediaQuery("(max-width:1024px)");
  const isLaptop = useMediaQuery("(max-width:1152px)");
  const isSmallMobile=useMediaQuery('(max-width:400px)');
  const islg = useMediaQuery("(max-width:1339px)");
  return (
    <div
      className="flex relative justify-between"
      style={{ 
      fontFamily: "'Cabin'",
      flexDirection:isLaptop?"column":"row",
      alignItems:(isMobile&&"center")||(isLaptop&&"start")||"center",
      paddingLeft:isMobile?"0px":"105px"
    }}
    >
      {/* Left Section */}
      <div
        className="bookCallSection flex flex-col"
        style={{ alignItems: "start", paddingTop: isMobile && "50px", paddingLeft:isSmallMobile?"5px":"0px", width: isMobile && "330px"}}
      >
        <h2
          className="text-[#263238]"
          style={{
            fontSize: isMobile
            ? "25px"
            : "35px",
              fontWeight: "550"
          }}
        >
          Get Access to
          <span className="text-[#1C6ED0]"> Extra Brains</span>
        </h2>
        <div className="flex" style={{ marginTop: "8px" }}>
          <h2
            className="text-[#263238]"
            style={{
              fontSize: isMobile
                ? "25px"
                : "35px",
                fontWeight: "550"
            }}
          >
            For Your <span className="text-[#1C6ED0]">Startup</span>
          </h2>
          <div
            className="relative"
            style={{
              backgroundColor: "#1C6ED0",
              width: isMobile
                ? "20px"
                : isTab
                ? "30px"
                : isLaptop
                ? "30px"
                : "40px",
              height: isMobile
                ? "20px"
                : isTab
                ? "25px"
                : isLaptop
                ? "30px"
                : "40px",
              borderRadius: "50%",
              left: isMobile? "12px": "20px",
            }}
          ></div>
          <div
            style={{
              backgroundColor: "black",
              width: isMobile
                ? "20px"
                : isTab
                ? "30px"
                : isLaptop
                ? "30px"
                : "40px",
              height: isMobile
                ? "20px"
                : isTab
                ? "30px"
                : isLaptop
                ? "30px"
                : "40px",
              borderRadius: "50%",
            }}
          ></div>
        </div>
        <p
          className="mt-[11px] text-left text-black"
          style={{
            fontSize: isMobile
              ? "13px"
              : isTab
              ? "14px"
              : "16px",
              fontWeight: "300",
              fontFamily: "'Inter', sans-serif",
            marginTop: "24px",
            opacity:"80%"
          }}
        >
          Speak with experts to get guidance for
        </p>
        <p
          className="mb-6 text-left text-black"
          style={{
            fontSize: isMobile
              ? "13px"
              : isTab
              ? "14px"
              : "16px",
              fontWeight: "300",
              fontFamily: "'Inter', sans-serif",
            marginBottom: "24px",
            opacity:"80%"
          }}
        >
          your business. Get support for any stage of business.
        </p>
        <button
          className="flex bg-[#1C6ED0] text-white font-semibold items-center"
          style={{fontSize:isMobile?"15px":"22px",height:isMobile?"40px":"auto" }}
        
        >
        <NavLink to="https://calendly.com/bizowl " className="no-underline text-white hover:text-current active:text-current visited:text-current">Book Call</NavLink>
       
        </button>
        <h2
          className="text-[20px] font-semibold mt-12 relative top-2"
          style={{ fontSize:isMobile?"15px":"20px", fontWeight: "bold", marginTop: "48px" }}
        >
          Trusted By
        </h2>
        <p className="text-[#1C6ED0] text-[50px]" style={{ fontSize:isMobile?"30px":"50px" }}>
          5K+
        </p>
        <p
          className="font-semibold text-[15px] relative"
          style={{ fontSize:isMobile?"10px":"15px",bottom:isMobile?"10px":"20px", fontWeight: "600" }}
        >
          People
        </p>
      </div>
      {/* Right section */}
      <div className="grid grid-cols-2 grid-rows-2" style={{height:isMobile?"420px":"600px",width:isMobile?"420px":"600px"}}>
        <ImageContainer1 />
        <ImageContainer3 />
        <ImageContainer4/>
        <ImageContainer2/>
      </div>
    </div>
  );
};

export default GuidanceHeroSection;
