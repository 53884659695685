import React,{useState} from "react";
import { useMediaQuery } from "@mui/material";
import { Modal } from "react-bootstrap";
import closeIcon from "../assets/grommet_icons_next.png"

const Features = ({ featuresOverview,serviceName }) => {
  const isMobile = useMediaQuery("(max-width:768px)");
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  // const benefitsData= [
  return (
    <div className="flex flex-col mt-14 !items-start w-[100%] md:w-[68%] ">
      <h3 className="font-cabin font-[500] text-xl md:text-3xl">
        Features of {serviceName} Service{" "}
      </h3>
      <div className="w-[100%] h-[1px] bg-[#263238] opacity-20 mt-4 mb-4"></div>

      <div className="grid md:!grid-cols-2 md:grid-rows-5 !gap-x-20 !gap-y-7 w-[100%] text-left font-cabin">
        {featuresOverview?.slice(0, isMobile ? 5 : featuresOverview.length)
          .map((data, index) => (
            <div key={index} className="flex gap-4">
              <img
                src={data.icon}
                alt={`Icon ${index}`}
                className="h-[30px] w-[30px] md:w-[45px] md:h-[45px] "
              />
              <p className="font-medium text-[15px] md:text-xl">{data.title}</p>
            </div>
          ))}
      </div>
      {/* Popup window*/}
      <Modal show={show} onHide={handleClose} style={{border:"1px solid black",overflowY:"hidden"}}>
              <Modal.Header style={{height:"50px",borderBottom:"1px solid gray"}}>
                <div className="cursor-pointer" onClick={()=>handleClose()}><img src={closeIcon} alt="close icon" /></div>
              </Modal.Header>
                <Modal.Body className="flex flex-col" style={{height:"600px",alignItems:"flex-start"}}>
                <p className="font-[600] mb-[10px]">Features of {serviceName} service</p>
                <div className="flex flex-col items-start h-max w-max px-[8px]" style={{alignItems:"flex-start",alignSelf:"center",width:"100%"}}>
                {featuresOverview?.map((data,index)=>{
                    return (<div key={index} className="flex gap-[14px] h-[50px] w-full" style={{borderBottom:"1px solid gray",alignItems:"center"}}>
                    <img
                      src={data.icon}
                      alt={`Icon ${index}`}
                      className="h-[30px] w-[30px] ml-[15px] md:w-[45px] md:h-[45px] "
                    />
                    <p className="font-medium text-[15px] md:text-xl">{data.title}</p>
                  </div>)
                  })}
                </div>
                  
                </Modal.Body>
      </Modal>
      <div className="mx-auto mt-4 md:hidden">
        <button className="!bg-transparent border-[4px] border-[#1C6ED0] text-[15px] font-cabin rounded-[10px] px-4" onClick={()=>handleShow()}>
          View all Features
        </button>
      </div>
    </div>
  );
};

export default Features;
