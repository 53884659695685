import React from "react";
import Clock_1 from './assets/Clock_1.png'
import { useMediaQuery } from "@mui/material";
import  usePdfStore  from "./store";

function Viewer_HeroSection({ id,titleColored, titleBlack,description,}){
    const currPdf = usePdfStore((state)=>state.currPdf)
    let isTab=useMediaQuery('(max-width:1100px)');
    let isMobile=useMediaQuery('(max-width:624px)')

    const currDate = new Date();
    const options = { year: 'numeric', month: 'long', day: 'numeric' };
    const formattedDate = currDate.toLocaleDateString(undefined, options);

    // Function to handle the download
  const handleDownload = () => {
    const link = document.createElement('a');
    link.href = currPdf.path; // Path to the PDF file
    link.download = currPdf.fileName; // The filename to save the file as
    document.body.appendChild(link);

    link.click();

    document.body.removeChild(link); // Clean up the link element
  };
    return(
        <div className="flex flex-col h-max w-full pl-10 pt-8 pr-8 pb-8 text-left" style={{background:"linear-gradient(90deg, rgba(64, 123, 255, 0.25) 0%, rgba(178, 202, 255, 0.25) 55%, #FFFFFF 61%)",alignItems:"start"}}>
            {/* HeroSection Title*/}
            <div className="flex h-auto w-auto mb-4 font-semibold" style={{fontSize:isMobile&&"25px"||isTab&&"30px"||"40px",lineHeight:isMobile&&"30px"||isTab&&"35px"||"50px",color:"rgba(38, 50, 56, 1)"}}><p><span style={{color:"rgba(28, 110, 208, 1)"}}>{titleColored}</span> {titleBlack}</p></div>
            {/* Date and time */}
            <div className="flex items-center h-auto w-auto text-md mb-2 font-medium" style={{fontSize:isMobile&&"12px"||isTab&&"15px"||"25px",lineHeight:isMobile&&"15px"||isTab&&"20px"||"30px",color:"rgba(38, 50, 56, 0.75)"}}>
                <img src={Clock_1} className="h-8 w-8 mr-2" style={{height:isMobile&&"16px"||isTab&&"20px"||"32px",width:isMobile&&"16px"||isTab&&"20px"||"32px"}} />
                <p className="text-[14px] md:text-[18px] " style={{
                    fontFamily:"'inter',sans-serif"
                }}>Apr 25, 2024</p>
            </div>
            {/* HeroSection description */}
            <div className="flex h-auto w-auto mb-4 font-medium" style={{fontSize:isMobile&&"12px"||isTab&&"15px"||"25px",lineHeight:isMobile&&"15px"||isTab&&"20px"||"30px",color:"rgba(38, 50, 56, 0.75)",textAlign:"left"}}>
                <p style={{marginRight:"40px"}}>{description}</p>
            </div>
            {/* Download Button */}
            <div onClick={handleDownload} className="flex items-center justify-center text-base bg-blue-700 text-white hover:scale-105 cursor-pointer sticky top-0 z-50" style={{fontFamily:"'inter',sans-serif" ,height:"auto",width:isMobile&&"80px"||isTab&&"120px"||"160px",padding:isMobile&&"8px"||isTab&&"12px"||"16px",borderRadius:isMobile&&"8px"||isTab&&"12px"||"16px"}}>
                <p className="h-auto w-auto text" style={{fontSize:isMobile&&"12px"||isTab&&"15px"||"24px"}}>Download</p>
            </div>
        </div>
    )
}

export default Viewer_HeroSection;