import { useMediaQuery } from "@mui/material";
import React from "react";

const Stepper = ({ currentStep }) => {
  const isMobile = useMediaQuery("(max-width: 768px)");
  return (
    <div className="flex justify-center items-center p-5 md:p-10">
      <div className="flex flex-row items-center justify-between w-full">
        <div className="flex flex-row justify-start gap-2 md:gap-5 w-1/3">
          <div
            className={`w-6 h-6 md:w-8 md:h-8 flex justify-center items-center rounded-full ${
              isMobile ? "ring-0" : (currentStep > 1 ? "ring-2 ring-green-500" : "ring-2 ring-blue-500")
            }`}
          >
            <div
              className={`w-4 h-4 md:w-6 md:h-6 flex justify-center items-center rounded-full ${
                currentStep > 1
                  ? "bg-green-500 text-white"
                  : "bg-blue-500 text-white-500"
              }`}
            ></div>
          </div>
          <div className="flex flex-col">
            <div style={{width: isMobile && "max-content"}} className="flex flex-row gap-1 md:gap-2">
              <div>
                <p className="text-gray-500 text-xs md:text-sm">Step 1</p>
              </div>
              <div>
                <p className={`${currentStep > 1 ? 'bg-gray-200 text-gray-500' : 'bg-blue-200 text-blue-500'} p-1 text-xs md:text-sm`}>{currentStep > 1 ? "Completed" : "In Progress"}</p>
              </div>
            </div>
            <div>
              <p style={{width: isMobile && "max-content"}} className="text-gray-500 text-xs md:text-sm">Idea Details</p>
            </div>
          </div>
        </div>
        <div style={{width: isMobile && "40px", marginLeft: isMobile && "20px"}} className="h-1 md:h-2 bg-gray-300 w-1/4"></div>
        <div className="flex flex-row justify-center gap-2 md:gap-5 w-1/3">
          <div
            className={`w-6 h-6 md:w-8 md:h-8 flex justify-center items-center rounded-full ${
              isMobile ? "ring-0" : (currentStep <= 1 ? "ring-0" : "ring-2 ring-blue-500")
            }`}
          >
            <div
              className={`w-4 h-4 md:w-6 md:h-6 flex justify-center items-center rounded-full ${
                currentStep >= 2
                  ? "bg-blue-500 text-white"
                  : "bg-gray-300 text-gray-500"
              }`}
            ></div>
          </div>
          <div>
            <div style={{width: isMobile && "max-content"}} className="flex flex-row gap-1 md:gap-2">
              <div>
                <p className="text-gray-500 text-xs md:text-sm">Step 2</p>
              </div>
              <div>
                <p className={`${currentStep === 1 ? 'text-gray-500 bg-gray-200' : 'text-blue-500 bg-blue-200'} p-1 text-xs md:text-sm`}>{currentStep === 1 ? "" : "In Progress"}</p>
              </div>
            </div>
            <div>
              <p style={{width: isMobile && "max-content"}} className="text-gray-500 text-xs md:text-sm">Personal Details</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Stepper;
