import React from "react";
import { NavLink } from "react-router-dom";
import GuidanceHeroSection from "../../Components/guidanceSecttion/heroSection/GuidanceHeroSection";
import GuidanceDomainSection from "../../Components/guidanceSecttion/guidanceDomainSection/GuidanceDomainSection";
import AdviceSection from "../../Components/guidanceSecttion/adviceSection/AdviceSection";
import HowItWork from "../../Components/guidanceSecttion/howItWorks/HowItWork";
import { useMediaQuery } from "@mui/material";
import { Helmet } from "react-helmet";
import NavBar from "../../Components/Header/NavBar";
import Footer from "../../Components/Footer/Footer";
import ApplyBodyStylesOnLoad from "../../Components/ApplyBodyStylesOnLoad ";

const GuidanceSection = () => {
  const isMobile = useMediaQuery("(max-width:724px)");

  return (
    <div>
 <ApplyBodyStylesOnLoad />
    <Helmet>
    <title>Get Access to Extra Brains for Your Startup | Expert Business Support</title>
<meta charset="UTF-8"/>
<meta name="viewport" content="width=device-width, initial-scale=1.0"/>
<meta name="description" content="Get expert business support to solve your business challenges. Access 1:1 video calls with our business experts for personalized guidance and solutions tailored to your startup."/>
<meta name="keywords" content="Business Support, Business Guidance, Startup Help, Business Challenges, Expert Business Advice, 1:1 Video Calls, Personalized Business Solutions"/>
<meta name="robots" content="index, follow"/>
<meta name="author" content="Bizowl"/>
<meta http-equiv="Content-Language" content="en"/>
<link rel="canonical" href="https://bizzowl.com/business-support" />
<meta property="og:title" content="Get Access to Extra Brains for Your Startup | Expert Business Support"/>
<meta property="og:description" content="Solve your business challenges with expert guidance. Book a 1:1 video call with our business experts to get personalized solutions for your startup."/>
<meta property="og:image" content="https://yourwebsite.com/path-to-image.jpg"/>
<meta property="og:url" content="https://bizzowl.com/business-support"/>
<meta name="twitter:card" content="summary_large_image"/>
<meta name="twitter:title" content="Get Access to Extra Brains for Your Startup | Expert Business Support"/>
<meta name="twitter:description" content="Need business support? Get personalized guidance and solutions for your startup challenges through 1:1 video calls with our business experts."/>
<meta name="twitter:image" content="https://yourwebsite.com/path-to-image.jpg"/>

<script type="application/ld+json">
          {`{
           
            "@context": "https://schema.org",
  "@type": "WebPage",
  "name": "Get Access to Extra Brains for Your Startup | Expert Business Support",
  "description": "Get expert business support to solve your business challenges. Access 1:1 video calls with our business experts for personalized guidance and solutions tailored to your startup.",
  "url": "https://bizzowl.com/business-support",
  "mainEntity": {
    "@type": "Service",
    "serviceType": "Business Support and Guidance",
    "provider": {
      "@type": "Organization",
      "name": "Bizowl",
      "url": "https://bizzowl.com"
    },
    "areaServed": {
      "@type": "Place",
      "name": "Global"
    },
    "offers": {
      "@type": "Offer",
      "url": "https://bizzowl.com/business-support",
      "priceCurrency": "INR",
      "eligibleRegion": {
        "@type": "Place",
        "name": "Global"
      },
      "availability": "https://schema.org/InStock",
      "validFrom": "2024-09-02"
    }
  },
  "potentialAction": {
    "@type": "Action",
    "name": "Book a 1:1 Video Call",
    "target": "https://calendly.com/bizowl"
  },
  "author": {
    "@type": "Organization",
    "name": "Bizowl",
    "url": "https://bizzowl.com"
  },
  "publisher": {
    "@type": "Organization",
    "name": "Bizowl",
    "logo": {
      "@type": "ImageObject",
      "url": "https://yourwebsite.com/path-to-logo.jpg"
    }
  }


         }`
          }
</script>
    </Helmet>

    <NavBar />
    <div style={{paddingLeft: isMobile ?"0px": "28px",paddingRight:isMobile?"0px":"25px", paddingTop:isMobile?"40px":"20px", fontFamily: "'Cabin', sans-serif"}}>
        {/* <NavLink to="/">
          <img src="/Images/logo.png" alt="logo" className="w-[200px] py-2"/>
        </NavLink> */}
        <GuidanceHeroSection />
        <GuidanceDomainSection />
        <AdviceSection />
        <HowItWork />
      </div>
      
        <Footer />
      
    </div>
  );
};

export default GuidanceSection;
