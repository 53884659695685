import React from "react";
import PayemtCardMobile from "../../Components/SelfServicePage/PayemtCardMobile";
import Service from "../../Components/SelfServicePage/Service";
import Features from "../../Components/SelfServicePage/Features";
import HowItWorks from "../../Components/SelfServicePage/HowItWorks";
import Herosection from "../../Components/SelfServicePage/Herosection";
import WhoShouldChooseUs from "../../Components/SelfServicePage/WhoShouldChooseUs";
import SelfServiceFAQ from "../../Components/SelfServicePage/SelfServiceFAQ";
import Ideaavalidation from "../../Components/SelfServicePage/Ideaavalidation";
import PaymentCard from "../../Components/SelfServicePage/PaymentCard";

const SelfServicePage = () => {
  return (
    <div className="!bg-[#f5f5f5] mb-[105px] pb-[50px] md:mb-0">
      <Herosection />
      <div className="flex !items-start md:!px-[11.5rem] !gap-4 justify-between">
        <div className="md:w-8/12">
          <Ideaavalidation />
          <Service />
          <Features />
          <HowItWorks />
          <WhoShouldChooseUs />
          <SelfServiceFAQ />
          <PayemtCardMobile />
        </div> 
        <div className="hidden md:block w-3/12 sticky top-0">
          <PaymentCard/>
        </div>
      </div>
    </div>
  );
};

export default SelfServicePage;
