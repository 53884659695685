import NavBar from "./components/NavBar";
import leftArrow from "./assets/arrow-left.svg";
import PaymentSummaryCard from "./components/PaymentCard";
import IdeaForm from "./components/IdeaForm";
import Stepper from "./components/utils/Stepper";
import { useState } from "react";
import useMediaQuery from "@mui/material/useMediaQuery";
import MobilePaymentSummaryTray from "./components/PaymentSummaryTray";
import Footer from "../Footer/Footer";
import { useNavigate, useParams } from "react-router-dom";
import { serviceFormQuestions } from "../../data/servicePage/serviceFormQuestions";

const BookingSelf = () => {
  const { serviceName } = useParams();
  const questionData = serviceFormQuestions;
  const filterQuestion = questionData.filter((data)=>data.serviceName === serviceName);
  
  
  const totalPayment = 5211;
  const gst = totalPayment * 0.18;
  const [currentStep, setCurrentStep] = useState(1);
  const [showBackToQuotes, setShowBackToQuotes] = useState(true);
  const [showNextStep, setShowNextStep] = useState(false);
  const isMobile = useMediaQuery("(max-width: 768px)");

  const navigate = useNavigate();

  const handleContinue = () => {
    setCurrentStep(currentStep + 1);
    setShowBackToQuotes(false);
  };

  const handleBack = () => {
    if (currentStep === 2) {
      setCurrentStep(1);
      setShowBackToQuotes(true);
    } else if (currentStep === 1) {
      navigate(-1);
    }
  };

  return (
    <div className="overflow-x-hidden">
      <NavBar />
      <div className="max-w-[1250px] mx-auto px-2 py-4">
        <div
          style={{ paddingTop: isMobile && "50px" }}
          className="flex items-center"
        >
          {showBackToQuotes ? (
            <div className="mr-2 cursor-pointer bg-white" onClick={handleBack}>
              <img src={leftArrow} alt="leftArrow" width="40px" />
            </div>
          ) : (
            <div className="mr-2 cursor-pointer bg-white" onClick={handleBack}>
              <img src={leftArrow} alt="leftArrow" width="40px" />
            </div>
          )}
          <div>
            {showBackToQuotes ? (
              <p className="text-gray-600 cursor-pointer" onClick={handleBack}>
                Back to Quotes
              </p>
            ) : (
              <p className="text-gray-600 cursor-pointer" onClick={handleBack}>
                Back to Step - 1
              </p>
            )}
          </div>
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "flex-start",
          }}
          className="flex flex-row justify-between items-start gap-2 mt-4"
        >
          <div style={{ width: isMobile && "100%" }} className="w-[850px]">
            <div
              style={{
                backgroundColor: "#D7E9FD",
                borderTopRightRadius: "25px",
                borderTopLeftRadius: "25px",
              }}
            >
              <Stepper currentStep={currentStep} />
            </div>
            <div
              style={{
                backgroundColor: "#fff",
                borderTopRightRadius: "25px",
                borderTopLeftRadius: "25px",
                marginTop: "-20px",
              }}
            >
              <IdeaForm
                showNextStep={showNextStep}
                setShowNextStep={setShowNextStep}
                currentStep={currentStep}
                handleContinue={handleContinue}
                handleBack={handleBack}
                filterQuestion={filterQuestion}
              />
            </div>
          </div>
          {isMobile ? (
            <MobilePaymentSummaryTray totalPayment={totalPayment} gst={gst} />
          ) : (
            <div className="w-[350px]">
              <PaymentSummaryCard totalPayment={totalPayment} gst={gst} />
            </div>
          )}
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default BookingSelf;
