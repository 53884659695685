import React from 'react'
import { useNavigate } from 'react-router-dom'

const Bottom = ({serviceObj}) => {
const navigate =    useNavigate()

const {payemnetSummary,serviceName} = serviceObj;

  return (
    <div className='fixed left-0 right-0  bottom-0 bg-[white] z-[100] !w-[100%] p-2 flex flex-col justify-center h-[100px] border-t-[2px] border-t-[#263238] border-opacity-[45%] md:!hidden '>
    
        <div className='w-[90%] flex items-center justify-between pt-3 '>

        <div className='flex flex-col'>
        <p className='text-[22px] font-bold'>₹{payemnetSummary.Payable} /-</p>
        <p className='text-[15px] font-medium'>GST Included</p>
        </div>
        <div>
            <button className='bg-[#1C6ED0] h-[40px] px-3 py-1 text-white' onClick={()=>navigate(`/booking-self/${serviceName}`)}>Proceed</button>
        </div>


        </div>
    </div>
  )
}

export default Bottom