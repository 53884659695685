import './ToolsHerosection.css';
import AI_Icon from './assets/ai.png'
import Tick from './assets/Tick.png'
import Name_generator_icon from './assets/icon-park-solid_edit-name.png'
import Content_generator_icon from './assets/mingcute_content-ai-line.png'
import Main_image from './assets/Tools-Hero-Png.png'
import Plan_Product_icon from './assets/gridicons_product.png'
import Brand_generation_icon from './assets/cib_brand-ai.png'

function ToolsHerosection(){
return(
    <div className='Hero-Section'>
        <div className='Left-section'>
            <div className='Tools-Big-Text'><div className='Tools-Text1'><p>AI Tools For Every Stage of <span>Your</span></p>
            </div>
            <div className="Tools-Text2"><img src={AI_Icon} alt="AI icon" /><p><span>Business </span>Journey</p></div>
            </div>
            <div className='Tools-Small-Text'><p>From idea to global growth, our AI tools support founders at every stage, 
            making your business journey smoother and more successful.</p></div> 

            <div className='Features-Div'>
                <div className="Feature"><img src={Tick}/><p>No Account Required</p></div>
                <div className="Feature"><img src={Tick}/><p>Free AI Tools</p></div>
            </div> 

            <div className='Options-Div'> 
                <div className="Option"><img src={Name_generator_icon}/><p>Generate Name</p></div>
                <div className="Option"><img src={Content_generator_icon}/><p>Generate Content</p></div>
                <div className="Option"><img src={Plan_Product_icon}/><p>Plan Product</p></div>
                <div className="Option"><img src={Brand_generation_icon}/><p>Brand Generation</p></div>
            </div>
        </div>
        <div className='Right-section'><img src={Main_image}/></div>

    </div>
)

}

export default ToolsHerosection;