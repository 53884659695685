import axios from "axios";
import logo from "../../assets/bizowl_logo.png";
import useStore from "../store";
import { addDoc, collection } from "firebase/firestore";
import { adminDB } from "../../../../config/firebase";

const createRazorpayOrder = async (amount) => {
  let data = JSON.stringify({
    amount: amount * 100,
    currency: "INR",
  });

  try {
    // const response = await axios.post("http://localhost:5000/Orders", data, {
    const response = await axios.post(
      "https://bizowl-razorpay-backend.vercel.app/Orders",
      data,
      {
        headers: {
          "Content-Type": "application/json",
        },
      }
    );
    return response.data;
  } catch (error) {
    console.log(error);
  }
};

const handlePayment = async (
  e,
  ideaDetails,
  contactDetails
) => {
  e.preventDefault();
  const orderData = await createRazorpayOrder(6149); // Create Razorpay order and get response data
  // setResData(orderData);

  // Check if orderData is valid before proceeding
  if (!orderData) {
    console.error("Failed to create Razorpay order. Aborting payment process.");
    return; // Exit the function if orderData is null
  }

  

  var options = {
    // key: "rzp_test_tWEJGdo8KsLT7E", // Enter the Key ID generated from the Dashboard
    // key_secret: "ZoooQA2XWr7LRSbC2uKPPMUp",
    key: "rzp_live_BWaG2IsaGv3uk9",
    key_secret: "LPvFdnyAHQ0oH5X8DrrWYMkg",
    amount: orderData["amount"], // Amount is in currency subunits. Default currency is INR. Hence, 50000 refers to 50000 paise
    currency: orderData["currency"],
    name: "Bizowl", //your business name
    description: "Test Transaction",
    image: { logo },
    order_id: orderData["order_id"],
    handler: async function (response) {
      await submitToFirestore(
        ideaDetails,
        contactDetails,
        response.razorpay_payment_id,
        "success"
      );
      // alert(response.razorpay_payment_id);
      window.location.href = "/paymentSuccess";
    },
    modal: {
      ondismiss: async function () {
        await submitToFirestore(ideaDetails, contactDetails, null, "failure");
        // Redirect to payment failure page
        window.location.href = "/paymentFailure";
      },
    },
    prefill: {
      //We recommend using the prefill parameter to auto-fill customer's contact information especially their phone number
      name: "Abc", //your customer's name
      email: "abc@example.com",
      contact: "9000090000", //Provide the customer's phone number for better conversion rates
    },
    notes: {
      address: "Xyz",
    },
    theme: {
      color: "#3399cc",
    },
  };

  const rzp1 = new window.Razorpay(options);
  rzp1.open();
  e.preventDefault();

  const submitToFirestore = async (
    ideaDetails,
    contactDetails,
    paymentId,
    status
  ) => {
    try {
      await addDoc(collection(adminDB, "idea-validation-payments"), {
        ideaDetails,
        contactDetails,
        paymentId,
        status,
        createdAt: new Date(),
      });
      console.log("Document written with ID: ", paymentId);
    } catch (e) {
      console.error("Error adding document: ", e);
    }
  };
};

export { handlePayment, createRazorpayOrder };
