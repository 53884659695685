export const TargetCustomerAnalysisObj = {
  serviceName: "Customer & Target Market Analysis Service",
  icon: "/Images/homepage/AfterAssets/target-audience (1) 1.png",
  heroSection: {
    image: "/Images/selfService/targetcustomer/Customer-Segmentation 2.png",
    message: "Target Audience Identification",
    heading: "Avoid Failure: Validate Your ",
    headingPortion: "Idea Now",
    highlightedText: "42% of startups fail due to no market need.",
    content:
      "The biggest mistake entrepreneurs make is skipping idea validation. This crucial step ensures your idea solves real problems and meets market demands before you invest valuable time and money.",
  },
  aboutService: {
    content1:
      "Our Customer & Target Market Analysis service helps businesses of all sizes understand and segment their customers. We focus on identifying the right target audience and creating tailored marketing strategies based on their needs. With expert analysis and segmentation, we ensure your business reaches its most profitable customersce.",
    content2: "",
  },
  features: [
    {
      icon: "/Images/selfService/targetcustomer/feature1.png",

      title: "Target Audience Identification",
      description:
        "We help you identify and define the most relevant target market segments for your business, ensuring you reach the right customers with your product or service.",
    },
    {
      icon: "/Images/selfService/targetcustomer/feature2.png",
      title: "Customer Segmentation Analysis",
      description:
        "Understand the different segments of your target market with detailed customer segmentation analysis, allowing you to tailor your marketing efforts effectively.",
    },
    {
      icon: "/Images/selfService/targetcustomer/feature3.png",
      title: "Target Consumer Behavior Insights",
      description:
        "Gain actionable insights into how your target customer segment behaves, helping you create personalized strategies for higher engagement and conversion.",
    },
    {
      icon: "/Images/selfService/targetcustomer/feature4.png",
      title: "Target Market Research",
      description:
        "Our in-depth target market analysis helps you understand who your customers are, where they are, and what they want, enabling you to refine your products and services.",
    },
    {
      icon: "/Images/selfService/targetcustomer/feature5.png",
      title: "Audience Segmentation Reports",
      description:
        "Receive detailed reports that highlight the key customer segments, ensuring your marketing and sales efforts are always directed towards the right people.",
    },
    {
      icon: "/Images/selfService/targetcustomer/feature6.png",
      title: "Customer Persona Creation",
      description:
        "We help you create detailed customer personas, representing the ideal buyers in your target consumer segment, allowing you to better cater to their needs.",
    },
  ],
  payemnetSummary: {
    totalPayment: "5211",
    gst: "937",
    Payable: "6149",
  },
  benefits: {
    title: "Benefits of the Service",
    data: [
      {
        title: "Targeted Marketing",
        description:
          "Identify and segment your target audience for effective marketing",
        sno: "01",
      },
      {
        title: "Higher Conversion Rates",
        description:
          "Tailor messaging with customer segmentation to boost conversions",
        sno: "02",
      },
      {
        title: "Better ROI",
        description:
          "Focus on key market segments for efficient resource allocation.",
        sno: "03",
      },
      {
        title: "Competitive Advantage",
        description:
          "Know your ideal customers and stand out in the marketplace.",
        sno: "04",
      },
      {
        title: "Customer-Centric Approach",
        description:
          "Understand customer needs to align your products and services.",
        sno: "05",
      },
      {
        title: "Improved Customer Retention",
        description:
          "Tailor service and offerings to meet your most valuable customers.",
        sno: "06",
      },
    ],
  },
  featuresOverview: [
    {
      title: "Customer Segmentation Analysis",
      icon: "/Images/selfService/targetcustomer/overview1.png",
    },
    {
      title: "Target Market Research & Insights",
      icon: "/Images/selfService/targetcustomer/overview2.png",
    },
    {
      title: "Target Audience Identification",
      icon: "/Images/selfService/targetcustomer/overview3.png",
    },
    {
      title: "Consumer Behavior Analysis",
      icon: "/Images/selfService/targetcustomer/overview4.png",
    },
    {
      title: "Customer Persona Development",
      icon: "/Images/selfService/targetcustomer/overview5.png",
    },
    {
      title: "Target Market Segmentation Reports",
      icon: "/Images/selfService/targetcustomer/overview6.png",
    },
    {
      title: "Tailored Marketing Strategy Guidance",
      icon: "/Images/selfService/targetcustomer/overview7.png",
    },
    {
      title: "Data-Driven Insights for Decision-Making",
      icon: "/Images/selfService/targetcustomer/overview8.png",
    },
    {
      title: "Competitor Target Audience Comparison",
      icon: "/Images/selfService/targetcustomer/overview9.png",
    },
    {
      title: "Ongoing Customer Segment Refinement",
      icon: "/Images/selfService/targetcustomer/overview10.png",
    },
  ],

  howItWorks: [
    {
      bg: "/Images/selfService/IdeaValidationImages/IdeaValWorks/fg1.png",
      fg: "/Images/selfService/IdeaValidationImages/IdeaValWorks/bg1.png",
      step: "Initial Consultation",
      description:
        "We discuss your business, products, and current market position.",
    },
    {
      bg: "/Images/selfService/IdeaValidationImages/IdeaValWorks/fg2.png",
      fg: "/Images/selfService/targetcustomer/Customer-Segmentation 1.png",
      step: "Target Audience Research",
      description:
        "We perform a detailed target market analysis to identify key customer segments.",
    },
    {
      bg: "/Images/selfService/IdeaValidationImages/IdeaValWorks/fg3.png",
      fg: "/Images/selfService/targetcustomer/Strategy-Review 1.png",
      step: "Segmentation Strategy",
      description:
        "We help you develop a strategy for targeting the most profitable segments of your target market.",
    },
    {
      bg: "/Images/selfService/IdeaValidationImages/IdeaValWorks/fg4.png",
      fg: "/Images/selfService/IdeaValidationImages/IdeaValWorks/bg4.png",
      step: "Implementation & Review",
      description:
        "We guide you through executing marketing strategies tailored to each target customer segment and provide ongoing support.",
    },
  ],
  targetAudience: [
    {
      icon: "/Images/servicePage/targeticon1.png",
      iconMobile: "/Images/servicePage/targeticonMobile1.png",
      title: "Aspiring Entrepreneurs & Startups",
      content1:
        "If you're just starting out, our service helps you understand who your ideal customers are and how to reach them.",
      content2: "",
    },
    {
      icon: "/Images/servicePage/targeticon2.png",
      iconMobile: "/Images/servicePage/targeticonMobile2.png",
      title: "Product Managers",
      content1:
        "Gain a clear understanding of the different target consumer segments for your product to maximize reach and engagement.",
      content2: "",
    },
    {
      icon: "/Images/servicePage/targeticon3.png",
      iconMobile: "/Images/servicePage/targeticonMobile3.png",
      title: "Small Business Owners",
      content1:
        "Our target customer analysis allows you to optimize your marketing efforts and focus on the customers most likely to convert.",
      content2: "",
    },
    {
      icon: "/Images/servicePage/targeticon4.png",
      iconMobile: "/Images/selfservicePage/targetcustomer/Group 1000004760.png",
      title: "Established Companies Looking to Grow",
      content1:
        "If you're expanding into new markets or launching new products, our detailed target market segmentation ensures you're focusing on the right customers.",
      content2: "",
    },
  ],

  faq: [
    {
      que: "What is target market segmentation?",
      ans: "Target market segmentation is the process of dividing a market into distinct groups of customers who have similar needs or behaviors. This allows businesses to tailor their products and marketing strategies to specific segments.",
    },
    {
      que: "Why is customer segmentation important?",
      ans: "Customer segmentation helps businesses understand their audience better and create more personalized marketing campaigns, leading to higher conversion rates and improved customer satisfaction.",
    },
    {
      que: "How do you identify the target audience?",
      ans: "We use a mix of data analysis, market research, and customer behavior insights to identify your most valuable target audience segments, ensuring your marketing efforts are aimed at the right customers.",
    },
    {
      que: "Can this service help with customer retention?",
      ans: "Yes! By understanding the needs and preferences of your target customer segment, you can improve customer loyalty and retention through more targeted marketing and personalized offerings.",
    },
    {
      que: "How often should target audience analysis be done?",
      ans: "Target market analysis should be done regularly, especially when entering new markets, launching new products, or when there are changes in consumer behavior.",
    },
    {
      que: "Can I use this service for a new product launch?",
      ans: "Absolutely! Our customer segmentation analysis helps ensure your new product reaches the right audience, leading to a more successful launch.",
    },
    {
      que: "What industries do you specialize in?",
      ans: "We work across various industries, including retail, technology, healthcare, and more. Our customer analysis and segmentation approach is adaptable to any market.",
    },
    {
      que: "How long does the analysis take?",
      ans: "The time required varies based on your business size and market complexity, but most analyses are completed within 2-4 weeks.",
    },
    {
      que: "Will this service help me discover new market opportunities?",
      ans: "Yes! Our target market segmentation often uncovers untapped segments that your business can target for future growth.",
    },
  ],
};
