import React from 'react'

const PaymentCard = () => {
  return (
    <div className='bg-white rounded-3xl shadow-2xl relative top-16 '>
        <h3 className='bg-[#D7E9FD] rounded-t-3xl'>Payment Summary</h3>
        <div>
            <h3>Idea Validation</h3>

        </div>
        <div>
            <h3>Total Payment</h3>
            <p>₹5211 /-</p>
        </div>
        <div>
            <h3>GST ( 18% )</h3>
            <p>₹937 /-</p>
        </div>
        <div>
            <h3>Payable Amount</h3>
            <p>₹6149 /-</p>
        </div>
        <button>Validate Now</button>
    </div>
  )
}

export default PaymentCard