import React from "react";
import { useNavigate } from "react-router-dom";

const heroSection = ({ icon, serviceName, heroSection }) => {
  const { image, message, heading, headingPortion, highlightedText, content } =
    heroSection;
  return (
    <div className="flex flex-col-reverse md:flex-col  !items-start md:gap-0 md:items-center mt-20 md:mt-0">
      <div className="flex gap-3 md:gap-5 mt-12 md:!mt-0">
        <img
          src={icon}
          alt="icon"
          className="w-[36px] h-[36px] md:w-[50px] md:h-[50px] "
        />
        <h1 className="text-[25px] md:text-[35px] font-[500] font-cabin">
          {serviceName}
        </h1>
      </div>

      <div className="rounded-[35px] bg-[#CDE2F7] w-[100%] md:mt-9 ">
        <div className=" flex gap-2 px-4 py-3">
          <div className="w-[40%] flex flex-col justify-start !items-start text-left gap-2 ">
            <p className="bg-white w-auto rounded-3xl px-2 md:px-2 py-1 text-[3.5px] md:text-[10px]">
              {message}
            </p>
            <p className="text-[10px] md:text-2xl  font-semibold opacity-70">
              {heading}
              <span className="text-[#0F88CA] !opacity-100">{headingPortion}</span>
            </p>
            <p className="text-[5px] md:text-xs w-[80%]">
              <span className="text-[#0F88CA] !opacity-100">
                {highlightedText}
              </span>{" "}
              {content}
            </p>
          </div>

          <div className="w-[60%]">
            <img src={image} alt="heroSectionBanner" />
          </div>
        </div>
      </div>

      <div></div>
    </div>
  );
};

export default heroSection;
