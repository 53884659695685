import NavBar from "../../Components/bookingDetails/navBar";
import Booking_container from "../../Components/bookingDetails/booking_container";
import Pm_summary2 from "../../Components/bookingDetails/pm_summary2";
import { useEffect, useState } from "react";
import "./BookingDetails.css";
import {
  collection,
  doc,
  getDoc,
  getDocs,
  query,
  where,
} from "firebase/firestore";
import { partnerDB } from "../../config/firebase";
import { useSearchParams } from "react-router-dom";
import useServicesStore from "./storePr";

function BookingDetails() {
  const [searchParams] = useSearchParams();
  const id = searchParams.get("id");
  const index = searchParams.get("index");
  const [isLoading, setIsLoading] = useState(true); // New loading state
  const { servicesData, setServicesData } = useServicesStore();
  const [state, setState] = useState("About");

  console.log(servicesData);

  // useEffect(() => {
  //   const fetchData = async () => {
  //     if (!id) return;

  //     setIsLoading(true); // Set loading state to true before fetching

  //     try {
  //       const q = query(
  //         collection(partnerDB, "newPrData"),
  //         where("id", "==", Number(id))
  //       );

  //       const querySnapshot = await getDocs(q);
  //       const filteredPartners = querySnapshot.docs.map((doc) => doc.data());
  //       console.log("filteredPartners data", filteredPartners);
  //       const matchingPartner = filteredPartners.map((partner) => {
  //         console.log(partner);
  //         const matchingPackages = Object.entries(partner.packages).filter(
  //           ([pkgKey, pkgData]) => {
  //             const replacedPackageName = pkgData.name.replace(/\+/g, " ");
  //             return replacedPackageName === index;
  //           }
  //         );

  //         // Return the partner with only the matching packages
  //         return {
  //           ...partner,
  //           packages: Object.fromEntries(matchingPackages), // Convert back to object form
  //         };
  //       }).filter((partner) => Object.keys(partner.packages).length > 0);
  //       console.log(matchingPartner);
  //       setServicesData(matchingPartner);
  //       console.log(servicesData);
  //       setIsLoading(false); // Set loading state to false after fetching
  //     } catch (error) {
  //       console.error("Error fetching data: ", error);
  //       setIsLoading(false); // Ensure loading state is reset on error
  //     }
  //   };

  //   fetchData();
  // }, [id, partnerDB]);

  // Conditional rendering based on loading state and servicesData
  // if (isLoading) {
  //   return <p>Loading...</p>;
  // }

  const data = Object.entries(servicesData[0].packages).map(([pkgIdx, pkgData]) =>
    pkgData
  );

  if (!servicesData || servicesData.length === 0) {
    return <p>No service data found.</p>;
  }

  const displayName = servicesData[0]?.name.substring(0, 3).toUpperCase();

  const middleIndex = Math.floor(displayName.length / 2);

  const firstPart = displayName.slice(0, middleIndex);
  const middleLetter = displayName[middleIndex];
  const lastPart = displayName.slice(middleIndex + 1);

  return (
    <>
      <div className="app">
        <div id="navBar">
          <NavBar />
        </div>

        <div id="head-section">
          <p>{data[0].name}</p>
        </div>
        <div className="sections">
          <div className="booking-section1">
            <Booking_container
              firstPart={firstPart}
              middleLetter={middleLetter}
              lastPart={lastPart}
              index={index}
            />
          </div>
          <div className="booking-section2">
            <Pm_summary2 data={data} index={index} />
          </div>
        </div>
      </div>
    </>
  );
}

export default BookingDetails;
