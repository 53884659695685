import React from "react";
import VideoSearchBar from "./VideoSearchBar";
import Video_HeroSectionImg from "./assets/Video_library_HerosectionImg.png"
import { useMediaQuery } from "@mui/material";
function VideoLibraryHeroSection(){
    const isMobile=useMediaQuery("(max-width:624px)");
    const isTab=useMediaQuery("(max-width:1100px)");

    return(
        <div className="flex h-max w-full" style={{background:isMobile&&"linear-gradient(90deg, rgba(64, 123, 255, 0.25) 0%, rgba(178, 202, 255, 0.25) 80%, #FFFFFF 95%)"||"linear-gradient(90deg, rgba(64, 123, 255, 0.25) 0%, rgba(178, 202, 255, 0.25) 55%, #FFFFFF 61%)",flexDirection:isMobile&&"column"||"row"}}>
            {/* Left Section */}
            <div className="flex flex-col" style={{paddingTop:isMobile&&"30px"||isTab&&"40px"||"50px",paddingLeft:isMobile&&"0px"||isTab&&"60px"||"100px",paddingBottom:isMobile&&"20px"||isTab&&"30px"||"40px",height:"max-content",width:isMobile&&"100%"||"50%",alignItems:"start"}}>
                {/* Hero section title */}
                <div className="flex h-auto w-auto" style={{fontSize:isMobile&&"30px"||isTab&&"35px"||"45px",lineHeight:"50px",textAlign:"left"}}>
                    <p><span className="text-[#1C6ED0]">Entrepreneurship Knowledge </span> Hub Video Library</p>
                </div>
                {/* Search Bar */}
                <VideoSearchBar/>
                
                {/* Tag div */}
                <div className="flex flex-wrap h-max w-full">
                    {/* Tags */}
                    <div className="flex h-max w-max border border-[#455A64] rounded-full bg-white opacity-50 mr-2 mb-4 text-[#7897BA]"><p className="p-1 px-8" style={{fontSize:isMobile&&"12px"||isTab&&"14px"||"16px"}}>Branding</p></div>
                    <div className="flex h-max w-max border border-[#455A64] rounded-full bg-white opacity-50 mr-2 mb-4 text-[#FFC741]"><p className="p-1 px-4" style={{fontSize:isMobile&&"12px"||isTab&&"14px"||"16px"}}>Business Fundamentals</p></div>
                    <div className="flex h-max w-max border border-[#455A64] rounded-full bg-white opacity-50 mr-2 mb-4 text-[#CF9DBB]"><p className="p-1 px-6" style={{fontSize:isMobile&&"12px"||isTab&&"14px"||"16px"}}>Marketing</p></div>
                    <div className="flex h-max w-max border border-[#455A64] rounded-full bg-white opacity-50 mb-4 mr-2 text-[#A1D4C9]"><p className="p-1 px-6" style={{fontSize:isMobile&&"12px"||isTab&&"14px"||"16px"}}>Sales</p></div>

                    <div className="flex h-max w-max border border-[#455A64] rounded-full bg-white opacity-50 mr-2 mb-4 text-[#CF9DBB]"><p className="p-1 px-8" style={{fontSize:isMobile&&"12px"||isTab&&"14px"||"16px"}}>Product</p></div>
                    <div className="flex h-max w-max border border-[#455A64] rounded-full bg-white opacity-50 mr-2 mb-4 text-[#FFC741]"><p className="p-1 px-4" style={{fontSize:isMobile&&"12px"||isTab&&"14px"||"16px"}}>Market Research</p></div>
                    <div className="flex h-max w-max border border-[#455A64] rounded-full bg-white opacity-50 mr-2 mb-4 text-[#7897BA]"><p className="p-1 px-8" style={{fontSize:isMobile&&"12px"||isTab&&"14px"||"16px"}}>Design</p></div>
                    <div className="flex h-max w-max border border-[#455A64] rounded-full bg-white opacity-50 mb-4 mr-2 text-[#A1D4C9]"><p className="p-1 px-4" style={{fontSize:isMobile&&"12px"||isTab&&"14px"||"16px"}}>Operations</p></div>

                    <div className="flex h-max w-max border border-[#455A64] rounded-full bg-white opacity-50 mr-2 mb-4 text-[#7897BA]"><p className="p-1 px-8" style={{fontSize:isMobile&&"12px"||isTab&&"14px"||"16px"}}>Finance</p></div>
                    <div className="flex h-max w-max border border-[#455A64] rounded-full bg-white opacity-50 mr-2 mb-4 text-[#FFC741]"><p className="p-1 px-6" style={{fontSize:isMobile&&"12px"||isTab&&"14px"||"16px"}}>GTM</p></div>
                    <div className="flex h-max w-max border border-[#455A64] rounded-full bg-white opacity-50 mr-2 mb-4 text-[#CF9DBB]"><p className="p-1 px-6" style={{fontSize:isMobile&&"12px"||isTab&&"14px"||"16px"}}>PMF</p></div>
                    <div className="flex h-max w-max border border-[#455A64] rounded-full bg-white opacity-50 mr-2 mb-4 text-[#A1D4C9]"><p className="p-1 px-4" style={{fontSize:isMobile&&"12px"||isTab&&"14px"||"16px"}}>Business Model</p></div>
                    <div className="flex h-max w-max border border-[#455A64] rounded-full bg-white opacity-50 mb-4 mr-2 text-[#FFC741]"><p className="p-1 px-6" style={{fontSize:isMobile&&"12px"||isTab&&"14px"||"16px"}}>Funding</p></div>
                </div>
            </div>

            {/* Right Section */}
            <div className="flex" style={{height:"max-content",width:isMobile&&"100%"||"50%"}}>
                <img src={Video_HeroSectionImg} style={{height:"auto",width:"100%"}}/>
            </div>
        </div>
    )
}

export default VideoLibraryHeroSection;