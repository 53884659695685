export const GTM_Obj = {
  serviceName: "Go-To-Market",
  icon: "/Images/selfService/GTM_Images/MarketAnalysis_1.png",
  heroSection: {
    image: "/Images/selfService/GTM_Images/GTM_HeroSectionImg.png",
    message: "Build a GTM strategy that supports long-term success and scalability.",
    heading: "Launch Smart:",
    headingPortion: "Develop Your Go-To-Market Strategy",
    highlightedText: "A strong go-to-market marketing strategy ensures a successful launch.",
    content:
      "Crafting the right GTM strategy helps you target the right audience, optimize channels, and create a roadmap for market success from day one.",
  },
  aboutService: {
    content1:
      "Our Go-To-Market (GTM) Strategy service is built to help entrepreneurs, aspiring founders, small business owners, and product managers bring their products to market successfully. We guide you step by step, helping you understand what to launch, where to target, who your product should serve, and how to execute the plan.",
    content2:
      "With our comprehensive GTM strategy framework, you'll enter the market confidently and effectively.",
  },
  features: [
    {
      icon: "/Images/selfService/GTM_Images/Details/Strategy.png",
      title: "Market Entry Strategy Development",
      description:
        "Will create a strategic Go-To-Market (GTM) plan that answers what product to launch, where to launch it, to whom to target, and how to execute the strategy.",
    },
    {
      icon: "/Images/selfService/GTM_Images/Details/Product_launch.png",
      title: "Product Launch Planning",
      description:
        "Step-by-step guidance on how to successfully bring your product to market with a solid **go-to-market strategy framework",
    },
    {
      icon: "/Images/selfService/GTM_Images/Details/Audience.png",
      title: "Target Audience Identification",
      description:
        "Identify and refine your target customers, ensuring you effectively reach **who** your product is meant for.",
    },
    {
      icon: "/Images/selfService/GTM_Images/Details/Teamwork.png",
      title: "Sales & Distribution Strategy",
      description:
        "Build a robust approach for **where** and **how** to distribute your product, ensuring it reaches your customers efficiently.",
    },
    {
      icon: "/Images/selfService/GTM_Images/Details/Social_media.png",
      title: "Marketing Strategy Alignment",
      description:
        "Ensure your **go-to-market plan** aligns with your overall business objectives, helping your product stand out in the marketplace.",
    },
    {
      icon: "/Images/selfService/GTM_Images/Details/Pie_chart.png",
      title: "Adaptable GTM Strategies",
      description:
        "Whether you’re launching locally, regionally, or globally, our **GTM strategy framework** adapts to fit your specific needs and goals.",
    },
  ],
  payemnetSummary: {
    totalPayment: "5211",
    gst: "937",
    Payable: "6149",
  },
  benefits: {
    title: "Benefits of the Service",
    data: [
      {
        title: "Comprehensive Strategy",
        description:
          "Develop a detailed go-to-market plan addressing key questions—what, where, to whom, and how.",
        sno: "01",
      },
      {
        title: "Effective Launch",
        description:
          "Ensure a smooth product launch with a structured, step-by-step GTM plan.",
        sno: "02",
      },
      {
        title: "Precise Audience Targeting",
        description:
          "Identify and refine your target audience to reach the right customers.",
        sno: "03",
      },
      {
        title: "Optimized Sales Channels",
        description: "Create an efficient sales and distribution strategy for maximum product reach.",
        sno: "04",
      },
      {
        title: "Custom Solutions",
        description: "Tailored advice to meet your specific business needs and goals.",
        sno: "05",
      },
      {
        title: "Sustainable Growth",
        description: "Build a GTM strategy that supports long-term success and scalability.",
        sno: "06",
      },
    ],
  },
  featuresOverview: [
    {
      title: "GTM Strategy Development",
      icon: "/Images/selfService/GTM_Images/Features/Diagram.png",
    },
    {
      title: "Go-To-Market Plan Creation",
      icon: "/Images/selfService/GTM_Images/Features/Task.png",
    },
    {
      title: "Product Launch Strategy",
      icon: "/Images/selfService/GTM_Images/Features/Product_launch.png",
    },
    {
      title: "Sales & Distribution Planning",
      icon: "/Images/selfService/GTM_Images/Features/Teamwork.png",
    },
    {
      title: "Audience Targeting & Segmentation",
      icon: "/Images/selfService/GTM_Images/Features/Audience.png",
    },
    {
      title: "Market Entry Strategy",
      icon: "/Images/selfService/GTM_Images/Features/Strategy.png",
    },
    {
      title: "Flexible GTM Strategy Framework",
      icon: "/Images/selfService/GTM_Images/Features/Flexibility.png",
    },
    {
      title: "Custom Reports & Actionable Plans",
      icon: "/Images/selfService/GTM_Images/Features/Seo_report.png",
    },
    {
      title: "Tailored Consultation Sessions",
      icon: "/Images/selfService/GTM_Images/Features/conversation.png",
    },
    {
      title: "Ongoing Support & Guidance",
      icon: "/Images/selfService/GTM_Images/Features/Consulting.png",
    },
  ],

  howItWorks: [
    {
      bg:"/Images/selfService/BusinessCanvasImages/HowItWorks/fg1.png",
      fg:"/Images/selfService/howItWorks/InitialConsultation.png",
      step: "Initial Consultation",
      description:
        "We discuss your business, market, and product goals.",
    },
    {
      bg:"/Images/selfService/BusinessCanvasImages/HowItWorks/fg2.png",
      fg:"/Images/selfService/howItWorks/Strategic Review.png",
      step: "Strategy Development",
      description:
        "We help you build a customized go-to-market plan that covers what, where, to whom, and how.",
    },
    {
      bg:"/Images/selfService/BusinessCanvasImages/HowItWorks/fg3.png",
      fg:"/Images/selfService/howItWorks/Customised consulation.png",
      step: "Execution Support",
      description:
        "We provide guidance during execution to ensure a successful product launch.",
    },
    {
      bg:"/Images/selfService/BusinessCanvasImages/HowItWorks/fg4.png",
      fg:"/Images/selfService/howItWorks/Results.png",
      step: "Ongoing Assistance",
      description:
        "After launch, we offer continued support to help you refine and adjust your strategy for long-term success.",
    },
  ],
  targetAudience: [
    {
      icon: "/Images/selfService/BusinessCanvasImages/WhoShouldUseService/targeticon1.png",
      iconMobile: "/Images/selfService/BusinessCanvasImages/WhoShouldUseService/targeticonMobile1.png",
      title: "Aspiring Entrepreneurs",
      content1: "If you’re ready to bring your product to market,",
      content2:
      "we’ll guide you through creating an effective go-to-market strategy that addresses what, where, to whom, and how.",
    },
    {
      icon: "/Images/selfService/BusinessCanvasImages/WhoShouldUseService/targeticon2.png",
      iconMobile: "/Images/selfService/BusinessCanvasImages/WhoShouldUseService/targeticonMobile2.png",
      title: "Small Business Owners",
      content1: "Responsible for new product launches?",
      content2:
      "Our go-to-market plan provides you with actionable steps to ensure success.",
    },
    {
      icon: "/Images/selfService/BusinessCanvasImages/WhoShouldUseService/targeticon3.png",
      iconMobile: "/Images/selfService/BusinessCanvasImages/WhoShouldUseService/targeticonMobile3.png",
      title: "Established Companies",
      content1:
      "Launching new products or expanding into new markets?",
      content2:
      "We’ll help you build a go-to-market strategy framework that supports growth.",
    },
    {
      icon: "/Images/selfService/BusinessCanvasImages/WhoShouldUseService/targeticon4.png",
      iconMobile: "/Images/selfService/BusinessCanvasImages/WhoShouldUseService/targeticonMobile4.png",
      title: "Product Managers",
      content1: "Need to launch in a new region?",
      content2:
        "We’ll guide you through what product to offer, where to market it, and how to ensure a smooth entry.",
    },
  ],

  faq: [
    {
      que: "What is a Go-To-Market (GTM) Strategy?",
      ans: "A go-to-market strategy is a detailed plan that answers the critical questions of what product to launch, where to target, to whom you should sell, and how to do it effectively.",
    },
    {
      que: "What are the key elements of a GTM strategy?",
      ans: "The key elements include market research, identifying your target audience, developing a marketing strategy, sales and distribution planning, and creating an execution roadmap.",
    },
    {
      que: "How does the Go-To-Market Strategy help my business?",
      ans: "A well-developed go-to-market strategy ensures that your product is launched effectively, reaches the right audience, and sustains growth in the market.",
    },
    {
      que: "What industries do you work with?",
      ans: "We work across a wide range of industries, including technology, retail, manufacturing, and services, tailoring each GTM strategy to your unique business and market conditions.",
    },
    {
      que: "What happens if my GTM strategy needs to change post-launch?",
      ans: "We offer ongoing support after launch to help refine your go-to-market plan and make adjustments as needed to ensure long-term success.",
    },
    {
      que: "How long does it take to develop a GTM strategy?",
      ans: "The time required depends on the complexity of your product and market, but most strategies take between 2-6 weeks to fully develop and begin execution.",
    },
    {
      que: "Can I use this service if I’m launching globally?",
      ans: "Yes! Our **go-to-market strategy framework** is adaptable to global markets, ensuring you enter new regions with a comprehensive plan.",
    },
    {
      que: "What makes your GTM service different?",
      ans: "Our service focuses on personalized strategies and actionable insights, helping you launch not just with confidence but also with the flexibility to adapt and grow.",
    },
    {
      que: "How much involvement is required from my team?",
      ans: "Your team will work closely with our experts to provide necessary information, but we’ll guide you through each step and handle the heavy lifting to create your GTM strategy.",
    },
  ],
};