import {
  useMediaQuery,
  Button,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Paper,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import BizowalLogo from "../assets/logo-of-BIZOWL--Business-Services.png";
import back from "../assets/Back-to-Quotes.png";
import PhonePic from "../assets/Vector (1).png";
import svg from "../../Services/assets/compareSVG.svg";
import mockData from "./mockData.json";

const CompareLogoServices = () => {
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const [serviceIds, setServiceIds] = useState([]);
  const [indices, setIndices] = useState([]);
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState(mockData);
  const [filteredData, setFilteredData] = useState([]);
  const isMobile = useMediaQuery("(max-width:768px)");

  useEffect(() => {
    const ids = [];
    const services = searchParams.get("services");
    if (services) {
      const serviceArray = services.split(",");
      setServiceIds(serviceArray);
      const indicesArray = serviceArray.map((id) => parseInt(id, 10));
      setIndices(indicesArray);
    }
  }, [searchParams]);

  useEffect(() => {
    const extractData = () => {
      const filtered = [];
      data.forEach((partner) => {
        const matchingPlans = partner.Plans.filter((plan) =>
          serviceIds.includes(plan.id.toString())
        );
        if (matchingPlans.length > 0) {
          filtered.push({
            "Partner Name": partner["Partner Name"],
            Plans: matchingPlans,
          });
        }
      });
      setFilteredData(filtered);
    };
    extractData();
    setLoading(false);
  }, [data, serviceIds]);

  if (loading) {
    return <p>Loading...</p>;
  }

  function createData(name, ...values) {
    return {
      name,
      ...values.reduce(
        (acc, val, idx) => ({ ...acc, [`value${idx + 1}`]: val }),
        {}
      ),
    };
  }

  const rows =
    filteredData.length > 0
      ? [
          createData(
            "Partner Name",
            ...filteredData.map((item) => item["Partner Name"])
          ),
          createData(
            "Plan Name",
            ...filteredData.map((item) => item.Plans[0]["Plan Name"])
          ),
          createData(
            "Types of Logo Designs",
            ...filteredData.map(
              (item) => item.Plans[0]["Types of Logo Designs"]
            )
          ),
          createData(
            "Logo Styles",
            ...filteredData.map((item) => item.Plans[0]["Logo Styles"])
          ),
          createData(
            "Experience (Years)",
            ...filteredData.map(
              (item) => item.Plans[0]["Total No of Experince"]
            )
          ),
          createData(
            "Specialized In",
            ...filteredData.map((item) => item.Plans[0]["Specilized"])
          ),
          createData(
            "Number of Concepts",
            ...filteredData.map((item) => item.Plans[0]["Concepts"])
          ),
          createData(
            "Revisions Included",
            ...filteredData.map((item) => item.Plans[0]["Revisions Included"])
          ),
          createData(
            "Branding Assets",
            ...filteredData.map((item) => item.Plans[0]["Branding Assets"])
          ),
          createData(
            "Asset Details",
            ...filteredData.map((item) => item.Plans[0]["Details of Assets"])
          ),
          createData(
            "Deliverable Formats",
            ...filteredData.map((item) => item.Plans[0]["Deliverables"])
          ),
          createData(
            "Price (₹)",
            ...filteredData.map((item) => item.Plans[0]["Price"])
          ),
          createData(
            "Delivery Time (Days)",
            ...filteredData.map((item) => item.Plans[0]["TAT (days)"])
          ),
          createData(
            "Post Delivery Support",
            ...filteredData.map(
              (item) => item.Plans[0]["Post Delivery Support"]
            )
          ),
          createData(
            "Revision/Refund Policy",
            ...filteredData.map(
              (item) => item.Plans[0]["Revision/Refund Policy"]
            )
          ),
        ]
      : [];

  const handleClick = (isMobile) => {
    if (isMobile) {
      window.location.href = "tel:+919354651433";
    } else {
      window.location.href =
        "https://wa.me/919354651433?text=Hello%20I%20need%20help";
    }
  };

  const getColumnWidth = () => {
    const baseWidth = isMobile ? 15 : 27;
    const remainingWidth = 100 - baseWidth;
    const numColumns = filteredData.length;
    return {
      firstColumn: `${baseWidth}%`,
      dataColumn: `${remainingWidth / numColumns}%`,
    };
  };

  const columnWidths = getColumnWidth();

  // Create header row data
  const headerRow = filteredData.map((partner) => ({
    partnerCode: partner["Partner Name"].substring(0, 3).toUpperCase(),
    price: partner.Plans[0].Price,
    deliveryTime: partner.Plans[0]["TAT (days)"],
  }));


  return (
    <div
      className="card"
      style={{
        backgroundColor: "#f7fcfb",
        position: "relative",
      }}
    >
      {/* Nav */}
      <div
        className="card-body"
        style={{
          backgroundColor: "#f7fcfb",
          position: "fixed",
          top: "0px",
          right: "0px",
          left: "0px",
          zIndex: "9999",
          boxShadow: "0 4px 6.4px 0 #c6cac9",
        }}
      >
        <div className="d-flex justify-content-between w-[90%] mx-auto">
          <div className="d-flex align-items-center">
            <a href="/">
              <img
                src={BizowalLogo}
                alt="BizowlLogo"
                width={isMobile ? "120px" : "200px"}
                onClick={() => navigate("/")}
              />
            </a>
          </div>
          <div
            className="d-flex align-items-center cursor-pointer"
            onClick={() => handleClick(isMobile)}
          >
            <img
              src={PhonePic}
              alt="PhonePic"
              width={isMobile ? "30px" : "40px"}
            />
            <p style={{ color: "#407bff" }}>Need help?</p>
          </div>
        </div>
      </div>

      <div
        className="absolute cursor-pointer"
        style={{
          margin: isMobile ? "6rem" : "7rem",
          left: isMobile ? "-4rem" : "-2rem",
        }}
        onClick={() => navigate(-1)}
      >
        <img src={back} width="120px" alt="back" />
      </div>

      {/* Comparison Table */}
      <div
        style={{
          width: isMobile ? "95%" : "80%",
          margin: "10px auto 40px auto",
          marginTop: isMobile ? "8rem" : "10rem",
        }}
      >
        <TableContainer
          component={Paper}
          sx={{ borderRadius: "25px", padding: "20px 0px" }}
        >
          <Table sx={{ minWidth: 650 }} aria-label="logo comparison table">
            <TableBody>
              {/* /* Header Cards Row */ }
              <TableRow>
                <TableCell sx={{ width: columnWidths.firstColumn }}>
                  {!isMobile && (
                    <img
                      alt="compare"
                      src={svg}
                      style={{
                        minWidth: filteredData.length < 3 ? "300px" : "200px",
                        display: filteredData.length > 3 ? "none" : "block",
                      }}
                    />
                  )}
                </TableCell>
                {headerRow.map((header, idx) => (
                  <TableCell
                    key={idx}
                    sx={{
                      width: columnWidths.dataColumn,
                      padding: "15px",
                    }}
                  >
                    <div
                      style={{
                        maxWidth: "300px",
                        borderRadius: "25px",
                        boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.2)",
                        padding: "15px",
                        margin: "0 auto",
                      }}
                    >
                      <div
                        style={{
                          border: "1px solid #70a6e6",
                          borderRadius: "10px",
                          borderWidth: "4px",
                          width: "120px",
                          padding: "7px",
                          display: "flex",
                          flexDirection: "row",
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                      >
                        <h3
                          style={{
                            fontSize: "1.5rem",
                            fontWeight: "520",
                            color: "#455A64",
                          }}
                        >
                          {header.partnerCode}
                        </h3>
                      </div>
                      <div>
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "row",
                            justifyContent: "space-between",
                          }}
                        >
                          <div
                            style={{
                              display: "flex",
                              flexDirection: "column",
                              marginTop: "10px",
                            }}
                          >
                            <p style={{ fontSize: "12px" }}>Total Price Included GST</p>
                            <h5 className="font-medium text-lg">₹{header.price}</h5>
                          </div>
                          <div
                            style={{
                              display: "flex",
                              flexDirection: "column",
                              marginTop: "10px",
                            }}
                          >
                            <p style={{ fontSize: "12px" }}>Delivery Time</p>
                            <h5 className="font-medium text-lg">{header.deliveryTime} Days</h5>
                          </div>
                        </div>
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "column",
                            gap: "10px",
                            marginTop: "15px",
                          }}
                        >
                          <div style={{ marginTop: "10px" }}>
                            <Button
                              fullWidth
                              variant="contained"
                              style={{
                                backgroundColor: "#1c6ed0",
                                textTransform: "none",
                              }}
                              onClick={() =>
                                navigate(
                                  `/services/logo-design-distribution/quote-details/service-summary/?id=${filteredData[idx].Plans[0].id}`
                                )
                              }
                            >
                              Book Now
                            </Button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </TableCell>
                ))}
              </TableRow>

              {/* Existing comparison rows */}
              {rows.map((row) => (
                <TableRow
                  key={row.name}
                  sx={{
                    "&:last-child td, &:last-child th": { border: 0 },
                    "&:nth-of-type(odd)": { backgroundColor: "#F7FCFB" },
                  }}
                >
                  <TableCell
                    component="th"
                    scope="row"
                    sx={{
                      width: columnWidths.firstColumn,
                      fontWeight: "500",
                      color: "#333",
                    }}
                  >
                    {row.name}
                  </TableCell>
                  {Array.from({ length: filteredData.length }, (_, i) => (
                    <TableCell
                      key={i}
                      align="center"
                      sx={{ width: columnWidths.dataColumn }}
                    >
                      {row[`value${i + 1}`]}
                    </TableCell>
                  ))}
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </div>
    </div>
  );
};

export default CompareLogoServices;
