import React from 'react'

const Herosection = () => {
  return (
    <div className='flex flex-col !gap-6 !items-start !flex-wrap !content-center !pt-5 !pb-0 !px-5 md:flex-col-reverse md:!px-[11.5rem]'>
        <img className='md:!hidden' src="/Images/selfService/heroSection.png" alt="heroSectionBanner" />
        <img className='!hidden md:!block' src="/Images/selfService/heroSectionBig.png" alt="heroSectionBanner" />
        <div className='flex gap-2'>
        <img src="/Images/selfService/ideaIconMain.png" alt="featuresIcon" />
        <h2 className="text-[24px] font-semibold text-start md:text-[45px]">
          Idea Validation
        </h2>
      </div>
    </div>
  )
}

export default Herosection