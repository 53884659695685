import React from "react";
import VideoLibraryHeroSection from "../../Components/videoLibrary/VideoLibraryHeroSection";
// import VideoCard from "../../Components/VideoCard";
import { useMediaQuery } from "@mui/material";
import NavBar from "../../Components/Header/NavBar";
import Footer from "../../Components/Footer/Footer";
import VideoCard from "../../Components/resourceSection/components/VideoCard";

function Video_Library(){

    const VideoCompData =[
   
        {h1:"After PMF : People Customer Sucess",h3:" Marketing",videoId:"dpyGKtkYubY"},
        {h1:"After PMF : People Customer Sucess",h3:"Marketing",videoId:"gEiK-bDOADg"},
      {h1:"After PMF : People Customer Sucess",h3:"Marketing",videoId:"dpyGKtkYubY"},
     
    ]
    
    const isMobile=useMediaQuery("(max-width:624px)");
    const isTab=useMediaQuery("(max-width:1100px)");
    return(
        <div className="flex flex-col items-center font-cabin w-full" style={{height:"max-content",}}>
        <NavBar/>
            <VideoLibraryHeroSection/>
            <div className="flex w-full bg-[#1C6ED0]" style={{height:isMobile&&"50px"||isTab&&"75px"||"100px"}}></div>
            <div className=" flex flex-wrap justify-center gap-8 ">
            <VideoCard h1={VideoCompData[0].h1} h3={VideoCompData[0].h3} videoId={VideoCompData[0].videoId}/>
                <VideoCard h1={VideoCompData[0].h1} h3={VideoCompData[0].h3} videoId={VideoCompData[0].videoId}/>
                <VideoCard h1={VideoCompData[1].h1} h3={VideoCompData[1].h3} videoId={VideoCompData[1].videoId}/>
                <VideoCard/>
                <VideoCard />
                <VideoCard/>
            </div>
            <Footer/>
        </div>
    )
}

export default Video_Library;