import React from 'react'
import CircleContainer from './CircleContainer'
import { useMediaQuery } from '@mui/material';


const ImageContainer1 = () => {
  const isMobile = useMediaQuery("(max-width:724px)");
  const isTab = useMediaQuery("(max-width:1024px)");
  const isLaptop = useMediaQuery("(max-width:1224px)");
  return (
    <div className='relative' style={{borderRadius: isMobile?"15px":"35px", backgroundColor : "#69a0df", width:isMobile ? "157px" :"260px", height:isMobile ? "210px" :"353px",top:"40px",left:isMobile?"40px":"0px"}}>
        <img className='relative bottom-0' style={{height:isMobile?"180px":"320px",width:isMobile?"310px":"350px",left:isMobile?"8px":"20px",top:isMobile?"30px":"32px",zIndex:2}} src="Images/guidanceSection/serious-handsome.png" alt="handsomeman" />
        <CircleContainer width={isMobile ? "16px" :"25px"} height={isMobile ? "16px" :"25px"} color={"#5291DB"} bottom={isMobile?"90px":"190px"} left={isMobile?"28px":"60px"}/>
        <CircleContainer width={isMobile ? "16px" :"25px"} height={isMobile ? "16px" :"25px"} color={"#5291DB"} bottom={isMobile?"175px":"320px"} left={isMobile?"20px":"30px"}/>
        <CircleContainer width={isMobile ? "11px" :"15px"} height={isMobile ? "11px" :"15px"} color={"#5291DB"} bottom={isMobile?"160px":"290px"} left={isMobile?"47px":"91px"}/>
        <CircleContainer width={isMobile ? "7px" :"10px"} height={isMobile ? "7px" :"10px"} color={"#5291DB"}  bottom={isMobile?"170px":"300px"} left={isMobile?"121px":"215px"}/>
        <CircleContainer width={isMobile ? "7px" :"10px"} height={isMobile ? "7px" :"10px"} color={"#5291DB"} bottom={isMobile?"200px":"360px"} left={isMobile?"100px":"170px"}/>
    </div>
  )
}

export default ImageContainer1