import React from "react";
import NavBar from "../Header/NavBar";
import Bottom from "./components/Bottom";
import Footer from "../Footer/Footer";
import { Helmet } from "react-helmet";
import ServicePage from "../../pages/ServicePage";
import { BusinessConsultancyObj } from "../../data/BusinessConsultancyService/BusinessConsultancyObj"

const BusinessConsultancyPage = () => {
  return (
    <>
      <Helmet>
      <meta charset="UTF-8"/>
<meta name="viewport" content="width=device-width, initial-scale=1.0"/>
<title>Business Consultancy Service | Expert Business Guidance | Bizowl</title>
<meta name="description" content="Bizowl's Business Consultancy Service provides expert advice in business strategy, growth, operations, and financial planning to help you succeed."/>
<meta name="keywords" content="Business Consultancy Service, Business Strategy Consulting, Business Consultant for Small Business, Business Planning Services, Professional Business Consulting, Expert Business Guidance, Business Growth Strategy, Business Operations Consulting, Business Financial Planning, Business Development Consultant"/>
<meta name="robots" content="index, follow"/>
<meta name="author" content="Bizowl"/>
<meta http-equiv="Content-Language" content="en"/>
<link rel="canonical" href="https://www.bizzowl.com/services/business-consultancy-service" />
<meta property="og:title" content="Business Consultancy Service | Expert Business Guidance | Bizowl"/>
<meta property="og:description" content="Get tailored business consultancy services from Bizowl to ensure growth, strategic success, and operational efficiency for your business."/>
<meta property="og:image" content="https://res.cloudinary.com/dcduojrhf/image/upload/v1728309795/Busines_Consultancy_Service_ti6ql4.png"/>
<meta property="og:url" content="https://www.bizzowl.com/services/business-consultancy-service"/>
<meta name="twitter:card" content="summary_large_image"/>
<meta name="twitter:title" content="Business Consultancy Service | Expert Business Guidance | Bizowl"/>
<meta name="twitter:description" content="Optimize your business strategies, operations, and financial planning with Bizowl's professional consultancy services."/>
<meta name="twitter:image" content="https://res.cloudinary.com/dcduojrhf/image/upload/v1728309795/Busines_Consultancy_Service_ti6ql4.png"/>
        <script type="application/ld+json">
                {`
          "@context": "https://schema.org",
          "@type": "Product",
          "name": "Business Consultancy Service",
          "description": "Bizowl's Business Consultancy Service offers expert advice in business strategy, operations, growth, and financial planning to help you achieve success.",
          "brand": {
            "@type": "Organization",
            "name": "Bizowl",
            "sameAs": "https://bizzowl.com",
            "logo": "https://www.bizzowl.com/Images/logos/logo-of-BIZOWL--Business-Services.png"
          },
          "offers": {
            "@type": "Offer",
            "url": "https://bizzowl.com/services/business-consultancy-service",
            "priceCurrency": "INR",
            "price": "5958",
            "priceValidUntil": "2024-12-31",
            "availability": "https://schema.org/InStock",
            "itemCondition": "https://schema.org/NewCondition"
          },
          "review": {
            "@type": "Review",
            "reviewRating": {
              "@type": "Rating",
              "ratingValue": "4.8",
              "bestRating": "5"
            },
            "author": {
              "@type": "Person",
              "name": "Team Bizowl"
            }
          },
          "aggregateRating": {
            "@type": "AggregateRating",
            "ratingValue": "4.7",
            "reviewCount": "38"
          },
          "category": "Business Consultancy"
        `}
        </script>
      </Helmet>
      <div className="bg-[#F5F5F5] w-[100vw] overflow-x-hidden ">
        <NavBar />
        <div className=" md:!p-16 p-3 pt-0  w-[100%] md:w-[80%] mx-auto bg-[#F5F5F5]  mb-2">
          <ServicePage serviceObj={BusinessConsultancyObj} />
         
        </div>
        <Footer />


        <Bottom serviceObj={BusinessConsultancyObj} />

      </div>
    </>
  );
};

export default BusinessConsultancyPage;
