import React, { useEffect, useState } from "react";
import { handlePayment } from "./utils/paymentGateway";
import useStore from "./store";

const IdeaForm = ({
  currentStep,
  handleContinue,
  handleBack,
  showNextStep,
  setShowNextStep,
  filterQuestion,
}) => {
  const { ideaDetails, contactDetails, setIdeaDetails, setContactDetails } =
    useStore();

  const [isFormValid, setIsFormValid] = useState(false);
  const [showValidateButton, setShowValidateButton] = useState(false);

  const handleInputChange = (e,index) => {
    const { name, value } = e.target;
    if (
      name === "questions" 
    ) {
      setIdeaDetails({ ...ideaDetails, [index]: value });
    } else {
      setContactDetails({ ...contactDetails, [name]: value });
    }
    validateForm();
  };

  useEffect(() => {
    validateForm();
  }, [ideaDetails, contactDetails]);

  const handleContinueClick = () => {
    // if (isFormValid) {
    handleContinue();
    setShowNextStep(true);
    
    // }
  };

  const handlePaymentClick = (e) => {
    
    handlePayment(e, ideaDetails, contactDetails);
    console.log("button triggered!");
  };

  const handleValidate = () => {
    setShowValidateButton(true);
    if (
      contactDetails.fullName &&
      contactDetails.phone &&
      contactDetails.email &&
      contactDetails.location
    ) {
      console.log("Form is valid");
    } else {
      console.log("Form is not valid");
    }
  };

  const validateForm = () => {
    if (
      ideaDetails.ideaAbout &&
      ideaDetails.targetAudience &&
      ideaDetails.problemSolved &&
      ideaDetails.marketResearch &&
      ideaDetails.timeline &&
      ideaDetails.competitors
    ) {
      setIsFormValid(true);
    } else {
      setIsFormValid(false);
    }
  };

  return (
    <div className="p-5">
      <h2 className="text-lg text-left font-bold">
        <span
          style={{
            textDecorationColor: "#0080ff",
            textDecorationThickness: "3px",
          }}
          className="underline underline-offset-4"
        >
          Details{" "}
        </span>
        of the idea
      </h2>
      {!showNextStep && (
        <div className="text-left">
          {filterQuestion[0]?.questions?.map((data, index) => (
            <div className="mb-4 mt-5" key={index}>
              <label className="block text-gray-700 text-sm font-bold mb-2">
                {data} *
              </label>
              <textarea
                className="shadow appearance-none border rounded w-full py-5 px-3 bg-[#eee] text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                name="questions"
                value={ideaDetails[index]}
                onChange={(e) => handleInputChange(e, index)}
                required
              />
            </div>
          ))}

          <button
            className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
            onClick={handleContinueClick}
            // disabled={!isFormValid}
          >
            Continue
          </button>
        </div>
      )}
      {showNextStep && currentStep === 2 && (
        <div className="text-left">
          <div className="mb-4 mt-4">
            <label className="block text-gray-700 text-sm font-bold mb-2">
              Full Name *
            </label>
            <input
              className="shadow appearance-none border rounded w-full py-2 px-3 bg-[#eee] text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
              type="text"
              name="fullName"
              value={contactDetails.fullName}
              onChange={handleInputChange}
              placeholder="John Doe"
              required
            />
          </div>
          <div className="mb-4">
            <label className="block text-gray-700 text-sm font-bold mb-2">
              Phone *
            </label>
            <input
              className="shadow appearance-none border rounded w-full py-2 px-3 bg-[#eee] text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
              type="text"
              name="phone"
              value={contactDetails.phone}
              onChange={handleInputChange}
              placeholder="9354651433"
              required
            />
          </div>
          <div className="mb-4">
            <label className="block text-gray-700 text-sm font-bold mb-2">
              Email *
            </label>
            <input
              className="shadow appearance-none border rounded w-full py-2 px-3 bg-[#eee] text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
              type="email"
              name="email"
              value={contactDetails.email}
              onChange={handleInputChange}
              placeholder="johndoe@gmail.com"
              required
            />
          </div>
          <div className="mb-4">
            <label className="block text-gray-700 text-sm font-bold mb-2">
              Location *
            </label>
            <input
              className="shadow appearance-none border rounded w-full py-2 px-3 bg-[#eee] text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
              type="text"
              name="location"
              value={contactDetails.location}
              onChange={handleInputChange}
              placeholder="City, State"
              required
            />
          </div>
          <button
            type="submit"
            className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
            onClick={handlePaymentClick}
          >
            Validate Now
          </button>
        </div>
      )}
    </div>
  );
};

export default IdeaForm;