import React from "react";
import NavBar from "../Header/NavBar";
import Bottom from "./components/Bottom";
import Footer from "../Footer/Footer";
import { Helmet } from "react-helmet";
import ServicePage from "../../pages/ServicePage";
import { SwotObj } from "../../data/swot/SwotObj";

const SwotAnalysis = () => {
  return (
    <>
      <Helmet>
      <meta charset="UTF-8"/>
<meta name="viewport" content="width=device-width, initial-scale=1.0"/>
<title>Comprehensive SWOT Analysis Service for Businesses | Bizowl</title>
<meta name="description" content="Bizowl offers a comprehensive SWOT analysis service to help businesses identify their strengths, weaknesses, opportunities, and threats for strategic growth."/>
<meta name="keywords" content="SWOT analysis, business analysis, SWOT strategy, strengths and weaknesses, strategic growth, business opportunities"/>
<meta name="robots" content="index, follow"/>
<meta name="author" content="Bizowl"/>
<meta http-equiv="Content-Language" content="en"/>
<link rel="canonical" href="https://www.bizzowl.com/services/swot-analysis-of-a-business" />
<meta property="og:title" content="SWOT Analysis of a Business | Bizowl"/>
<meta property="og:description" content="Get a professional SWOT analysis to better understand your business position and maximize growth opportunities with Bizowl."/>
<meta property="og:image" content="https://res.cloudinary.com/dcduojrhf/image/upload/v1728453321/SWOT_Analsysis_crzkzp.png"/>
<meta property="og:url" content="https://www.bizzowl.com/services/swot-analysis-of-a-business"/>
<meta name="twitter:card" content="summary_large_image"/>
<meta name="twitter:title" content="SWOT Analysis of a Business | Bizowl"/>
<meta name="twitter:description" content="Leverage Bizowl's SWOT analysis service to evaluate your business strengths and weaknesses, and plan for future success."/>
<meta name="twitter:image" content="https://res.cloudinary.com/dcduojrhf/image/upload/v1728453321/SWOT_Analsysis_crzkzp.png"/>
              <script type="application/ld+json">
                {`
          "@context": "https://schema.org",
  "@type": "Product",
  "name": "SWOT Analysis Service",
  "description": "Bizowl's SWOT Analysis Service helps businesses identify strengths, weaknesses, opportunities, and threats to strategize effectively for growth.",
  "brand": {
    "@type": "Organization",
    "name": "Bizowl",
    "sameAs": "https://www.bizzowl.com",
    "logo": "https://www.bizzowl.com/Images/logos/logo-of-BIZOWL--Business-Services.png"
  },
  "offers": {
    "@type": "Offer",
    "url": "https://www.bizzowl.com/services/swot-analysis-of-a-business",
    "priceCurrency": "INR",
    "price": "5958",
    "priceValidUntil": "2024-12-31",
    "availability": "https://schema.org/InStock",
    "itemCondition": "https://schema.org/NewCondition"
  },
  "review": {
    "@type": "Review",
    "reviewRating": {
      "@type": "Rating",
      "ratingValue": "4.8",
      "bestRating": "5"
    },
    "author": {
      "@type": "Person",
      "name": "Team Bizowl"
    }
  },
  "aggregateRating": {
    "@type": "AggregateRating",
    "ratingValue": "4.7",
    "reviewCount": "38"
  },
  "category": "Business Consultancy"
        `}
        </script>
      </Helmet>
      <div className="bg-[#F5F5F5] w-[100vw] overflow-x-hidden ">
        <NavBar />
        <div className=" md:!p-16 p-3 pt-0  w-[100%] md:w-[80%] mx-auto bg-[#F5F5F5]  mb-2">
          <ServicePage serviceObj={SwotObj} />
         
        </div>
        <Footer />


        <Bottom serviceObj={SwotObj} />

      </div>
    </>
  );
};

export default SwotAnalysis;
