import React from "react";
import CloseIcon from "@mui/icons-material/Close";
import { IconButton } from "@mui/material";

const PaymentDetailsModal = ({ totalPayment, gst, totalAmount, onClose }) => {
  return (
    <div className="fixed top-0 left-0 right-0 bottom-0 bg-gray-500 bg-opacity-50 flex justify-center items-center">
      <div className="bg-white shadow-md rounded-md p-4 w-3/4 md:w-1/2 lg:w-1/3 xl:w-1/4">
        <div className="flex items-center mb-4">
          <div className="flex-1 text-center">
            <img src="/Images/logos/logo-of-BIZOWL--Business-Services.png" alt="Logo" className="h-6 w-35" />
          </div>
          <IconButton className="py-1 px-1 rounded-md" onClick={onClose}>
            <CloseIcon />
          </IconButton>
        </div>
        <h2 className="text-lg font-bold mb-2">Idea Validation</h2>
        <hr className="mb-4" />
        <div className="flex justify-between mb-2">
          <p>Total Payment</p>
          <p>₹{totalPayment}</p>
        </div>
        <div className="flex justify-between mb-2">
          <p>GST (18%)</p>
          <p>₹{gst}</p>
        </div>
        <hr className="mb-4" />
        <div className="flex justify-between font-bold">
          <p>Payable Amount</p>
          <p>₹ {totalAmount.toFixed(2)}</p>
        </div>
      </div>
    </div>
  );
};

export default PaymentDetailsModal;
