import React from 'react'

const Features = () => {
  return (
    <div className='!pt-5  md:!pt-10 !pb-0 !px-5'>
        <h2 className="text-[20px] font-semibold text-start md:!text-[35px] ">Features of Idea Validation Service </h2>
        {/* <hr /> */}
        <div className='px-2 flex flex-col !gap-3 !items-start md:!hidden'>
            <div className='flex !gap-5 !items-center'>
                <img src="/Images/selfService/featuresIcon1.png" alt="featuresIcon" />
                <p className='text-[15px] text-gray-600'>Dedicated Account Manager </p>
            </div>
            <div className='flex !gap-5 !items-center'>
                <img src="/Images/selfService/featuresIcon2.png" alt="featuresIcon" />
                <p className='text-[15px] text-gray-600'>Expert-led Validation </p>
            </div>
            <div className='flex !gap-5 !items-center'>
                <img src="/Images/selfService/featuresIcon3.png" alt="featuresIcon" />
                <p className='text-[15px] text-gray-600'>Data-Driven Insights </p>
            </div>
            <div className='flex !gap-5 !items-center'>
                <img src="/Images/selfService/featuresIcon4.png" alt="featuresIcon" />
                <p className='text-[15px] text-gray-600'>Risk Assessment and Mitigation </p>
            </div>
            <div className='flex !gap-5 !items-center'>
                <img src="/Images/selfService/featuresIcon5.png" alt="featuresIcon" />
                <p className='text-[15px] text-gray-600'>Access to a Resource Library </p>
            </div>
        </div>
        <div className='px-2 !gap-3 !items-start !my-5 hidden md:!grid  grid-cols-2 '>
            <div className='flex !gap-5 !items-center'>
                <img src="/Images/selfService/featuresIconBig1.png" alt="featuresIcon" />
                <p className='text-[15px] text-gray-600'>Dedicated Account Manager </p>
            </div>
            <div className='flex !gap-5 !items-center'>
                <img src="/Images/selfService/featuresIconBig2.png" alt="featuresIcon" />
                <p className='text-[15px] text-gray-600'>Expert-led Validation </p>
            </div>
            <div className='flex !gap-5 !items-center'>
                <img src="/Images/selfService/featuresIconBig3.png" alt="featuresIcon" />
                <p className='text-[15px] text-gray-600'>Data-Driven Insights </p>
            </div>
            <div className='flex !gap-5 !items-center'>
                <img src="/Images/selfService/featuresIconBig4.png" alt="featuresIcon" />
                <p className='text-[15px] text-gray-600'>Risk Assessment and Mitigation </p>
            </div>
            <div className='flex !gap-5 !items-center'>
                <img src="/Images/selfService/featuresIconBig5.png" alt="featuresIcon" />
                <p className='text-[15px] text-gray-600'>Access to a Resource Library </p>
            </div>
            <div className='flex !gap-5 !items-center'>
                <img src="/Images/selfService/featuresIconBig6.png" alt="featuresIcon" />
                <p className='text-[15px] text-gray-600'>Future Strategy Support </p>
            </div>
            <div className='flex !gap-5 !items-center'>
                <img src="/Images/selfService/featuresIconBig7.png" alt="featuresIcon" />
                <p className='text-[15px] text-gray-600'>Idea Pivot Support </p>
            </div>
            <div className='flex !gap-5 !items-center'>
                <img src="/Images/selfService/featuresIconBig8.png" alt="featuresIcon" />
                <p className='text-[15px] text-gray-600'>Free Market Entry Consultation </p>
            </div>
            <div className='flex !gap-5 !items-center'>
                <img src="/Images/selfService/featuresIconBig9.png" alt="featuresIcon" />
                <p className='text-[15px] text-gray-600'>Interactive Feedback Sessions </p>
            </div>
            <div className='flex !gap-5 !items-center'>
                <img src="/Images/selfService/featuresIconBig10.png" alt="featuresIcon" />
                <p className='text-[15px] text-gray-600'>Multi-Channel Feedback  </p>
            </div>
        </div>
        <button className='border-blue-600 border-2 md:!hidden'>View all Features</button>
    </div>
  )
}

export default Features