export const MarketEntryObj={
    serviceName: "Market Entry Strategy",
    icon: "/Images/selfService/MarketEntryImages/MarketEnrty_icon.png",

    heroSection: {
        image: "/Images/selfService/MarketEntryImages/Market-Entry_HeroImg.png",
        message: "Because the Right Startegy is key",
        heading: "Minimize Risk: Plan Your New ",
        headingPortion: "Market Entry Strategy",
        highlightedText: "Entering a new market without preparation can lead to failure.",
        content:
          "Crafting a solid entry strategy ensures you understand local dynamics, competition, and customer needs before investing time and resources.",
      },

      aboutService: {
        content1:
          "Our Market Entry Strategy service is designed to help startups and new businesses establish themselves in competitive markets.",
        content2:
          "By understanding the market landscape, overcoming entry barriers, and positioning your business effectively, we ensure you can confidently navigate your entry into new markets.",
      },
      features: [
        {
          icon: "/Images/selfService/MarketEntryImages/MarketEntryFeatures1/Agile.png",
          title: "Market Landscape Analysis",
          description:
            "A comprehensive analysis of the current market conditions, competition, and opportunities.",
        },
        {
          icon: "/Images/selfService/MarketEntryImages/MarketEntryFeatures1/Vip.png",
          title: "Entry Barriers Identification",
          description:
            "We identify the potential barriers to entry and provide strategies to overcome them..",
        },
        {
            icon: "/Images/selfService/MarketEntryImages/MarketEntryFeatures1/hugeicons_resources-add.png",
            title: "Market Positioning Guidance",
            description:
              "Help in defining how to position your business in the market to stand out from competitors.",
          },
        {
          icon: "/Images/selfService/MarketEntryImages/MarketEntryFeatures1/Regulatory.png",
          title: "Regulatory and Compliance Assistance",
          description:
            "Guidance on navigating legal, regulatory, and compliance issues specific to your industry.",
        },
        {
          icon: "/Images/selfService/MarketEntryImages/MarketEntryFeatures1/Competition.png",
          title: "Competitive Edge Strategies",
          description:
            "Insightful strategies to leverage your strengths and differentiate your business in a crowded market.",
        },
        {
          icon: "/Images/selfService/MarketEntryImages/MarketEntryFeatures1/Partnership handshake.png",
          title: "Partnership & Distribution Channels",
          description:
            "Recommendations for potential partnerships and distribution channels that can help penetrate the market faster.",
        },
        
      ],

      payemnetSummary: {
        totalPayment: "5049",
        gst: "908",
        Payable: "5958",
      },

      benefits: {
        title: "Benefits of Market Entry Strategy",
        data: [
          {
            title: "Faster Market Penetration",
            description:
              "Identify the quickest routes to enter the market effectively.",
            sno: "01",
          },
          {
            title: "Informed Decision Making",
            description:
              "Make data-driven, strategic decisions with our expert guidance.",
            sno: "02",
          },
          {
            title: "Regulatory Clarity",
            description:
              "Ensure compliance with local laws and regulations.",
            sno: "03",
          },
          {
            title: "Strategic Partnerships",
            description: "Shape your idea to better meet market demand.",
            sno: "04",
          },
          {
            title: "Competitive Advantage",
            description: "Identify ways to position your business against competitors.",
            sno: "05",
          },
          {
            title: "Minimize Risk",
            description: "Enter the market with a clear understanding of barriers and how to overcome them.",
            sno: "06",
          },
        ],
      },
      featuresOverview: [
        {
          title: "Market research & trend analysis",
          icon: "/Images/selfService/MarketEntryImages/MarketEntryFeatures1/Agile.png",
        },
        {
          title: "Identification of entry barriers",
          icon: "/Images/selfService/MarketEntryImages/MarketEntryFeatures1/Vip.png",
        },
        {
          title: "Competitive landscape assessment",
          icon: "/Images/selfService/MarketEntryImages/MarketEntryFeatures1/Competition.png",
        },
        {
          title: "Legal & compliance advisory",
          icon: "/Images/selfService/MarketEntryImages/MarketEntryFeatures2/Legal document.png",
        },
        {
          title: "Distribution channel recommendations",
          icon: "/Images/selfService/MarketEntryImages/MarketEntryFeatures1/hugeicons_resources-add.png",
        },
        {
          title: "Compliance & legal guidance",
          icon: "/Images/selfService/MarketEntryImages/MarketEntryFeatures2/Channel.png",
        },
        {
          title: "Strategic partnership insights",
          icon: "/Images/selfService/MarketEntryImages/MarketEntryFeatures1/Partnership handshake.png",
        },
        {
          title: "Local market understanding",
          icon: "/Images/selfService/MarketEntryImages/MarketEntryFeatures2/Designation.png",
        },
        {
          title: "Risk mitigation plans",
          icon: "/Images/selfService/MarketEntryImages/MarketEntryFeatures2/Risk management.png",
        },
        {
          title: "Actionable entry plan report",
          icon: "/Images/selfService/MarketEntryImages/MarketEntryFeatures2/fluent_channel-24-regular.png",
        },
      ],
      howItWorks: [
        {
          bg:"/Images/selfService/IdeaValidationImages/IdeaValWorks/fg1.png",
          fg:"/Images/selfService/howItWorks/InitialConsultation.png",
          step: "Initial Consultation",
          description:
            "What Happens: We discuss your ideas, goals, and define the key objetives of validation.",
        },
        {
          bg:"/Images/selfService/IdeaValidationImages/IdeaValWorks/fg2.png",
          // fg:"/Images/selfService/IdeaValidationImages/IdeaValWorks/bg2.png",
          fg:"/Images/selfService/howItWorks/MarketResearch.png",
          step: "Market Analysis",
          description:
            "Our team conducts a detailed analysis of the market, identifying key trends and opportunities.",
        },
        {
          bg:"/Images/selfService/IdeaValidationImages/IdeaValWorks/fg3.png",
          fg:"/Images/selfService/howItWorks/DataAnalysis.png",
          step: "Strategic Plan Development",
          description:
            "We create a customized market entry strategy with actionable steps.",
        },
        {
          bg:"/Images/selfService/IdeaValidationImages/IdeaValWorks/fg4.png",
          fg:"/Images/selfService/howItWorks/Results.png",
          step: "Implementation Guidance",
          description:
            "We provide ongoing support and guidance as you begin to execute your market entry plan.",
        },
      ],

      targetAudience: [
        {
          icon: "/Images/servicePage/targeticon1.png",
          iconMobile: "/Images/servicePage/targeticonMobile1.png",
          title: "Aspiring Entrepreneurs",
          content1: "You're likely facing uncertainity and fear of failure.",
          content2:
          "Our service helps you validate your idea, ensuring it meets a real market need,so you can avoid common startup mistakes.",
        },
        {
          icon: "/Images/servicePage/targeticon2.png",
          iconMobile: "/Images/servicePage/targeticonMobile2.png",
          title: "Small Business",
          content1: "With limited resources and high stakes, you need to prove your concept.",
          content2:
          "Our service helps you validate market demand, gain traction, and improve your chances of securing funding.",
        },
        {
          icon: "/Images/servicePage/intCompanies.png",
          iconMobile: "/Images/servicePage/intCompaniesmobile.png",
          title: "International Companies",
          content1:
          "Expanding into new markets can be risky.",
          content2:
          "Our service lets you test new products or services with different demographics, reducing the uncertainity and risk of market expansion.",
        },
        {
          icon: "/Images/servicePage/targeticon4.png",
          iconMobile: "/Images/servicePage/targeticonMobile4.png",
          title: "Product Managers",
          content1: "You're under pressure to innovate with minimal risk.",
          content2:
            "Use our service to validate new ideas or features, making sure they align with customer needs before investing resources.",
        },
      ],
      faq: [
        {
          que: "How long does it take to create a market entry strategy?",
          ans: "Our process typically takes 2-4 weeks depending on the market complexity.",
        },
        {
          que: "Do i need this if I already have a product ready?",
          ans: "Yes, having a great product is only one part; a strong entry strategy ensures success",
        },
        {
          que: "What markets do you specialize in?",
          ans: "We specialize in various industries, including tech, retail and manufacturing, in both domestic and international markets.",
        },
        {
          que: "Can you help with local regulations?",
          ans: "Yes, we provide guidance on regulatory and compliance issues to help you navigate the legal aspects.",
        },
        
      ],
};