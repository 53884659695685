import React from "react";
import benefits_1 from "../assets/card_bg1.png";
import benefits_2 from "../assets/card_bg2.png";
import benefits_3 from "../assets/card_bg3.png";
import benefits_4 from "../assets/card_bg4.png";
import benefits_5 from "../assets/card_bg5.png";
import benefits_6 from "../assets/card_bg6.png";
import { useMediaQuery } from "@mui/material";
import Slider from "react-slick";
// Import Slick CSS
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

const Benefits = ({ benefits }) => {
  const { title, data } = benefits;
  const isMobile = useMediaQuery("(max-width:640px)");
  const isTab = useMediaQuery("(max-width:900px)");

  const benefitsImages = [benefits_1, benefits_2, benefits_3, benefits_4, benefits_5, benefits_6];

  return (
    <div className="flex flex-col mt-12 md:mt-14 !items-start w-[100%] md:w-[68%]">
      <h3 className="font-cabin font-[500] text-xl md:text-3xl">{title}</h3>
      <div className="w-[100%] h-[1px] bg-[#263238] opacity-20 mt-4 mb-4"></div>

      <div
        className="w-full gap-2 h-max overflow-x-scroll scroll-smooth"
        style={{ display: isMobile ? "flex" : "none"}}
      >
        {data.map((data, index) => {
          return (
            <div className="flex flex-col h-[220px] w-[220px] justify-center relative " key={index} style={{backgroundImage:`url(${benefitsImages[index]})`}}>
              <div className="flex flex-col h-[60%] justify-center w-[220px] p-2">
              <p
                style={{
                  fontWeight: "550",
                  width: "100%",
                
                  
                }}
              >
                {data.title}
              </p>
              <p
                style={{
                  fontWeight: "500",
                  width: "96%",
                  fontSize: "10px",
                }}
              >
                {data.description}
              </p>

              </div>
            
              <p className="flex justify-center items-center"
                style={{
                  position: "relative",
                  zIndex: "2",
                  bottom: "4px",
                //  alignItems:"center",
                  fontSize: "18px",
                  

                }}
              >
                {data.sno}
              </p>
            </div>
         
          );
        })}
      </div>

      {/* Desktop view */}
      <div
        className="sm:flex hidden gap-4 flex-wrap w-[750px]"
        style={{ width: isTab ? "550px" : "750px" }}
      >
        {/* Benefit cards */}
        {data.map((data, index) => {
          return (
            
            <div className="flex flex-col h-[220px] w-[220px] justify-center" key={index} style={{backgroundImage:`url(${benefitsImages[index]})`}}>
            <div className="flex flex-col h-[60%] justify-center w-[90%]">
            <p
              style={{
                fontWeight: "550",
                width: "200px",
              }}
            >
              {data.title}
            </p>
            <p
              style={{
                fontWeight: "500",
                width: "200px",
                fontSize: "10px",
              }}
            >
              {data.description}
            </p>

              
              
              <p
                style={{
                  fontSize: "25px",
                }}
              >
                {/* 0{index + 1} */}
              </p>
            </div>
            
            <p
              style={{
                fontSize: "25px",
              }}
            >
              0{index + 1}
            </p>
          </div>
          );
        })}
      </div>
    </div>
    
  );
};

export default Benefits;
