import React from "react";
import Red_tick from './assets/Red_tick.png';
import PaymentFailed from './assets/PaymentFailed.png';
import Blue_tick from './assets/Blue_tick.png';
import Call_icon from './assets/Call_icon.png';
import { useMediaQuery } from "@mui/material";
function PaymentFailureComponent(){

    let isTab=useMediaQuery('(max-width:1200px)');
    let isSmallMobile=useMediaQuery('(max-width:550px)');
    let isMobile=useMediaQuery('(max-width:720px)');

    return(
        <div className="flex flex-col items-center bg-white shadow-lg h-max w-[80%] font-cabin rounded-lg" style={{width:(isMobile&&"90%"||"80%"),paddingTop:isMobile?"20px":"20px",paddingBottom:isMobile?"40px":"60px"}}>
            {/* Component Heading */}
            <div className="flex h-max w-full items-center">
                <img src={Red_tick} alt="red tick" style={{height:isMobile&&"30px",width:isMobile&&"30px",marginRight:isMobile&&"8px"||"16px",marginLeft:isMobile?"20px":"40px"}}/>
                <p className="text-[#FF481F] font-bold" style={{fontSize:isMobile&&"18px"||"30px"}}>Payment Failed</p>
            </div>
            {/* Main Image */}
            <div className="flex justify-center w-full h-max">
                <img src={PaymentFailed} className="mb-2" style={{width:isMobile&&"60%"||"45%"}}/>
            </div>
                
            {/* Big heading */}
            <p className="text-[#FF481F] mb-2 text-center" style={{fontSize:isMobile&&"18px"||"30px",fontWeight:isMobile&&"600"||"700",width:isMobile&&"80%"||"100%"}}>Oops ! We could not process your payment.</p>
            {/* Description */}
            <div className="flex" style={{width:isMobile&&"80%"||"45%"}}>
                <p className="font-medium text-center mb-4 text-[#1B2559]" style={{fontSize:isMobile&&"9px"||"15px"}}>We’re sorry, but your payment couldn’t be completed. This could have happened due to one of the following reasons:</p>
            </div>
            {/* Reasons of payment failure */}
            <div className="flex flex-col mb-6 text-[#263238]" style={{width:isMobile?"60%":"550px"}}>

        <div className="flex h-auto w-full" style={{flexDirection:isMobile?"column":"row",alignItems:isMobile?"start":"",marginBottom:isMobile?"2px":"8px"}}>
            <div className="flex items-center w-max" style={{fontSize:isMobile?"7px":"13px",fontWeight:"500",marginRight:isMobile?"0px":"200px",marginBottom:isMobile?"2px":"0px"}}>
                <img src={Blue_tick} style={{height:isMobile?"10px":"",width:isMobile?"10px":"",marginRight:isMobile?"2px":"10px"}}/>
                <p>Payment gateway timeout</p>
            </div>

            <div className="flex items-center w-max" style={{fontSize:isMobile?"7px":"13px",fontWeight:"500"}}>
                <img src={Blue_tick} style={{height:isMobile?"10px":"",width:isMobile?"10px":"",marginRight:isMobile?"2px":"10px"}}/>
                <p>Insufficient funds</p>
            </div>
        </div>

        <div className="flex h-auto w-full" style={{flexDirection:isMobile?"column":"row",alignItems:isMobile?"start":"",marginBottom:isMobile?"2px":"8px"}}>
            <div className="flex items-center w-max" style={{fontSize:isMobile?"7px":"13px",fontWeight:"500",marginRight:isMobile?"0px":"95px",marginBottom:isMobile?"2px":"0px"}}>
                <img src={Blue_tick} style={{height:isMobile?"10px":"",width:isMobile?"10px":"",marginRight:isMobile?"2px":"10px"}}/>
                <p>Clicked the back button or refreshed the page</p>
            </div>

            <div className="flex items-center w-max" style={{fontSize:isMobile?"7px":"13px",fontWeight:"500"}}>
                <img src={Blue_tick} style={{height:isMobile?"10px":"",width:isMobile?"10px":"",marginRight:isMobile?"2px":"10px"}}/>
                <p>Incorrect card details</p>
            </div>
        </div>

        <div className="flex h-auto w-full" style={{flexDirection:isMobile?"column":"row",alignItems:isMobile?"start":"",marginBottom:isMobile?"2px":"8px"}}>
            <div className="flex items-center w-max" style={{fontSize:isMobile?"7px":"13px",fontWeight:"500",marginRight:isMobile?"0px":"258px",marginBottom:isMobile?"2px":"0px"}}>
                <img src={Blue_tick} style={{height:isMobile?"10px":"",width:isMobile?"10px":"",marginRight:isMobile?"2px":"10px"}}/>
                <p>Technical issues</p>
            </div>

            <div className="flex items-center w-max" style={{fontSize:isMobile?"7px":"13px",fontWeight:"500"}}>
                <img src={Blue_tick} style={{height:isMobile?"10px":"",width:isMobile?"10px":"",marginRight:isMobile?"2px":"10px"}}/>
                <p>Card expiration</p>
            </div>
        </div>

        <div className="flex h-auto w-full" style={{flexDirection:isMobile?"column":"row",alignItems:isMobile?"start":"",marginBottom:isMobile?"2px":"8px"}}>
            <div className="flex items-center w-max" style={{fontSize:isMobile?"7px":"13px",fontWeight:"500"}}>
                <img src={Blue_tick} style={{height:isMobile?"10px":"",width:isMobile?"10px":"",marginRight:isMobile?"2px":"10px"}}/>
                <p>Bank restrictions</p>
            </div>
        </div>
    </div>
            

            {/* buttons div */}
            <div className="flex justify-center w-full h-max mb-5">
                    <div className="flex justify-between w-[30%] h-max" style={{width:isMobile&&"200px"||isTab&&"50%"||"30%"}}>
                        <button className="h-max w-max bg-[#1C6ED0] rounded-md hover:scale-105" style={{border:"1px solid #1C6ED0",padding:isMobile&&"5px 15px 5px 15px"||"5px 30px 5px 30px"}}>
                        <p className="text-white" style={{fontSize:isMobile&&"10px"||"18px"}}>Retry</p>
                        </button>

                        <button className="h-max w-max rounded-md hover:scale-105" style={{border:"1px solid #1C6ED0",padding:isMobile&&"5px 15px 5px 15px"||"5px 30px 5px 30px"}}>
                        <p className="text-[#1C6ED0]" style={{fontSize:isMobile&&"10px"||"18px"}}>Home</p>
                        </button>
                    </div>
                </div>

            {/* Need help card */}
            <div className="flex w-[85%] bg-[#F7FCFB] shadow-lg rounded-lg" style={{width:isMobile&&"90%"||"85%",justifyContent:"space-between",padding:isMobile&&"10px"||isTab&&"10px 40px 10px 20px"||"20px 25px 20px 40px"}}>
                <div className="flex flex-col h-max" style={{width:isMobile?"150px":"450px"}}>
                    <p className="font-bold self-start text-[#1B2559]" style={{fontSize:isMobile&&"16px"||isTab&&"18px"||"25px"}}>Need Our help?</p>
                    <p className="font-semibold self-start text-left text-[#1B2559]" style={{fontSize:isMobile&&"9px"||isTab&&"12px"||"15px"}}>Call us in case you faced any issue in our service or have any doubt.</p>
                </div>
            {/* Phone number */}
                <div className="flex items-center h-max w-max">
                    <img src={Call_icon} className="mt-2" style={{height:isMobile&&"20px"||"",width:isMobile&&"20px"||""}}/>
                    <p className="font-bold text-[#1B2559]" style={{fontSize:isMobile&&"12px"||isTab&&"16px"||"30px"}}>+919354651433</p>
                </div>
            </div>
            
            
        </div>
    )
}

export default PaymentFailureComponent;