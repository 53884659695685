export const CompetitorAnalysisObj={
    serviceName: "Competitor Analysis Service",
    icon: "/Images/selfService/CompAnalysis/competitor-analysis-icon.png",

    heroSection: {
        image: "/Images/selfService/CompAnalysis/Competitor-Analysis-HeroImg.png",
        message: "Stay Ahead of the Market",
        heading: "Gain a Competitive ",
        headingPortion: "Edge with Competitor Analysis",
        highlightedText: "Our Competitor Analysis service helps you uncover",
        content:
          "strategic insights to refine your approach and outperform competitors.",
      },

      aboutService: {
        content1:
          "Our Competitor Analysis service helps you understand your competition and market position better. ",
        content2:
          "We provide a comprehensive review of your competitors, analyze their strategies, and offer actionable insights that will help you stand out in a competitive market.",
      },

      features: [
        {
          icon: "/Images/selfService/CompAnalysis/details/det1.png",
          title: "Competitive Landscape Overview",
          description:
            "Analyze the market to identify your key competitors and industry trends.",
        },
        {
          icon: "/Images/selfService/CompAnalysis/details/det4.png",
          title: "Competitor Strengths & Weaknesses",
          description:
            "Understand competitors' strengths, weaknesses, opportunities, and threats.",
        },
        {
            icon: "/Images/selfService/CompAnalysis/details/det2.png",
            title: "Market Positioning",
            description:
              "Evaluate how your business stands in comparison to competitors.",
          },
        {
          icon: "/Images/selfService/CompAnalysis/details/det5.png",
          title: "Competitor Strategy Insights",
          description:
            "Analyze competitors' marketing, pricing, and sales strategies.",
        },
        {
          icon: "/Images/selfService/CompAnalysis/details/det3.png",
          title: "Product/Service Benchmarking",
          description:
            "Compare your offerings with those of competitors.",
        },
        {
          icon: "/Images/selfService/CompAnalysis/details/det6.png",
          title: "Future Competitor Trends",
          description:
            "Identify potential upcoming changes in the competitive landscape.",
        },
        
      ],

      payemnetSummary: {
        totalPayment: "5049",
        gst: "908",
        Payable: "5958",
      },

      benefits: {
        title: "Benefits of Competitor Analysis Service",
        data: [
          {
            title: "Strategic Insights",
            description:
              "Gain a deeper understanding of your competitors and their strategies.",
            sno: "01",
          },
          {
            title: "Better Positioning",
            description:
              "Optimize your business strategy to improve market positioning.",
            sno: "02",
          },
          {
            title: "Competitive Edge",
            description:
              "Identify opportunities to outperform competitors.",
            sno: "03",
          },
          {
            title: "Informed Decisions",
            description: "Make data-driven choices to stay ahead in the market.",
            sno: "04",
          },
          {
            title: "Improved Offerings",
            description: "Refine your product or service based on competitor benchmarking.",
            sno: "05",
          },
          {
            title: "Proactive Planning",
            description: "Anticipate future market trends and competitor moves.",
            sno: "06",
          },
        ],
      },







      featuresOverview: [
        {
          title: "Detailed Competitive Reports",
          icon: "/Images/selfService/CompAnalysis/features/ft1.png",
        },
        {
          title: "SWOT Analysis of Competitors",
          icon: "/Images/selfService/CompAnalysis/features/f6.png",
        },
        {
          title: "Market Positioning Analysis",
          icon:  "/Images/selfService/CompAnalysis/features/ft2.png",
         
        },
        {
          title: "Competitor Marketing & Sales Review",
          icon: "/Images/selfService/CompAnalysis/features/f7.png",
        },
        {
          title: "Product & Service Benchmarking",
          icon: "/Images/selfService/CompAnalysis/features/f3.png",
        },
        {
          title: "Competitive Pricing Insights",
          icon: "/Images/selfService/CompAnalysis/features/f8.png",
        },
        {
          title: "Industry Trend Analysis",
          icon: "/Images/selfService/CompAnalysis/features/f4.png",
        },
        {
          title: "Custom Reports & Insights",
          icon: "/Images/selfService/CompAnalysis/features/f9.png",
        },
        {
          title: "Ongoing Competitor Monitoring",
          icon: "/Images/selfService/CompAnalysis/features/f5.png",
        },
        {
          title: "Expert Consultation & Support",
          icon: "/Images/selfService/CompAnalysis/features/f10.png",
        },
      ],


      howItWorks: [
        {
          bg:"/Images/selfService/IdeaValidationImages/IdeaValWorks/fg1.png",
          fg:"/Images/selfService/howItWorks/InitialCOnsultation.png",
          step: "Initial Briefing",
          description:
            "Discuss your business and competitor focus areas.",
        },
        {
          bg:"/Images/selfService/IdeaValidationImages/IdeaValWorks/fg2.png",
          fg:"/Images/selfService/howItWorks/MarketResearch.png",
          step: "Market Research",
          description:
            "Conduct a comprehensive competitor analysis.",
        },
        {
        //   bg:"/Images/selfService/IdeaValidationImages/IdeaValWorks/fg3.png",
          fg:"/Images/selfService/howItWorks/ReportCreation.png",
          step: "Report Creation",
          description:
            "Generate a detailed competitor report with insights.",
        },
        {
          bg:"/Images/selfService/IdeaValidationImages/IdeaValWorks/fg4.png",
          fg:"/Images/selfService/howItWorks/StrategicReview2.png",
          step: "Strategic Review",
          description:
            "Review findings and optimize your strategy accordingly.",
        },
      ],

      targetAudience: [
        {
          icon: "/Images/servicePage/targeticon1.png",
          iconMobile: "/Images/servicePage/targeticonMobile1.png",
          title: "Aspiring Entrepreneurs",
          content1: "Understand competitors in your target market before launching.",
          content2:
          "",
        },
        {
          icon: "/Images/servicePage/targeticon2.png",
          iconMobile: "/Images/servicePage/targeticonMobile2.png",
          title: "Small Business Owners",
          content1: "Benchmark against competitors to improve your strategy.",
          content2:
          "",
        },
       
        
        {
            icon: "/Images/servicePage/targeticon3.png",
            iconMobile: "/Images/servicePage/targeticonMobile3.png",
          title: "Startups",
          content1: "Stay competitive in a crowded market with valuable insights. ",
          content2:
            "",
        },
        {
          icon: "/Images/servicePage/targeticon4.png",
          iconMobile: "/Images/selfService/BusinessConsultancyImages/prodManagerMobile.png",
          title: "Established Businesses",
          content1:
          "Keep track of competitors' moves and industry changes.",
          content2:
          "",
        },
      ],

      faq: [
        {
          que: "What is competitor analysis?",
          ans: "Competitor analysis involves reviewing competitors’ strategies, strengths, weaknesses, and market positioning to help improve your business decisions.",
        },
        {
          que: "Why is competitor analysis important?",
          ans: "It helps you identify where your business stands in the market and how you can gain a competitive advantage.",
        },
        {
          que: "How long does a competitor analysis take?",
          ans: "It typically takes 1-3 weeks depending on the depth of research required.",
        },
        {
          que: "Can this service help me improve my product?",
          ans: "Yes, by comparing your product or service with competitors, we identify areas of improvement.",
        },
        {
            que: "What industries do you support?",
            ans: "We provide competitor analysis for a wide range of industries, including retail, tech, and services.",
          },
          {
            que: "Do you offer ongoing monitoring of competitors?",
            ans: "Yes, we can continuously monitor your competitors and provide updates as needed.",
          },
        
      ],
};