import React, { useEffect, useRef, useState } from "react";
import logo from "./assets/logo.svg";
import image from "./assets/image.svg";
import talkExpert from "./assets/talkToExpert.svg";
import percentage from "./assets/percentage.svg";
import { Helmet } from "react-helmet";
import "../../Styles/pr.css";
import { useNavigate } from "react-router-dom";
import { useMediaQuery } from "@mui/material";
import ReactSlider from "react-slider";
import { IoIosArrowDown } from "react-icons/io";
import { addDoc, collection } from "firebase/firestore";
import { partnerDB, primaryDB } from "../../config/firebase";
import useFormData from "../Services/util/useFormData";

const FirstForm = () => {
  const { updateFormData } = useFormData();

  const [currStep, setCurrStep] = useState(1);
  // for budget slider

  const [errors, setErrors] = useState([]);
  const [formData, setFormData] = useState({
    priceRange: [0, 30000],
    targetAudience: [],
    network: [],
    language: [],
    industry: [],
    pressReleasePurpose: "",
    pressReleaseReady: "",
    deliveryTime: "",
    fullName: "",
    email: "",
    phone: "",
    website: "",
    business: "",
  });



  // Do not remove the below code as it might be used in the future for updating the new pr data
  // useEffect(() => {
  //   const sendData = async () => {
  //     try {
  //       const docRef = await addDoc(collection(partnerDB, "newPrData"), sendPRData);
  //       console.log('Data sent successfully:', docRef.id);
  //     } catch (error) {
  //       console.error('Error sending data:', error);
  //     }
  //   };
  //   sendData();
  // }, []);

  const navigate = useNavigate();

  const handleSubmit = async (event) => {
    event.preventDefault();
    const newErrors = [];

    try {
      // Validate purposeOfPr
      if (!formData.fullName) {
        newErrors.fullName = "Full name is required.";
      }
      // Validate time
      if (!formData.email) {
        newErrors.email = "Email is required.";
      }
      // language
      if (!formData.phone) {
        newErrors.phone = "Phone number is required.";
      } else if (formData.phone.length !== 10) {
        // Assuming a 10-digit phone number
        newErrors.phone = "Phone number must be 10 digits long.";
      }

      if (Object.keys(newErrors).length > 0) {
        setErrors(newErrors);
      } else {
        const docRef = await addDoc(collection(primaryDB, "prUsers"), formData);
        console.log("Document written with ID: ", docRef.id);
        updateFormData(formData);
        navigate("/services/press-release-distribution/quote-details");
      }
    } catch (error) {
      console.error("Error adding document: ", error);
    }
  };

  const handleNext = (event) => {
    event.preventDefault();

    const newErrors = [];

    // Validate purposeOfPr
    if (!formData.pressReleasePurpose) {
      newErrors.pressReleasePurpose = "Purpose of Press Release is required.";
    }
    // Validate time
    if (!formData.deliveryTime) {
      newErrors.deliveryTime = "Time is required.";
    }
    // language
    if (formData.language.length === 0) {
      newErrors.language = "At least one language is required.";
    }
    // If there are errors, update state and do not go forward
    if (Object.keys(newErrors).length > 0) {
      setErrors(newErrors);
    } else {
      // Clear errors if no validation issues
      setErrors([]);
      setCurrStep(2);
    }
  };

  console.log(errors);

  // Function to handle slider value change
  const handleSliderChange = (newRange) => {
    setFormData((prevData) => ({
      ...prevData,
      priceRange: newRange,
    }));
  };

  const handleCheckboxChange = (group, event) => {
    const { value, checked } = event.target;
    setFormData((prevData) => {
      const newSelections = checked
        ? [...prevData[group], value]
        : prevData[group].filter((item) => item !== value);

      return { ...prevData, [group]: newSelections };
    });
  };
  const handleFormData = (event) => {
    const { name, value } = event.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));

    console.log(formData);
  };

  const industries = [
    "Technology",
    "E-commerce",
    "Fintech",
    "IOT",
    "Artificial Intelligence",
    "Food and Beverage",
    "Health & Wellness",
    "Media & Entertainment",
    "Travel & Hospitality",
    "Education",
    "Real Estate",
    "Energy",
    "Agriculture",
    "B2B SaaS",
    "BioTech",
    "Space Tech",
    "Cyber Security",
    "Legal Tech",
    "Fashion & Apparel",
    "Beauty & Personal Care",
    "Automotive",
    "Retail",
    "Cleantech",
    "Deeptech",
    "Robotics",
    "Power",
    "VR/AR",
    "Other",
  ];

  const isMobile = useMediaQuery("(max-width: 768px)");
  const isTab = useMediaQuery("(min-width: 769px) and (max-width:1476px) ");
  const labelStyle = {
    width: !isMobile ? "400px" : "", // Set width to 400px if not mobile
    textAlign: "left",
    // gap:isMobile?"5px":""
  };

  const dropdownRef = useRef({});
  const buttonRef = useRef({});

  // Close the dropdown if a click is detected outside of it
  useEffect(() => {
    const handleClickOutside = (event) => {
      Object.keys(dropdownRef.current).forEach((key) => {
        if (
          dropdownRef.current[key] &&
          !dropdownRef.current[key].contains(event.target) &&
          !buttonRef.current[key].contains(event.target)
        ) {
          dropdownRef.current[key].classList.add("hidden");
        }
      });
    };

    document.addEventListener("click", handleClickOutside);

    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, []);

  // Toggle the dropdown when the button is clicked
  const toggleDropdown = (id) => {
    dropdownRef.current[id].classList.toggle("hidden");
  };

  return (
    <>
      <Helmet>
        <title>
          Press Release Distribution | Custom PR Plans for Your Needs
        </title>
        <meta
          name="description"
          content="Get your news noticed with our custom press release distribution service. Submit your details and view tailored PR plans designed to maximize your media coverage."
        />
        <meta
          name="keywords"
          content="press release distribution, custom PR plans, media coverage, PR distribution service"
        />
        <meta name="author" content="Bizowl" />
        <meta name="robots" content="index, follow" />
        <link
          rel="canonical"
          href="https://bizzowl.com/services/press-release-distribution"
        />
        <script type="application/ld+json">
          {`{
            "@context": "https://schema.org",
            "@type": "Service",
            serviceType: "Press Release Distribution",
            provider: {
              "@type": "Organization",
              name: "Bizowl",
              url: "https://www.bizzowl.com",
              logo: "https://www.bizzowl.com/logo.png",
              contactPoint: {
                "@type": "ContactPoint",
                telephone: "+91-935465143",
                contactType: "Customer Service",
                areaServed: "Global",
                availableLanguage: "English",
              },
            },
            description:
              "Get your news noticed with our custom press release distribution service. Submit your details and view tailored PR plans designed to maximize your media coverage.",
            areaServed: {
              "@type": "Place",
              name: "Global",
            },
            offers: {
              "@type": "Offer",
              url: "https://www.bizzowl.com/services/press-release-distribution",
              priceCurrency: "INR",
              price: "Variable",
              eligibleRegion: {
                "@type": "Place",
                name: "Global",
              },
              availability: "https://schema.org/InStock",
              validFrom: "2024-07-24",
            },
            aggregateRating: {
              "@type": "AggregateRating",
              ratingValue: "4.6",
              reviewCount: "17",
            },
          }`}
        </script>
      </Helmet>
      <div className="main">
        <div
          className="left-side"
          style={{
            display: isMobile ? "flex" : "",

            flexDirection: isMobile ? "column" : "",
            justifyContent: isMobile ? "start" : "",
            alignItems: isMobile ? "flex-start" : "", // Align items to the start (left) of the container
            padding: isMobile ? "0 30px" : "", // Optional: Add padding to avoid elements touching the container edges
            gap: isMobile ? "15px" : "",
          }}
        >
          <br />
          <br />
          <div
            className="logo"
            style={{
              display: isMobile ? "flex" : "",
              justifyContent: isMobile ? "space-between" : "flex-start", // Align items based on screen size
              // gap: isMobile ?"40px":"",
              width: isMobile ? "100%" : "", // Ensure the logo div takes up the full width for proper alignment
              marginTop: isMobile ? "-20px" : "",
            }}
          >
            <img
              src={logo}
              alt="logo"
              onClick={() => navigate("/")}
              style={{
                width: !isMobile && "35%",
              }}
            />
            <p style={{ textAlign: "left" }}>
              <img src={talkExpert} alt="" />
              <a
                href="tel:+919354651433"
                style={{ textDecoration: "none", color: "inherit" }}
              >
                Talk to an Expert ( +919354651433)
              </a>
            </p>
          </div>

          <div
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "start",
              alignItems: "start",
              gap: isMobile ? "8px" : "10px",
              margin: isMobile ? "10px auto 10px auto" : "20px auto",
            }}
          >
            <p style={{ textAlign: "left" }}>Public Relations</p>

            <p
              className="text-[25px]"
              style={{
                textAlign: "left",
                lineHeight: isMobile ? "1.2" : "1.2",
                fontSize: isMobile ? "20px" : "",
              }}
            >
              Get Your Press Release Perfected with{" "}
              <span className="text-[#1C6ED0] ">Best Deals and Expertise</span>
            </p>

            <ul
              className="flex list-disc gap-4 sm:gap-6 text-[#1C6ED0] text-[12px] justify-start"
              style={{ textAlign: "left", paddingInlineStart: "20px" }}
            >
              <li>Product Launch</li>
              <li>Events</li>
              <li>Fundraise Announcement</li>
            </ul>
          </div>

          <br />

          <img
            src={image}
            alt=""
            width={isMobile ? "70%" : "95%"}
            style={{
              display: "block",
              margin: isMobile ? "0 auto" : "10px  auto auto",
            }}
          />
        </div>
        <div className="md:w-[50%] md:absolute left-[50%]">
          <br />
          <br />
          <p
            className={`text-[#1B2559] hidden  text-[12px] font-bold md:flex items-center justify-end pr-2 ${
              isMobile ? "" : isTab ? "auto" : "w-[90%]"
            }`}
          >
            <img src={talkExpert} alt="" />
            <p>Talk to an Expert ( +919354651433)</p>
          </p>

          <div className="flex items-end justify-center text-[10px] mt-3">
            <br />
            <div className="flex flex-col items-center justify-center">
              <p>Press Release</p>
              <svg
                width="15"
                height="15"
                viewBox="0 0 25 25"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <circle cx="12.5" cy="12.5" r="12.5" fill="#1C6ED0" />
              </svg>
              <dot />
            </div>
            <div className="mt-3">
              <svg
                width="200"
                height="1"
                viewBox="0 0 200 1"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <line
                  y1="0.5"
                  x2="200"
                  y2="0.5"
                  stroke="#455A64"
                  stroke-opacity="0.75"
                />
              </svg>
            </div>

            <div className="flex flex-col items-center justify-center">
              <p>Contact Details</p>
              <svg
                width="15"
                height="15"
                viewBox="0 0 25 25"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <circle
                  cx="12.5"
                  cy="12.5"
                  r="12.5"
                  fill={currStep === 1 ? "#455A64" : "#1C6ED0"}
                />
              </svg>
            </div>
          </div>

          <br />
          <div
            className={`flex flex-col justify-center items-center w-[95%] md:w-[85%] lg:w-[65%] m-auto rounded-b-3xl form  ${
              isMobile ? "max-w-[375px]" : ""
            }`}
          >
            <p className="flex items-center bg-gradient-to-r from-[#1C6ED0] to-[#0E386A] text-white w-full justify-center rounded-t-3xl p-1 gap-2">
              <img src={percentage} alt="" />
              Compare and Get the Best PR Deals
            </p>
            <div className="flex flex-col justify-start">
              <p
                className="font-bold text-[18px] lg:text-[24px] "
                style={{
                  marginTop: isMobile ? "19px" : isTab ? "23px" : "10px",
                  marginBottom: isMobile ? "9px" : "11px",
                  marginLeft: isMobile ? "-48px" : isTab ? "1%" : "-90px",
                  fontWeight: !isMobile && "600",
                }}
              >
                {currStep == 1 ? (
                  <>
                    Share Your Story{" "}
                    <span className="text-[#1C6ED0]">with the World</span>
                  </>
                ) : (
                  <div
                    style={{
                      marginTop: isMobile ? "19px" : "23px",
                      marginBottom: isMobile ? "9px" : "11px",
                      marginLeft: isMobile ? "-48px" : isTab ? "" : "-40px",
                    }}
                  >
                    <p
                      className={`${
                        isMobile
                          ? ""
                          : isTab
                          ? "w-[320px] text-left"
                          : "w-[320px] text-left"
                      }`}
                    >
                      Enter Your Contact Details
                    </p>
                  </div>
                )}
              </p>
              <form
                action=""
                className="mainForm"
                style={{
                  marginLeft: isMobile ? "16px" : isTab ? "1%" : "-80px",
                  gap: isMobile ? "12px" : "25px",
                  width: isMobile ? "" : isTab ? "320px" : "375px",
                }}
              >
                {currStep === 1 ? (
                  <>
                    <label style={labelStyle} htmlFor="pressReleaseReady">
                      Do you have the press release ready?
                      <br />
                      <select
                        style={{
                          width: isMobile ? "" : isTab ? "300px" : "400px",
                        }}
                        id="pressReleaseReady"
                        name="pressReleaseReady"
                        defaultValue=""
                        value={formData.pressReleaseReady}
                        onChange={handleFormData}
                        className="bg-[#18141f05] w-[300px] text-[grey] h-[30px]"
                      >
                        <option disabled value="">
                          Select
                        </option>
                        <option value="Yes">Yes</option>
                        <option value="No">No</option>
                      </select>
                    </label>

                    <label
                      style={labelStyle}
                      className="formLabels"
                      htmlFor="pressReleasePurpose"
                    >
                      Purpose of Press Release
                      <br />
                      {errors.pressReleasePurpose && (
                        <div style={{ color: "red", zIndex: 10000 }}>
                          {errors.pressReleasePurpose}
                          <sup>*</sup>
                        </div>
                      )}
                      <select
                        style={{
                          width: isMobile ? "" : isTab ? "300px" : "400px",
                        }}
                        name="pressReleasePurpose"
                        defaultValue=""
                        value={formData.pressReleasePurpose}
                        onChange={handleFormData}
                        id="pressReleasePurpose"
                        className="bg-[#18141f05] w-[300px] text-[grey] h-[30px] "
                      >
                        <option disabled value="">
                          Select
                        </option>
                        <option value="productLaunch">Product Launch</option>
                        <option value="eventAnnouncement">
                          Event Announcement
                        </option>
                        <option value="companyNews">Company News</option>
                        <option value="partnershipAnnouncement">
                          Partnership Announcement
                        </option>
                        <option value="awardRecognition">
                          Award Recognition
                        </option>
                        <option value="other">Other</option>
                      </select>
                    </label>
                    <label className="block" htmlFor="budget">
                      <div
                        className="flex flex-col "
                        style={{
                          alignItems: "start",
                        }}
                      >
                        <span>Budget</span>
                        <br />
                        <div
                          className="relative "
                          style={{
                            width: isMobile ? "90%" : isTab ? "300px" : "400px",
                          }}
                        >
                          <ReactSlider
                            value={formData.priceRange}
                            onChange={handleSliderChange}
                            min={0}
                            max={30000}
                            step={500}
                            className="w-full h-2 bg-gray-300 rounded-full"
                            thumbClassName="w-4 h-4 bg-blue-500 rounded-full cursor-pointer -mt-[3px] "
                            trackClassName="bg-blue-500 rounded-full "
                            renderThumb={(props) => <div {...props} />} // Remove default value display
                          />
                          <div className="flex justify-between text-sm text-gray-600 mt-2">
                            <span>₹{formData.priceRange[0]}</span>
                            <span>₹{formData.priceRange[1]}</span>
                          </div>
                        </div>
                      </div>
                    </label>
                    {/* target audience */}
                    <div
                      style={{
                        position: "relative",
                        width: isMobile ? "90%" : isTab ? "300px" : "400px",
                      }}
                    >
                      <label htmlFor="targetAudience" classname="formLabels ">
                        Target Audience
                      </label>

                      <div
                        ref={(el) => (buttonRef.current["dropdown1"] = el)}
                        style={{
                          padding: "5px",
                          cursor: "pointer",
                          position: "relative",
                          background: "#18141f05",
                        }}
                        onClick={() => toggleDropdown("dropdown1")}
                      >
                        {formData.targetAudience.length > 0 ? (
                          formData.targetAudience.join(", ")
                        ) : (
                          <div className="flex justify-between text-[#808080]">
                            <p>Select Audience</p>
                            <p>
                              <IoIosArrowDown />
                            </p>
                          </div>
                        )}
                        <div
                          id="dropdown1"
                          ref={(el) => (dropdownRef.current["dropdown1"] = el)}
                          className="hidden "
                          style={{
                            position: "absolute",
                            background: "#fff",
                            border: "1px solid #ccc",
                            borderRadius: "5px",
                            width: "100%",
                            maxHeight: "150px",
                            overflowY: "auto",
                            zIndex: 1000,
                          }}
                        >
                          {["Local", "National", "International"].map(
                            (option) => (
                              <label
                                key={option}
                                style={{
                                  display: "flex",
                                  flexDirection: "row",
                                  justifyContent: "start",
                                  alignItems: "center",
                                  padding: "5px",
                                }}
                              >
                                <input
                                  type="checkbox"
                                  value={option}
                                  checked={formData.targetAudience.includes(
                                    option
                                  )}
                                  onChange={(e) =>
                                    handleCheckboxChange("targetAudience", e)
                                  }
                                />

                                {option.charAt(0).toUpperCase() +
                                  option.slice(1)}
                              </label>
                            )
                          )}
                        </div>
                      </div>
                    </div>

                    {/* network */}
                    <div
                      style={{
                        position: "relative",
                        width: isMobile ? "90%" : isTab ? "300px" : "400px",
                      }}
                    >
                      <label
                        htmlFor="network"
                        classname="formLabels "
                        style={{ textAlign: "" }}
                      >
                        Any Specific Distribution Network?(Select "Not sure" if
                        unsure)
                      </label>
                      <div
                        ref={(el) => (buttonRef.current["dropdown2"] = el)}
                        style={{
                          padding: "5px",
                          cursor: "pointer",
                          position: "relative",
                          background: "#18141f05",
                        }}
                        onClick={() => toggleDropdown("dropdown2")}
                      >
                        {formData.network.length > 0 ? (
                          formData.network.join(", ")
                        ) : (
                          <div className="flex justify-between text-[#808080]">
                            <p>Select Network</p>
                            <p>
                              <IoIosArrowDown />
                            </p>
                          </div>
                        )}
                        <div
                          id="dropdown2"
                          ref={(e1) => (dropdownRef.current["dropdown2"] = e1)}
                          className="hidden"
                          style={{
                            position: "absolute",
                            background: "#fff",
                            border: "1px solid #ccc",
                            borderRadius: "5px",
                            width: "100%",
                            maxHeight: "150px",
                            overflowY: "auto",
                            zIndex: 1000,
                          }}
                        >
                          {["IANS", "UNI", "PTI", "ANI", "NotSure"].map(
                            (option) => (
                              <label
                                key={option}
                                style={{
                                  display: "flex",
                                  flexDirection: "row",
                                  justifyContent: "start",
                                  alignItems: "center",
                                  padding: "5px",
                                }}
                              >
                                <input
                                  type="checkbox"
                                  value={option}
                                  checked={formData.network.includes(option)}
                                  onChange={(e) =>
                                    handleCheckboxChange("network", e)
                                  }
                                />
                                {option.charAt(0).toUpperCase() +
                                  option.slice(1)}
                              </label>
                            )
                          )}
                        </div>
                      </div>
                    </div>

                    {/* language */}

                    <div
                      style={{
                        position: "relative",
                        width: isMobile ? "90%" : isTab ? "300px" : "400px",
                      }}
                    >
                      <label htmlFor="language" classname="formLabels ">
                        Language
                      </label>
                      {errors.language && (
                        <div
                          style={{
                            color: "red",
                            display: "flex",
                            alignItems: "center",
                            zIndex: 10000,
                          }}
                        >
                          {errors.language}
                          <sup>*</sup>
                        </div>
                      )}
                      <div
                        ref={(el) => (buttonRef.current["dropdown3"] = el)}
                        style={{
                          padding: "5px",
                          cursor: "pointer",
                          position: "relative",
                          background: "#18141f05",
                        }}
                        onClick={() => toggleDropdown("dropdown3")}
                      >
                        {formData.language.length > 0 ? (
                          formData.language.join(", ")
                        ) : (
                          <div className="flex justify-between text-[#808080]">
                            <p>Select Language</p>
                            <p>
                              <IoIosArrowDown />
                            </p>
                          </div>
                        )}
                        <div
                          id="dropdown3"
                          ref={(el) => (dropdownRef.current["dropdown3"] = el)}
                          className="hidden"
                          style={{
                            position: "absolute",
                            background: "#fff",
                            border: "1px solid #ccc",
                            borderRadius: "5px",
                            width: "100%",
                            maxHeight: "150px",
                            overflowY: "auto",
                            zIndex: 1000,
                          }}
                          aria-required
                        >
                          {["Hindi", "English", "Other"].map((option) => (
                            <label
                              key={option}
                              style={{
                                display: "flex",
                                flexDirection: "row",
                                justifyContent: "start",
                                alignItems: "center",
                                padding: "5px",
                              }}
                            >
                              <input
                                type="checkbox"
                                value={option}
                                checked={formData.language.includes(option)}
                                onChange={(e) =>
                                  handleCheckboxChange("language", e)
                                }
                              />
                              {option.charAt(0).toUpperCase() + option.slice(1)}
                            </label>
                          ))}
                        </div>
                      </div>
                    </div>

                    {/* industry */}
                    <div
                      style={{
                        position: "relative",
                        width: isMobile ? "90%" : isTab ? "300px" : "400px",
                      }}
                    >
                      <label htmlFor="industry">Industry (optional)</label>
                      <div
                        ref={(el) => (buttonRef.current["dropdown4"] = el)}
                        style={{
                          padding: "5px",
                          cursor: "pointer",
                          position: "relative",
                          background: "#18141f05",
                        }}
                        onClick={() => toggleDropdown("dropdown4")}
                      >
                        {formData.industry.length > 0 ? (
                          formData.industry.join(", ")
                        ) : (
                          <div className="flex justify-between text-[#808080]">
                            <p>Select Industry</p>
                            <p>
                              <IoIosArrowDown />
                            </p>
                          </div>
                        )}
                        <div
                          id="dropdown4"
                          ref={(el) => (dropdownRef.current["dropdown4"] = el)}
                          className="hidden"
                          style={{
                            position: "absolute",
                            background: "#fff",
                            border: "1px solid #ccc",
                            borderRadius: "5px",
                            width: "100%",
                            maxHeight: "150px",
                            overflowY: "auto",
                            zIndex: 10001,
                          }}
                        >
                          {industries.map((option) => (
                            <label
                              key={option}
                              style={{
                                display: "flex",
                                flexDirection: "row",
                                justifyContent: "start",
                                alignItems: "center",
                                padding: "5px",
                              }}
                            >
                              <input
                                type="checkbox"
                                value={option}
                                checked={formData.industry.includes(option)}
                                onChange={(e) =>
                                  handleCheckboxChange("industry", e)
                                }
                              />
                              {option.charAt(0).toUpperCase() + option.slice(1)}
                            </label>
                          ))}
                        </div>
                      </div>
                    </div>

                    {/* <label style={labelStyle} classname="formLabels" htmlFor="industry">
                    Industry
                    <br />
                    
                  
                   <select multiple 
                    value={formData.industry}
                    onChange={handleCheckboxChange}
                    style={{
         
  
          cursor: 'pointer',
          width: '100%', // Full width of parent container
          height: '100px', // Adjust height as needed
          overflowY: 'auto' // Scrollable if options exceed the height
        }}
                      id="industry"
                     
                    >

                    {
                      industries.map((industry,index)=>(
                        <option value={industry} key={index}>{industry}</option>
                      ))
                    }

                      
                    </select>
                  </label> */}

                    <label
                      style={labelStyle}
                      classname="formLabels"
                      htmlFor="deliveryTime"
                    >
                      Delivery Time
                      <br />
                      {errors.deliveryTime && (
                        <div
                          style={{
                            color: "red",
                            display: "flex",
                            alignItems: "center",
                            zIndex: 1000,
                          }}
                        >
                          {errors.deliveryTime}
                          <sup>*</sup>
                        </div>
                      )}
                      <select
                        style={{
                          width: isMobile ? "" : isTab ? "300px" : "400px",
                        }}
                        id="deliveryTime"
                        name="deliveryTime"
                        value={formData.deliveryTime}
                        onChange={handleFormData}
                        defaultValue=""
                        className="bg-[#18141f05] w-[300px] text-[grey] h-[30px]"
                      >
                        <option disabled value="">
                          Select
                        </option>
                        <option value="sameDay">Same day</option>
                        {/* <option value="1Day">1 day</option> */}
                        <option value="2 Days">2 days</option>
                        <option value="3 Days">3 days</option>
                        <option value="4 Days">4 days</option>
                        <option value="5 days">5+ days</option>
                      </select>
                    </label>
                    <button
                      className="flex bg-[#1C6ED0] w-fit text-white px-7 py-1 rounded-sm"
                      onClick={handleNext}
                    >
                      Next
                    </button>
                    <br />
                  </>
                ) : (
                  <>
                    <label style={labelStyle}>
                      First Name
                      <br />
                      {errors.fullName && (
                        <div
                          style={{
                            color: "red",
                            display: "flex",
                            alignItems: "center",
                            zIndex: 10000,
                          }}
                        >
                          {errors.fullName}
                          <sup>*</sup>
                        </div>
                      )}
                      <input
                        type="text"
                        name="fullName"
                        value={formData.fullName}
                        onChange={handleFormData}
                        style={{
                          width: isMobile ? "" : isTab ? "300px" : "400px",
                        }}
                        className="bg-[#18141f05] w-[300px] text-[grey] h-[30px]"
                        placeholder="Enter your first name here"
                      />
                    </label>
                    <label style={labelStyle}>
                      Business Name
                      <br />
                      <input
                        type="text"
                        name="business"
                        value={formData.business}
                        onChange={handleFormData}
                        style={{
                          width: isMobile ? "" : isTab ? "300px" : "400px",
                        }}
                        className="bg-[#18141f05] w-[300px] text-[grey] h-[30px]"
                        placeholder="Company Name"
                      />
                    </label>
                    <label style={labelStyle}>
                      Email
                      <br />
                      {errors.email && (
                        <div
                          style={{
                            color: "red",
                            display: "flex",
                            alignItems: "center",
                            zIndex: 10000,
                          }}
                        >
                          {errors.email}
                          <sup>*</sup>
                        </div>
                      )}
                      <input
                        type="email"
                        style={{
                          width: isMobile ? "" : isTab ? "300px" : "400px",
                        }}
                        name="email"
                        value={formData.email}
                        onChange={handleFormData}
                        className="bg-[#18141f05] w-[300px] text-[grey] h-[30px]"
                        placeholder="Enter your email here"
                      />
                    </label>
                    <label style={labelStyle}>
                      Phone Number
                      <br />
                      {errors.phone && (
                        <div
                          style={{
                            color: "red",
                            display: "flex",
                            alignItems: "center",
                            zIndex: 10000,
                          }}
                        >
                          {errors.phone}
                          <sup>*</sup>
                        </div>
                      )}
                      <input
                        type="text"
                        value={formData.phone}
                        name="phone"
                        onChange={handleFormData}
                        style={{
                          width: isMobile ? "" : isTab ? "300px" : "400px",
                        }}
                        className="bg-[#18141f05] w-[300px] text-[grey] h-[30px]"
                        placeholder="Enter your phone number here"
                      />
                    </label>
                    <label style={labelStyle}>
                      Website
                      <br />
                      <input
                        type="text"
                        value={formData.website}
                        name="website"
                        onChange={handleFormData}
                        style={{
                          width: isMobile ? "" : isTab ? "300px" : "400px",
                        }}
                        className="bg-[#18141f05] w-[300px] text-[grey] h-[30px]"
                        placeholder="www.bizzowl.com"
                      />
                    </label>
                    <button
                      className="flex bg-[#1C6ED0] w-fit text-white px-7 py-1 rounded-sm"
                      onClick={handleSubmit}
                    >
                      View plans
                    </button>
                    <br />
                  </>
                )}
              </form>
            </div>
          </div>

          <br />
          <br />
          {currStep !== 1 && (
            <div className="mb-10 w-[60%] mx-auto">
              <p>
                By clicking on "View Plans", you agree to our
                <span className="text-[#1C6ED0]"> Privacy Policy</span>  and 
                <span className="text-[#1C6ED0]">Terms of Use</span>
              </p>
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default FirstForm;
