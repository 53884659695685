export const ProductDevelopmentObj = {
  serviceName: "Product Development",
  icon: "/Images/selfService/ProductDevelopmentImages/product_design_1.png",
  heroSection: {
    image: "/Images/selfService/ProductDevelopmentImages/ProductDevelopmentImg.png",
    message: "Your Roadmap to Success",
    heading: "Accelerate Growth with ",
    headingPortion: "Product Development Service",
    highlightedText: "Our comprehensive Product Development Service helps you take your product",
    content:
      "from concept to market launch. We offer design, development, testing, and strategy consultation to ensure your product's success. Build smarter, launch faster, and scale confidently.",
  },
  aboutService: {
    content1:
      "Our Product Development Service is designed for startups, growing businesses, and entrepreneurs who need support in creating full-fledged, market-ready products.",
    content2:
      "We provide everything from product design and development to ongoing consulting and support, ensuring your product aligns with both market demands and business goals.",
  },
  features: [
    {
      icon: "/Images/selfService/ProductDevelopmentImages/Details/Graphic_designer.png",
      title: "Comprehensive Product Design",
      description:
        "We focus on creating a robust product design tailored to market needs, ensuring functionality and aesthetic appeal.",
    },
    {
      icon: "/Images/selfService/ProductDevelopmentImages/Details/Web_development.png",
      title: "End-to-End Development",
      description:
        "From initial concept to market-ready product, we manage the entire product lifecycle, offering full development support.",
    },
    {
      icon: "/Images/selfService/ProductDevelopmentImages/Details/Choice.png",
      title: "User Experience Optimization",
      description:
        "Design and develop a product that is intuitive, user-friendly, and aligned with customer pain points.",
    },
    {
      icon: "/Images/selfService/ProductDevelopmentImages/Details/Agile.png",
      title: "Agile Development Process",
      description:
        "Our flexible, iterative approach allows for adjustments during the development phase, ensuring the final product meets your goals.",
    },
    {
      icon: "/Images/selfService/ProductDevelopmentImages/Details/Robotic_hand.png",
      title: "Technology Stack Consultation",
      description:
        "Get expert advice on choosing the right tech stack for your product to ensure efficiency, scalability, and innovation.",
    },
    {
      icon: "/Images/selfService/ProductDevelopmentImages/Details/Software_testing.png",
      title: "Product Testing & Validation",
      description:
        "We rigorously test your product to validate it in real market conditions, minimizing risks before the final launch.",
    },
  ],
  payemnetSummary: {
    totalPayment: "5211",
    gst: "937",
    Payable: "6149",
  },
  benefits: {
    title: "Benefits of the Service",
    data: [
      {
        title: "Complete Product Solution",
        description:
          "From concept to market launch, we handle every aspect of your product's development.",
        sno: "01",
      },
      {
        title: "User-Centric Design",
        description:
          "Develop products that solve real problems, designed with user experience in mind.",
        sno: "02",
      },
      {
        title: "Agile Development",
        description:
          "Adapt your product as needed during the process, ensuring flexibility and reduced risks.",
        sno: "03",
      },
      {
        title: "Strategic Product Roadmap",
        description: "Build a clear roadmap that ensures your product development stays on track and meets business objectives.",
        sno: "04",
      },
      {
        title: "Faster Time-to-Market",
        description: "Efficient, streamlined development process to launch your product quickly and strategically.",
        sno: "05",
      },
      {
        title: "Future Scalability",
        description: "Develop with growth in mind, ensuring your product can expand as your business does.",
        sno: "06",
      },
    ],
  },
  featuresOverview: [
    {
      title: "Comprehensive Product Design",
      icon: "/Images/selfService/ProductDevelopmentImages/Features/Graphic_designer.png",
    },
    {
      title: "End-to-End Product Development",
      icon: "/Images/selfService/ProductDevelopmentImages/Features/Web_development.png",
    },
    {
      title: "User Experience Optimization",
      icon: "/Images/selfService/ProductDevelopmentImages/Features/Choice.png",
    },
    {
      title: "Agile & Iterative Development",
      icon: "/Images/selfService/ProductDevelopmentImages/Features/Agile.png",
    },
    {
      title: "Technology Stack Consultation",
      icon: "/Images/selfService/ProductDevelopmentImages/Features/Robotic_ hand.png",
    },
    {
      title: "Market Validation & Testing",
      icon: "/Images/selfService/ProductDevelopmentImages/Features/Accept.png",
    },
    {
      title: "Customized Product Roadmap",
      icon: "/Images/selfService/ProductDevelopmentImages/Features/Product.png",
    },
    {
      title: "Scalability Strategy",
      icon: "/Images/selfService/ProductDevelopmentImages/Features/Business_strategy.png",
    },
    {
      title: "Product Maintenance & Support",
      icon: "/Images/selfService/ProductDevelopmentImages/Features/Web_maintenance.png",
    },
    {
      title: "Consulting for Product Strategy",
      icon: "/Images/selfService/ProductDevelopmentImages/Features/Doctor.png",
    },
  ],

  howItWorks: [
    {
      bg:"/Images/selfService/BusinessCanvasImages/HowItWorks/fg1.png",
      fg:"/Images/selfService/howItWorks/InitialConsultation.png",
      step: "Initial Consultation",
      description:
        "We start with a detailed discussion of your product idea and business goals.",
    },
    {
      bg:"/Images/selfService/BusinessCanvasImages/HowItWorks/fg2.png",
      fg:"/Images/selfService/ProductDevelopmentImages/HowItWorks/ProductStrategy.png",
      step: "Product Strategy & Roadmap",
      description:
        "Develop a custom product roadmap aligned with your business strategy",
    },
    {
      bg:"/Images/selfService/BusinessCanvasImages/HowItWorks/fg3.png",
      fg:"/Images/selfService/ProductDevelopmentImages/HowItWorks/Design&Development.png",
      step: "Design & Development",
      description:
        "Move through design, development, and iteration cycles to bring your product to life.",
    },
    {
      bg:"/Images/selfService/BusinessCanvasImages/HowItWorks/fg4.png",
      fg:"/Images/selfService/BusinessCanvasImages/HowItWorks/bg4.png",
      step: "Launch & Ongoing Support",
      description:
        "Successfully launch your product with post-launch maintenance and support.",
    },
  ],
  targetAudience: [
    {
      icon: "/Images/selfService/BusinessCanvasImages/WhoShouldUseService/targeticon1.png",
      iconMobile: "/Images/selfService/BusinessCanvasImages/WhoShouldUseService/targeticonMobile1.png",
      title: "Early Stage Business",
      content1: "Perfect for entrepreneurs who want to transform an idea into a complete, market-ready product.",
      content2:
      "",
    },
    {
      icon: "/Images/selfService/BusinessCanvasImages/WhoShouldUseService/targeticon2.png",
      iconMobile: "/Images/selfService/BusinessCanvasImages/WhoShouldUseService/targeticonMobile2.png",
      // title: "Early Stage Business",
      // content1: "Perfect for entrepreneurs who want to transform an idea into a complete, market-ready product.",
       title: "Small Businesses",
      content1:
      "Ideal for businesses planning to expand their product lines or enter new markets.",
      content2:
      "",
    },
    {
      icon: "/Images/selfService/BusinessCanvasImages/WhoShouldUseService/targeticon3.png",
      iconMobile: "/Images/selfService/BusinessCanvasImages/WhoShouldUseService/targeticonMobile3.png",
      // title: "Small Businesses",
      // content1:
      // "Ideal for businesses planning to expand their product lines or enter new markets.",
      title: "Startups",
      content1: "For startups ready to develop a full product and take it to market.",
      content2:
      "",
    },
    {
      icon: "/Images/selfService/BusinessCanvasImages/WhoShouldUseService/targeticon4.png",
      iconMobile: "/Images/selfService/BusinessCanvasImages/WhoShouldUseService/targeticonMobile4.png",
      title: "Product Managers",
      content1: "For those overseeing the product development lifecycle and looking for expert assistance in development and strategy.",
      content2:
        "",
    },
  ],

  faq: [
    {
      que: "What is product development?",
      ans: "Product development is the process of bringing a new product from idea to market-ready, including design, development, testing, and launch.",
    },
    {
      que: "How long does product development take?",
      ans: "Depending on the product's complexity, the development process can take anywhere from a few months to a year or more.",
    },
    {
      que: "Why is a product roadmap important?",
      ans: "A product roadmap outlines the development stages, helping you stay aligned with business objectives and deadlines.",
    },
    {
      que: "Do you offer post-launch support?",
      ans: "Yes, we provide ongoing maintenance and support to ensure your product continues to perform optimally after launch.",
    },
    {
      que: "Can you help with both digital and physical products?",
      ans: "Yes, our services cover both digital product development (software, apps) and physical product design and development.",
    },
  ],
};