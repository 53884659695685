import React from "react";
import Tick from "./assets/tick.png";
import Arrow from "./assets/arrow.png";
import { useNavigate } from "react-router-dom";
import { useMediaQuery } from "@mui/material";
const ToolCard = ({img,h1,p,l1,l2,l3,l4,link}) => {
  const navigate= useNavigate();
  const isMobile = useMediaQuery("(max-width:724px)");
  return (
    <div>
      <div style={{maxWidth:"fit-content"}} className="p-4 cursor-pointer lg:ml-[71px] lg:mt-[100px] m-1 my-3 max-w-[478px] h-[508px]   font-semibold rounded-[25px] bg-white shadow-[0_0_10px_0_rgba(0,0,0,0.3)]" onClick={()=>navigate(`/ai-startup-tools/${link}`)}>
        {/* Image section */}
        <div className="flex relative w-[100%] max-w-[300px] h-[201px]  bg-[#D7E9FD] rounded-[22px] justify-center" >
          <div className="absolute  top-3 left-3 text-center pt-0.5 text-blue-500 w-[58px] h-[29px] bg-white rounded-[7px] ">
            Free
          </div>
          <img
            className="lg:w-[200px] h-[200px] ml-[75px] mr-[75px]  "
            alt="logo"
            src={img}
          />
          </div>
          {/* Card Text */}
          <div className="text-start">
          <h1 className="text-2xl pt-4" style={{fontSize:isMobile?"18px":"24px"}}>{h1}</h1>
          <p className="text-[12px] sm:text-[15px] text-gray-500">
           {p}
          </p>
         <ul className="text-[12px] sm:text-[15px]">
            <li className="mt-2 mb-2">
              <img
                src={Tick}
                alt="tick"
                className="w-[22px] h-[22px] inline mr-3"
              />
              {l1}
            </li>
            <li className="mt-2 mb-2">
              <img
                src={Tick}
                alt="tick"
                className="w-[22px] h-[22px] inline mr-3"
              />
              {l2}
            </li>
            <li className="mt-2 mb-2">
              <img
                src={Tick}
                alt="tick"
                className="w-[22px] h-[22px] inline mr-3"
              />
             {l3}
            </li>
            <li className="mt-2 mb-2">
              <img
                src={Tick}
                alt="tick"
                className="w-[22px] h-[22px] inline mr-3"
              />
           {l4}
            </li>
          </ul>
          </div>
          <button className="w-1/2 h-10 top-2 rounded-lg text-center p-1 text-blue-500 text-[14px] sm:text-[18px]  relative bg-[#F7FCFB]">
            Try For Free
            <img
              alt="arrow"
              src={Arrow}
              className="w-[22px] h-[22px] inline ml-3 bg-white rounded-3xl p-1"
            />
          </button>
        </div>

        <div></div>
      </div>
  );
};

export default ToolCard;
