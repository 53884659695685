import React, { useEffect, useState } from "react";
import Image_Slide from "./Image_Slide";
import Pet_care_industry_1 from "./assets/Pet_care_industry_1.png"
import Pet_care_industry_2 from "./assets/Pet_care_industry_2.png"
import Pet_care_industry_3 from "./assets/Pet_care_industry_3.png"
import { useMediaQuery } from "@mui/material";
import { Document, Page } from 'react-pdf'
import { pdfjs } from 'react-pdf';
// import pdf1 from '../pdfViewer/assets/pdf/Pet Care Report - Bizowl.pdf'
import  usePdfStore  from "./store";

pdfjs.GlobalWorkerOptions.workerSrc = new URL(
  'pdfjs-dist/build/pdf.worker.min.mjs',
  import.meta.url,
).toString();

function PDF_Slider({id}){
 

    console.log(id)

    const [numPages, setNumPages] = useState(null);
  const [pageNumber, setPageNumber] = useState(1);
  const [loading, setLoading] = useState(false);

  const pdf1= `/pdf/Pet Care Report - Bizowl.pdf`
  
 const setCurrPdf = usePdfStore((state)=>state.setCurrPdf)
 const currPdf = usePdfStore((state)=>state.currPdf)

 console.log(currPdf)

  const onDocumentLoadSuccess=({ numPages })=>  {

    setNumPages(numPages);
  }



    const isMobile=useMediaQuery("(max-width:724px)");
    const isTab = useMediaQuery(`(min-width:725px) and (max-width:1200px)`);


    const pdfFiles = [
        {
            id:"pet-care-sector",
            path:pdf1,
            fileName:"Pet Care Report - Bizowl.pdf"
        }
    ]

    useEffect(()=>{
        setLoading(true);
        
        const data = pdfFiles.find((item)=>item.id===id);
        console.log("dtaa", data)
        setCurrPdf(data);
        setLoading(false);
        
        

    },[id,setCurrPdf])
    if(loading){
        return <p>Loading...</p>;
    }

    return(
        <div className="flex flex-col h-auto mt-7" style={{width:"90%"}}>
            {/* Slide number display */}
            <div className="flex justify-center h-8 w-full">
                <p>{pageNumber} of {numPages}</p>
            </div>

              

            {/* Slides Section */}
            <div className="flex flex-col items-center h-max w-full pb-10 bg-purple-100 ">
                {/* <Image_Slide Image={Pet_care_industry_1}/>
                <Image_Slide Image={Pet_care_industry_2}/>
                <Image_Slide Image={Pet_care_industry_3}/> */}
                <Document file={currPdf?.path} onLoadSuccess={onDocumentLoadSuccess}
                >

              

          {
            [...Array(numPages).keys()].map((i)=>i+1).map((page)=>(
                <Page
                pageNumber={page}
                key={page}
                renderTextLayer={false}
                renderAnnotationLayer={false}
                className={`mb-10`}
                    scale={isMobile ? 0.27: isTab? 0.56: 1 } 
                  
                />
                

            ))
          }
                </Document>



            </div>
        </div>
    )
}

export default PDF_Slider;