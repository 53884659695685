import React from "react";
import NavBar from "../Header/NavBar";
import Bottom from "./components/Bottom";
import Footer from "../Footer/Footer";
import { Helmet } from "react-helmet";
import ServicePage from "../../pages/ServicePage";
import { BusinessFeasibilityObj } from "../../data/BusinessFeasibility/BusinessFeasibilityObj";

const BusinessFeasibility = () => {
  return (
    <>
      <Helmet>
      <meta charset="UTF-8"/>
<meta name="viewport" content="width=device-width, initial-scale=1.0"/>
<title>Detailed Business Feasibility Analysis to Ensure Viability | Bizowl</title>
<meta name="description" content="Bizowl offers a detailed business feasibility analysis to evaluate the potential success and profitability of your business idea or project."/>
<meta name="keywords" content="business feasibility analysis, business evaluation, project feasibility, business success analysis, market feasibility, startup evaluation"/>
<meta name="robots" content="index, follow"/>
<meta name="author" content="Bizowl"/>
<meta http-equiv="Content-Language" content="en"/>
<link rel="canonical" href="https://www.bizzowl.com/services/business-feasibility-analysis" />
<meta property="og:title" content="Business Feasibility Analysis | Bizowl"/>
<meta property="og:description" content="Determine the viability and profitability of your business with Bizowl’s business feasibility analysis service."/>
<meta property="og:image" content="https://res.cloudinary.com/dcduojrhf/image/upload/v1728453321/Business_Feasiblity_tf5tyd.png"/>
<meta property="og:url" content="https://www.bizzowl.com/services/business-feasibility-analysis"/>
<meta name="twitter:card" content="summary_large_image"/>
<meta name="twitter:title" content="Business Feasibility Analysis | Bizowl"/>
<meta name="twitter:description" content="Bizowl offers expert business feasibility analysis to help you understand your business's potential for success and growth."/>
<meta name="twitter:image" content="https://res.cloudinary.com/dcduojrhf/image/upload/v1728453321/Business_Feasiblity_tf5tyd.png"/>
              <script type="application/ld+json">
                {`
          "@context": "https://schema.org",
  "@type": "Product",
  "name": "Business Feasibility Analysis",
  "description": "Bizowl's Business Feasibility Analysis Service helps businesses assess the potential success and profitability of their ideas and projects.",
  "brand": {
    "@type": "Organization",
    "name": "Bizowl",
    "sameAs": "https://www.bizzowl.com",
    "logo": "https://www.bizzowl.com/Images/logos/logo-of-BIZOWL--Business-Services.png"
  },
  "offers": {
    "@type": "Offer",
    "url": "https://www.bizzowl.com/services/business-feasibility-analysis",
    "priceCurrency": "INR",
    "price": "5958",
    "priceValidUntil": "2024-12-31",
    "availability": "https://schema.org/InStock",
    "itemCondition": "https://schema.org/NewCondition"
  },
  "review": {
    "@type": "Review",
    "reviewRating": {
      "@type": "Rating",
      "ratingValue": "4.8",
      "bestRating": "5"
    },
    "author": {
      "@type": "Person",
      "name": "Team Bizowl"
    }
  },
  "aggregateRating": {
    "@type": "AggregateRating",
    "ratingValue": "4.7",
    "reviewCount": "38"
  },
  "category": "Business Consultancy"
        `}
        </script>
      </Helmet>
      <div className="bg-[#F5F5F5] w-[100vw] overflow-x-hidden ">
        <NavBar />
        <div className=" md:!p-16 p-3 pt-0  w-[100%] md:w-[80%] mx-auto bg-[#F5F5F5]  mb-2">
          <ServicePage serviceObj={BusinessFeasibilityObj} />
         
        </div>
        <Footer />


        <Bottom serviceObj={BusinessFeasibilityObj} />

      </div>
    </>
  );
};

export default BusinessFeasibility;
