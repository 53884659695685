import React from 'react'

const WhoShouldChooseUs = () => {
  return (
    <div className='flex md:!block !my-5 !py-2 md:!pt-10 !px-5 !bg-[#e2eefa] md:!bg-[#f5f5f5]'>
    <h2 className="text-[20px] font-semibold text-gray-500 md:text-black text-start md:text-[35px]">Who Should Choose this Service</h2>
    <div className="flex overflow-x-scroll md:!hidden">
      <img src="/Images/selfService/chooseUs1.png" alt="banner" />
      <img src="/Images/selfService/chooseUs2.png" alt="banner" />
      <img src="/Images/selfService/chooseUs3.png" alt="banner" />
      <img src="/Images/selfService/chooseUs4.png" alt="banner" />
    </div>
    <div className="md:!grid md:grid-cols-2 items-center pt-4 hidden">
      <img src="/Images/selfService/chooseUsBig1.png" alt="banner" />
      <img src="/Images/selfService/chooseUsBig2.png" alt="banner" />
      <img src="/Images/selfService/chooseUsBig3.png" alt="banner" />
      <img src="/Images/selfService/chooseUsBig4.png" alt="banner" />
    </div>
  </div>
  )
}

export default WhoShouldChooseUs