import React from "react";
import service_1 from "../assets/service_1.png";
import service_2 from "../assets/service_2.png";
import service_3 from "../assets/service_3.png";
import service_4 from "../assets/service_4.png";
import service_1_mobile from "../assets/service_1_mobile.png";
import service_2_mobile from "../assets/service_2_mobile.png";
import service_3_mobile from "../assets/service_3_mobile.png";
import service_4_mobile from "../assets/service_4_mobile.png";

// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
import "swiper/css";
// Import Slick CSS
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { useMediaQuery } from "@mui/material";

const Service = ({ targetAudience }) => {
  const data = [
    {
      image1: service_1,
      image2: service_1_mobile,
    },
    {
      image1: service_2,
      image2: service_2_mobile,
    },
    {
      image1: service_3,
      image2: service_3_mobile,
    },
    {
      image1: service_4,
      image2: service_4_mobile,
    },
  ];
  const isMobile = useMediaQuery("(min-width: 430px) and (max-width: 768px)");

  const settings = {
    // Enables dots for navigation
    arrows: false, // Disables navigation arrows
    speed: 500, // Transition speed (in ms)
    slidesToShow: isMobile ? 2 : 1, // Default: Show 3 slides at once for larger screens
    slidesToScroll: 1,
  };
  return (
    <div>
      <div className="w-[100vw] bg-[#EDF2F7] h-[272px] flex items-center justify-center -mx-4 pl-4 md:!hidden mt-10">
        <div className="w-[35%]">
          <p className="text-left text-xl font-medium">
            Who Should Choose this Service
          </p>
        </div>

        <div className="w-[65%] md:hidden flex overflow-x-scroll gap-x-6 overflow-y-visible h-[300px]">
          {targetAudience.map((item, index) => (
            <div
              key={index}
              style={{
                backgroundImage: `url(/Images/servicePage/targetBannerMobile${
                  index + 1
                }.png)`,
              }}
              className="min-w-[148px] min-h-[203px]"
            >
              <img
                src={item.iconMobile}
                alt="icon"
                className="relative top-[-30px] left-[10px]"
              />
              <div className="flex flex-col items-start! gap-1 mx-2">
                <h2 className="text-[14px] font-semibold text-start">
                  {item.title}
                </h2>
                <p className="text-[9px] text-start">{item.content1}</p>
                <p className="text-[9px] text-start">{item.content2}</p>
              </div>
            </div>
          ))}
        </div>
      </div>

      <div className=" flex-col mt-14 !items-start w-[68%] font-cabin hidden md:flex">
        <h3 className="font-cabin font-[500] text-3xl">
          Who Should Use service
        </h3>
        <div className="w-[100%] h-[1px] bg-[#263238] opacity-20 mt-4 mb-4"></div>

        <div className="flex w-[100%] flex-wrap gap-y-40 gap-x-24 mt-20">
          {targetAudience.map((item, index) => (
            <div
              key={index}
              style={{
                backgroundImage: `url(/Images/servicePage/targetBanner${
                  index + 1
                }.png)`,
              }}
              className="w-[322px] h-[314px]"
            >
              <img
                src={item.icon}
                alt="icon"
                className="relative top-[-85px] left-[20px]"
              />
              <div
                className="flex flex-col items-start! gap-2 mx-3"
                style={{ alignItems: "flex-start" }}
              >
                <h2
                  className="text-[20px] font-semibold text-start!"
                  style={{ textAlign: "start" }}
                >
                  {item.title}
                </h2>
                <p className="text-[15px]" style={{ textAlign: "start" }}>
                  {item.content1}
                </p>
                <p className="text-[15px] " style={{ textAlign: "start" }}>
                  {item.content2}
                </p>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default Service;
