import './navBar.css'
import bizowl_logo from './assets/bizowl_logo.png'
import call_logo from './assets/phone-icon.png'
import { useNavigate } from "react-router-dom";
import { useMediaQuery } from '@mui/material';

const NavBar=()=>{
    const navigate = useNavigate();
    const isMobile = useMediaQuery("(max-width:768px)");
    const handleClick = (isMobile) => {
        if (isMobile) {
          // Open dialer with the phone number
          window.location.href = "tel:+919354651433";
        } else {
          // Open WhatsApp with a predefined message
          window.location.href = "https://wa.me/919354651433?text=Hello%20I%20need%20help";
        }
      };
    
    return(
       <div className='nav'>
         <div id='logo' onClick={()=>navigate("/")}>
              <img id='logo-text' src={bizowl_logo} />
          </div>

          <div id='need-help'  onClick={() => handleClick(isMobile)}>
          <div className='call-logo'><img src={call_logo}/></div><p>Need Help?</p>
          </div>
       </div>
    )
}

export default NavBar;