import { Checkbox, Radio, Typography } from '@mui/material';
import React, { useState } from 'react';
import { FaChevronLeft } from "react-icons/fa";
import BlueDownArrow from "../assets/Vector (6).png";
import ReactSlider from 'react-slider';
import { styled } from '@mui/material/styles';
import { FormControl, FormControlLabel } from '@mui/material';

const StyledFormControl = styled(FormControl)({
  display: "flex",
  flexDirection: "column",
  alignItems: "start",
  color: "gray",
});

const StyledFormControlLabel = styled(FormControlLabel)({
  display: "flex",
  flexDirection: "row",
  alignItems: "center",
  fontSize: "5px",
});

const MobileFilters = ({
  showOverlay,
  toggleOverlay,
  filters,
  setFilters,
  handleFilter,
  handleReset,
  handleClick,
  openMenu,
  handleSliderChange,
  handleRevisionSliderChange,
  handleConceptSliderChange
}) => {

  const PostDeliveryOptions = ["Yes", "No"];
  const TATOptions = ["Same Day", "2 Days", "3 Days", "4 Days", "5 Days"];
  const AdditionalBrandingAssetsOptions = [
    "Business Card",
    "Letterhead Design",
    "Email Signature",
    "Brand Style",
    "Social Media",
    "Website Favicon",
    "Icon Set Design",
    "Packaging Design"
  ];
  const DeliverablesOptions = ["PNG", "AI", "SVG"];

  const handleAdditionalAssetsChange = (option) => {
    setFilters((prevFilters) => {
      const newAdditionalBrandingAssets = prevFilters.AdditionalBrandingAssets.includes(option)
        ? prevFilters.AdditionalBrandingAssets.filter(item => item !== option)
        : [...prevFilters.AdditionalBrandingAssets, option];

      return {
        ...prevFilters,
        AdditionalBrandingAssets: newAdditionalBrandingAssets,
      };
    });
  };

  return (
    <div className=''>
      {showOverlay && (
        <div className="" style={{
          position: 'fixed',
          top: 0,
          left: 0,
          width: '100vw',
          height: '100vh',
          backgroundColor: 'white',
          zIndex: 1000,
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          padding: "27px",
        }}>
          <div style={{
            display: "flex",
            justifyContent: "space-around",
            alignItems: "center",
            width: "100vw",
            boxShadow: '0 5px 5px 0px rgba(0, 0, 0, 0.05)',
            gap: 50
          }}>
            <div
              style={{
                width: "90%",
                display: "flex",
                gap: 20,
                alignItems: "center",
                paddingBottom: "10px"
              }}>
              <FaChevronLeft onClick={toggleOverlay} style={{
                fontSize: "30px"
              }} />
              <Typography variant='body1' style={{
                color: "#50afe6"
              }}>Filter Plans By</Typography>
            </div>
          </div>

          <div
            className=""
            style={{
              boxShadow: "0rem 0.25rem 0.25rem 0rem rgba(0, 0, 0, 0.25)",
              backgroundColor: "#f7fcfb",
              minHeight: "100vh",
              minWidth: "100vw",
              padding: "20px",
              overflowY: "auto"
            }}
          >
            {/* Budget Section */}
            <div className="flex-col justify-content-between align-items-center mt-3" onClick={() => handleClick("budget")}>
              <div className="flex justify-between w-[100%]" style={{ marginBottom: 20 }}>
                <h6>Budget</h6>
                <img src={BlueDownArrow} alt="BlueDownArrow" width="17rem" height="10rem" />
              </div>
              {openMenu === "budget" && (
                <div style={{ display: "block", marginTop: 10 }}>
                  <div style={{ position: 'relative' }}>
                    <ReactSlider
                      value={filters.priceRange}
                      onChange={handleSliderChange}
                      min={999}
                      max={10000}
                      step={500}
                      className="w-full h-2 bg-gray-300 rounded-full"
                      thumbClassName="w-4 h-4 bg-blue-500 rounded-full cursor-pointer -mt-[3px]"
                      trackClassName="bg-blue-500 rounded-full"
                      renderThumb={(props) => <div {...props} />}
                    />
                    <div className="flex justify-between text-sm text-gray-600 mt-2">
                      <span>₹{filters.priceRange[0]}</span>
                      <span>₹{filters.priceRange[1]}</span>
                    </div>
                  </div>
                </div>
              )}
              <div className="border border-b-gray-500 w-[100%] mt-2" style={{ marginBottom: "20px" }}></div>
            </div>

            {/* Revisions Section */}
            <div className="flex-col justify-content-between align-items-center mt-3" onClick={() => handleClick("revisions")}>
              <div className="flex justify-between w-[100%]" style={{ marginBottom: 20 }}>
                <h6>Revisions</h6>
                <img src={BlueDownArrow} alt="BlueDownArrow" width="17rem" height="10rem" />
              </div>
              {openMenu === "revisions" && (
                <div style={{ display: "block", marginTop: 10 }}>
                  <div style={{ position: 'relative' }}>
                    <ReactSlider
                      value={filters.revisions}
                      onChange={handleRevisionSliderChange}
                      min={1}
                      max={5}
                      step={1}
                      className="w-full h-2 bg-gray-300 rounded-full"
                      thumbClassName="w-4 h-4 bg-blue-500 rounded-full cursor-pointer -mt-[3px]"
                      trackClassName="bg-blue-500 rounded-full"
                      renderThumb={(props) => <div {...props} />}
                    />
                    <div className="flex justify-between text-sm text-gray-600 mt-2">
                      <span>{filters.revisions}+</span>
                    </div>
                  </div>
                </div>
              )}
              <div className="border border-b-gray-500 w-[100%] mt-2" style={{ marginBottom: "20px" }}></div>
            </div>

            {/* Concepts Section */}
            <div className="flex-col justify-content-between align-items-center mt-3" onClick={() => handleClick("concepts")}>
              <div className="flex justify-between w-[100%]" style={{ marginBottom: 20 }}>
                <h6>Concepts</h6>
                <img src={BlueDownArrow} alt="BlueDownArrow" width="17rem" height="10rem" />
              </div>
              {openMenu === "concepts" && (
                <div style={{ display: "block", marginTop: 10 }}>
                  <div style={{ position: 'relative' }}>
                    <ReactSlider
                      value={filters.concepts}
                      onChange={handleConceptSliderChange}
                      min={1}
                      max={5}
                      step={1}
                      className="w-full h-2 bg-gray-300 rounded-full"
                      thumbClassName="w-4 h-4 bg-blue-500 rounded-full cursor-pointer -mt-[3px]"
                      trackClassName="bg-blue-500 rounded-full"
                      renderThumb={(props) => <div {...props} />}
                    />
                    <div className="flex justify-between text-sm text-gray-600 mt-2">
                      <span>{filters.concepts}+</span>
                    </div>
                  </div>
                </div>
              )}
              <div className="border border-b-gray-500 w-[100%] mt-2" style={{ marginBottom: "20px" }}></div>
            </div>

            {/* TAT Section */}
            <div className="flex-col justify-content-between align-items-center mt-2" onClick={() => handleClick("TAT")}>
              <div className="flex justify-between w-[100%] mb-20" style={{ marginBottom: 20 }}>
                <h6>TAT</h6>
                <img src={BlueDownArrow} alt="BlueDownArrow" width="17rem" height="10rem" />
              </div>
              {openMenu === "TAT" && (
                <div>
                  <StyledFormControl component="fieldset">
                    {TATOptions.map((option, index) => (
                      <StyledFormControlLabel
                        key={option}
                        control={
                          <Radio
                            checked={filters.TAT === option}
                            name="TAT"
                            onChange={handleFilter}
                            value={option}
                          />
                        }
                        label={option}
                      />
                    ))}
                  </StyledFormControl>
                </div>
              )}
              <div className="border border-b-gray-500 w-[100%] mt-2" style={{ marginBottom: "20px" }}></div>
            </div>

            {/* Post Delivery Support Section */}
            <div className="flex-col justify-content-between align-items-center mt-2" onClick={() => handleClick("postDeliverySupport")}>
              <div className="flex justify-between w-[100%] mb-20" style={{ marginBottom: 20 }}>
                <h6>Post Delivery Support</h6>
                <img src={BlueDownArrow} alt="BlueDownArrow" width="17rem" height="10rem" />
              </div>
              {openMenu === "postDeliverySupport" && (
                <div>
                  <StyledFormControl component="fieldset">
                    {PostDeliveryOptions.map((option, index) => (
                      <StyledFormControlLabel
                        key={option}
                        control={
                          <Radio
                            checked={filters.PostDeliverySupport === option}
                            name="PostDeliverySupport"
                            onChange={handleFilter}
                            value={option}
                          />
                        }
                        label={option}
                      />
                    ))}
                  </StyledFormControl>
                </div>
              )}
              <div className="border border-b-gray-500 w-[100%] mt-2" style={{ marginBottom: "20px" }}></div>
            </div>

            {/* Additional Branding Assets Section */}
            <div className="flex-col justify-content-between align-items-center mt-2" onClick={() => handleClick("AdditionalAssets")}>
              <div className="flex justify-between w-[100%] mb-20" style={{ marginBottom: 20 }}>
                <h6>Additional Assets</h6>
                <img src={BlueDownArrow} alt="BlueDownArrow" width="17rem" height="10rem" />
              </div>
              {openMenu === "AdditionalAssets" && (
                <div>
                  <StyledFormControl component="fieldset">
                    {AdditionalBrandingAssetsOptions.map((option) => (
                      <StyledFormControlLabel
                        key={option}
                        control={
                          <Checkbox
                            checked={filters.AdditionalBrandingAssets.includes(option)}
                            onChange={() => handleAdditionalAssetsChange(option)}
                            name="AdditionalBrandingAssets"
                            value={option}
                          />
                        }
                        label={option}
                      />
                    ))}
                  </StyledFormControl>
                </div>
              )}
              <div className="border border-b-gray-500 w-[100%] mt-2" style={{ marginBottom: "20px" }}></div>
            </div>

            {/* Deliverables Section */}
            <div className="flex-col justify-content-between align-items-center mt-2" onClick={() => handleClick("deliverables")}>
              <div className="flex justify-between w-[100%] mb-20" style={{ marginBottom: 20 }}>
                <h6>Deliverables</h6>
                <img src={BlueDownArrow} alt="BlueDownArrow" width="17rem" height="10rem" />
              </div>
              {openMenu === "deliverables" && (
                <div>
                  <StyledFormControl component="fieldset">
                    {DeliverablesOptions.map((option, index) => (
                      <StyledFormControlLabel
                        key={option}
                        control={
                          <Radio
                            checked={filters.Deliverables === option}
                            name="Deliverables"
                            onChange={handleFilter}
                            value={option}
                          />
                        }
                        label={option}
                      />
                    ))}
                  </StyledFormControl>
                </div>
              )}
              <div className="border border-b-gray-500 w-[100%] mt-2" style={{ marginBottom: "20px" }}></div>
            </div>

            <button type='reset' onClick={handleReset} style={{
              padding: '10px 20px',
              borderRadius: '24px',
              backgroundColor: '#1976D2',
              color: 'white',
              cursor: 'pointer',
              marginLeft: '10px',
              marginTop: "25px",
              marginBottom: "70px",
              fontWeight: 'bold',
              fontSize: '16px',
              boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)',
              width: "40%"
            }}>Reset</button>
          </div>
        </div>
      )}
    </div>
  );
};

export default MobileFilters;