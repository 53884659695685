import { React, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { MdKeyboardArrowDown, MdKeyboardArrowUp } from "react-icons/md";
import {
  Box,
  Button,
  Card,
  CardContent,
  Checkbox,
  Grid,
  Typography,
  FormControl,
  FormControlLabel,
  Radio,
  useMediaQuery,
  styled,
} from "@mui/material";

const PricingCard = ({ 
  plan, 
  planData, // Full plan data
  isExpanded, 
  onToggleExpand, 
  checkboxState, 
  handleCheckboxChange, 
  isMobile, 
  selectedServices, 
  partnerIndex,
  planIndex 
}) => {
  const navigate = useNavigate();

  return (
    <div className="m-5 d-flex text-left">
      <div
        className="text-left"
        style={{
          height: "13rem",
          width: "12rem",
          background: `conic-gradient(from 220deg at calc(100% - 60px) calc(100% - 50px), #E9D8E3 80%, white 100%)`,
          borderColor: "transparent",
          borderTopLeftRadius: "25px",
          borderBottomLeftRadius: "25px",
          borderTopRightRadius: "0px",
          borderBottomRightRadius: "0px",
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          justifyContent: "space-between",
          boxShadow: "0 0 0px 4px rgba(255, 255, 255, 0.8)",
        }}
      >
        <div
          className="card-body"
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <div
            className="d-flex justify-content-center"
            style={{
              border: "3px solid",
              borderColor: "#000000",
              borderRadius: "20px",
              padding: "0.5rem",
              width: "80%",
              fontSize: "bold",
              backgroundColor: "white",
            }}
          >
            <div style={{ letterSpacing: "10px" }}>
              <span
                style={{
                  fontWeight: "bold",
                  fontSize: "2rem",
                  color: "#000000",
                }}
              >
                {plan.partnerName}
              </span>
            </div>
          </div>
        </div>
      </div>
      <div
        style={{
          boxShadow: "0.25rem 0.25rem 0.9375rem 0px #407BFF6B",
          borderTopRightRadius: "15px",
          borderBottomRightRadius: "15px",
        }}
      >
        <div
          className="card"
          style={{
            height: "13rem",
            width: "35rem",
            borderTopLeftRadius: "0px",
            borderBottomLeftRadius: "0px",
            borderTopRightRadius: "15px",
            borderBottomRightRadius: "15px",
            border: "none",
            paddingLeft: "5px",
          }}
        >
          <div
            className="card-body d-flex justify-content-between"
            style={{ marginBottom: "-20px" }}
          >
            <div className="d-flex flex-column align-items-start" style={{ width: "25%" }}>
              <h6 style={{ fontSize: "0.9rem", paddingBottom: '5px' }}>Logo Concepts</h6>
              <p style={{ fontWeight: "bold" }}>{plan.concepts}</p>
              <h6 style={{ fontSize: "0.9rem", paddingBottom: '5px' }}>Logo Revisions</h6>
              <p style={{ fontWeight: "bold" }}>{plan.revisions}</p>
            </div>
            <div className="d-flex flex-column justify-content-start" style={{ fontSize: "0.9rem", width: "30%" }}>
              <h6 style={{ fontSize: "0.9rem", paddingBottom: '5px' }}>Additional Assets</h6>
              <p style={{ fontWeight: "bold" }}>{plan.assets}</p>
            </div>
            <div className="d-flex flex-column justify-content-start" style={{ fontSize: "0.9rem", width: "20%" }}>
              <h6 style={{ fontSize: "0.9rem", paddingBottom: '5px' }}>TAT</h6>
              <p style={{ fontWeight: "bold" }}>{plan.delivery}</p>
            </div>
            <div className="d-flex flex-column justify-content-center align-items-center" style={{ fontSize: "0.9rem", height: '100%', width: "25%" }}>
              <p style={{ fontSize: "small", textAlign: 'center', paddingBottom: '5px' }}>Total Price Included GST</p>
              <p style={{ fontWeight: "bold" }}>₹{plan.price}</p>
              <button
                className="btn btn-primary btn-sm"
                style={{ borderRadius: "12px", padding: "6px 12px" }}
                onClick={() =>
                  navigate(
                    `/services/logo-design-distribution/quote-details/service-summary/?id=${planData.id}`
                  )
                }
              >
                Get Started
              </button>
            </div>
          </div>
          <div
            style={{
              width: "100%",
              display: "flex",
              flexDirection: "column",
              alignItems: "end",
              backgroundColor: "#F1FFFC",
              borderRadius: "0px 0px 15px 0px",
              zIndex: "200",
            }}
          >
            <div
              style={{
                width: "50%",
                display: "flex",
                alignItems: "center",
                justifyContent: "right",
                marginRight: "15px",
              }}
            >
              <Checkbox
                checked={checkboxState[planData.id] || false}
                onChange={(event) => handleCheckboxChange(event, planData.id)}
                disabled={
                  (isMobile ? selectedServices.length >= 2 : selectedServices.length >= 3) &&
                  !checkboxState[`${planData.id}-${planIndex}`]
                }
              />
              <Typography>Add To Compare</Typography>
            </div>
          </div>
          <div
            onClick={onToggleExpand}
            style={{
              width: "239px",
              color: "#455A64",
              position: "absolute",
              display: "flex",
              justifyContent: "space-around",
              alignItems: "center",
              top: "209px",
              left: "20%",
              borderTop: "0px",
              fontSize: "x-small",
              borderBottomRightRadius: "8px",
              borderBottomLeftRadius: "8px",
              zIndex: "100",
              backgroundColor: "white",
              boxShadow: "0px 2px 4px 0px #C4C6C6",
              cursor: "pointer",
            }}
          >
            <p>
              {isExpanded ? "Show less" : "More options from"} {plan.partnerName}
            </p>
            <p style={{ fontSize: "medium" }}>
              {isExpanded ? <MdKeyboardArrowUp /> : <MdKeyboardArrowDown />}
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

const PricingCards = ({ data, checkboxState, handleCheckboxChange, isMobile, selectedServices }) => {
  const [expandedPartner, setExpandedPartner] = useState(null);

  const toggleExpand = (partnerName) => {
    setExpandedPartner(expandedPartner === partnerName ? null : partnerName);
  };

  return (
    <div className="mx-auto">
      {data.map((partner, partnerIndex) => {
        const partnerName = partner["Partner Name"];
        const isExpanded = expandedPartner === partnerName;
        const plansToShow = isExpanded ? partner.Plans : [partner.Plans[0]];

        return plansToShow.map((planData, planIndex) => {
          const assets = planData["Details of Assets"].split(',');
          const displayedAssets = assets.length > 3 ? assets.slice(0, 3).concat('...') : assets;

          return (
            <PricingCard
              key={`${partnerIndex}-${planIndex}`}
              planData={planData} // Pass full plan data
              plan={{
                partnerName: partnerName.slice(0, 3).toUpperCase(),
                concepts: planData["Concepts"],
                revisions: planData["Revisions Included"],
                assets: displayedAssets.join(', '),
                delivery: planData["TAT (days)"] + ' Days',
                price: planData["Price"],
                bgColor: planData["bgColor"]
              }}
              isExpanded={isExpanded}
              onToggleExpand={() => toggleExpand(partnerName)}
              checkboxState={checkboxState}
              handleCheckboxChange={handleCheckboxChange}
              isMobile={isMobile}
              selectedServices={selectedServices}
              partnerIndex={partnerIndex}
              planIndex={planIndex}
            />
          );
        });
      })}
    </div>
  );
};

export default PricingCards;