export const SwotObj={
    serviceName: "SWOT Analysis Service",
    icon: "/Images/selfService/SWOTServiceImages/swot-analysis-icon.png",

    heroSection: {
        image: "/Images/selfService/SWOTServiceImages/SWOT-analysis-HeroImg.png",
        message: "Because Insights Drive Success",
        heading: "Empower Your Business",
        headingPortion: " with a Comprehensive SWOT Analysis",
        highlightedText: "A thorough SWOT analysis equips your business with critical insights.",
        content:
          "Understand your market position, recognize opportunities for growth, and navigate challenges effectively. Our service helps you strategically align resources for success.",
      },

      aboutService: {
        content1:
          "Our SWOT Analysis service is designed to empower entrepreneurs, small business owners, and startups with a structured approach to evaluating their internal and external environments. ",
        content2:
          "By identifying strengths, weaknesses, opportunities, and threats, we provide a comprehensive view that informs strategic planning and helps you make informed business decisions. With our expertise, you will develop a clear roadmap for growth and sustainability.",
      },

      features: [
        {
          icon: "/Images/selfService/SWOTServiceImages/details/det1.png",
          title: "Strategic Strengths Identification",
          description:
            "We help you pinpoint your company's strengths, weaknesses, opportunities, and threats, enabling informed decision-making.",
        },
        {
          icon: "/Images/selfService/SWOTServiceImages/details/det4.png",
          title: "Comprehensive Business Overview",
          description:
            "Gain a thorough understanding of your business landscape, equipping you with insights to steer your strategy effectively.",
        },
        {
            icon: "/Images/selfService/SWOTServiceImages/details/det2.png",
            title: "In-Depth Market Insights",
            description:
              "Analyze your market position to identify growth opportunities and potential risks, allowing for proactive planning.",
          },
        {
          icon: "/Images/selfService/SWOTServiceImages/details/det5.png",
          title: "Competitive Advantage Analysis",
          description:
            "Evaluate your competition to understand your unique position and identify areas for differentiation.",
        },
        {
          icon: "/Images/selfService/SWOTServiceImages/details/det3.png",
          title: "Resource Allocation Recommendations",
          description:
            "Receive expert guidance on how to allocate your resources effectively based on your SWOT findings.",
        },
        {
          icon: "/Images/selfService/SWOTServiceImages/details/det6.png",
          title: "Tailored SWOT Reports",
          description:
            "Get customized SWOT analysis reports that highlight critical insights specific to your business context.",
        },
        
      ],

      payemnetSummary: {
        totalPayment: "5049",
        gst: "908",
        Payable: "5958",
      },

      benefits: {
        title: "Benefits of SWOT Analysis Service",
        data: [
          {
            title: "Strategic Clarity",
            description:
              "Clearly identify your strengths and weaknesses for informed decision-making.",
            sno: "01",
          },
          {
            title: "Opportunity Awareness",
            description:
              "Recognize potential growth areas and market opportunities.",
            sno: "02",
          },
          {
            title: "Risk Management",
            description:
              "Anticipate threats to develop proactive strategies.",
            sno: "03",
          },
          {
            title: "Resource Optimization",
            description: "Efficiently allocate resources based on analysis.",
            sno: "04",
          },
          {
            title: "Competitive Insights",
            description: "Understand your position relative to competitors.",
            sno: "05",
          },
          {
            title: "Informed Decisions",
            description: "Make strategic choices backed by comprehensive analysis.",
            sno: "06",
          },
        ],
      },







      featuresOverview: [
        {
          title: "SWOT Framework Application",
          icon: "/Images/selfService/SWOTServiceImages/features/ft1.png",
        },
        {
          title: "Customized SWOT Analysis Reports",
          icon: "/Images/selfService/SWOTServiceImages/features/ft6.png",
        },
        {
          title: "Strength and Weakness Evaluation",
          icon:  "/Images/selfService/SWOTServiceImages/features/ft2.png",
         
        },
        {
          title: "Opportunity and Threat Identification",
          icon: "/Images/selfService/SWOTServiceImages/features/ft7.png",
        },
        {
          title: "Market Position Analysis",
          icon: "/Images/selfService/SWOTServiceImages/features/ft3.png",
        },
        {
          title: "Strategic Recommendations",
          icon: "/Images/selfService/SWOTServiceImages/features/ft8.png",
        },
        {
          title: "Resource Allocation Guidance",
          icon: "/Images/selfService/SWOTServiceImages/features/ft4.png",
        },
        {
          title: "Competitive Landscape Insights",
          icon: "/Images/selfService/SWOTServiceImages/features/ft9.png",
        },
        {
          title: "Ongoing Support & Consultation",
          icon: "/Images/selfService/SWOTServiceImages/features/ft5.png",
        },
        {
          title: "User-Friendly SWOT Analysis Tool",
          icon: "/Images/selfService/SWOTServiceImages/features/ft10.png",
        },
      ],


      howItWorks: [
        {
          bg:"/Images/selfService/IdeaValidationImages/IdeaValWorks/fg1.png",
          fg:"/Images/selfService/howItWorks/InitialConsultation.png",
          step: "Initial Consultation",
          description:
            "We assess your business needs and current challenges.",
        },
        {
          bg:"/Images/selfService/IdeaValidationImages/IdeaValWorks/fg2.png",
          fg:"/Images/selfService/howItWorks/dataCollection.png",
          step: "Data Collection",
          description:
            "Our team analyses your business model, operations, and goals.",
        },
        {
          bg:"/Images/selfService/IdeaValidationImages/IdeaValWorks/fg3.png",
          fg:"/Images/selfService/howItWorks/swotAnalysisDev.png",
          step: "SWOT Analysis Development",
          description:
            "You receive a personalised action plan with expert recommendations.",
        },
        {
          bg:"/Images/selfService/IdeaValidationImages/IdeaValWorks/fg4.png",
          fg:"/Images/selfService/howItWorks/Action Plan.png",
          step: "Action Plan Creation",
          description:
            "We offer continued support as you implement the recommendations.",
        },
      ],

      targetAudience: [
        {
            icon: "/Images/servicePage/targeticon1.png",
            iconMobile: "/Images/servicePage/targeticonMobile1.png",
            title: "Aspiring Entrepreneurs",
            content1: "If you’re starting a new venture, our SWOT analysis helps you understand your business landscape and identify key areas for focus.",
            content2:
            "",
          },
        {
          
          icon: "/Images/servicePage/targeticon2.png",
          iconMobile: "/Images/servicePage/targeticonMobile2.png",
          title: "Small Business Owners",
          content1: "If you own a business, our SWOT analysis will help you identify strengths, weaknesses, opportunities, and threats for strategic planning.",
          content2:
          "",
        },
        
       
        
        {
            icon: "/Images/servicePage/targeticon3.png",
          iconMobile: "/Images/servicePage/targeticonMobile3.png",
          title: "Early-Stage Startups",
          content1: "Our service provides insights to refine your strategy, ensuring you leverage strengths and address weaknesses as you grow. ",
          content2:
            "",
        },
        {
          icon: "/Images/selfService/BusinessConsultancyImages/freelancer.png",
          iconMobile: "/Images/selfService/SWOTServiceImages/freelancerMobile.png",
          
          title: "Consultants and Advisors",
          content1:
          "If you're advising businesses, our SWOT analysis tool equips you with comprehensive insights to help your clients strategize effectively.",
          content2:
          "",
        },
      ],

      faq: [
        {
          que: "What is a SWOT Analysis?",
          ans: "A SWOT analysis is a strategic tool that helps businesses identify their strengths, weaknesses, opportunities, and threats, aiding in informed decision-making.",
        },
        {
          que: "How does SWOT analysis benefit my business?",
          ans: "It provides a comprehensive view of your internal and external environment, helping you make strategic choices and identify areas for improvement.",
        },
        {
          que: "Can I use a SWOT analysis for any type of business?",
          ans: "Yes, SWOT analysis is versatile and can be applied to various industries and business sizes.",
        },
        {
          que: "Is this analysis conducted online?",
          ans: "Yes, we offer an online SWOT analysis tool to facilitate easy data collection and report generation",
        },
        {
            que: "How long does it take to complete a SWOT analysis?",
            ans: "Typically, the process takes 1-2 weeks, depending on the complexity and data availability.",
          },
        
      ],
};