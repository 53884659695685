import React from "react";
import CoverSection from "./CoverSection";
import SupportSection from "./SupportSection";

const SupportPageBody = () => {
  return (
    <div className="max-w-[1151px] bg-white mx-auto mt-24 md:mt-6 pb-4 mb-6 rounded-3xl">
      <div className="bg-[#D7E9FD] rounded-t-3xl md:h-[407px]">
        <CoverSection />
      </div>
      <SupportSection />
    </div>
  );
};

export default SupportPageBody;
