import React from "react";
import { supportPageMain } from "../../data/supportPage/supportPage";

const SupportSection = () => {
  return (
    <div className="font-cabin">
      <h1 className="text-[25px] md:text-[40px] font-bold mt-[27px] mx-3 md:mx-0 ">
        <span className="text-[#1C6ED0]">Welcome! </span>
        How can we help?
      </h1>
      <p className="text-[12px] md:text-[18px] text-gray-500 max-w-[695px] mx-auto mt-[15px]" style={{fontFamily:"Inter"}}>
        Hi there stuck somewhere? don’t worry we’re here to help. Check out the
        options below to get help. Don’t worry whatever it is together we will
        sort out.
      </p>
      <div className="flex flex-col md:flex-row gap-[68px] justify-center mt-[63px] mb-[63px]">
        {supportPageMain.map((data) => {
          return (
            <div className="bg-[#D7E9FD] flex flex-col rounded-3xl w-[208px] h-[272px] justify-between p-2 shadow-xl">
              <img className="h-[96px]" src={data.img} alt={data.head} />
              <div>
                <h3 className="text-[18px] md:text-[20px] font-semibold mb-2">{data.head}</h3>
                <p className="text-[12px] md:text-[14px] text-gray-500">{data.data}</p>
              </div>
              <p className="text-[#1C6ED0] text-[14px] md:text-[16px] font-semibold cursor-pointer">
                {data.button}
              </p>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default SupportSection;
