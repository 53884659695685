import React from 'react'

const HowItWorks = () => {
  return (
    <div className='!pt-5 md:!pt-10 !pb-0 !px-5'>
         <h2 className="text-[20px] font-semibold text-start md:text-[35px]">How it works</h2>
         {/* <hr /> */}
         <img className='bg-white rounded-2xl !mt-4 md:!hidden' src="/Images/selfService/workBanner.png" alt="workBanner" />
         <img className='bg-white rounded-2xl !mt-4 hidden md:!block' src="/Images/selfService/workBannerBig.png" alt="workBanner" />
    </div>
  )
}

export default HowItWorks
