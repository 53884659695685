import React from "react";

const CoverSection = () => {
  return (
    <div className="!mx-auto !w-fit relative top-6 md:top-[-7px] ">
      <img className="hidden md:block" src="/Images/supportPage/coverPage.png" alt="" />
      <img className="md:hidden" src="/Images/supportPage/coverPageMobile.png" alt="" />
    </div>
  );
};

export default CoverSection;
