import React from "react";
import NavBar from "../Header/NavBar";
import ServicePage from "../../pages/ServicePage";
import { BrandingStrategyServiceObj } from "../../data/brandingStrategy/BrandingStrategyService";
import Bottom from "../SelfServicePage/components/Bottom";
import Footer from "../Footer/Footer";
import { Helmet } from "react-helmet";

const BrandingStrategy = () => {
  return (
    <>
      <Helmet>
        <meta charset="UTF-8" />
        <meta name="viewport" content="width=device-width, initial-scale=1.0" />
        <title>
          Expert Branding Strategy Service for Business Growth| Bizowl
        </title>
        <meta
          name="description"
          content="Bizowl’s business branding strategy service helps businesses develop a strong brand identity and voice for market success."
        />
        <meta
          name="keywords"
          content="branding strategy, brand identity, business branding, brand voice, brand development, brand positioning"
        />
        <meta name="robots" content="index, follow" />
        <meta name="author" content="Bizowl" />
        <meta http-equiv="Content-Language" content="en" />
        <link
          rel="canonical"
          href="https://www.bizzowl.com/services/business-branding-strategy-service"
        />
        <meta
          property="og:title"
          content="Business Branding Strategy Service | Bizowl"
        />
        <meta
          property="og:description"
          content="Create a powerful brand with Bizowl’s expert branding strategy service, tailored to establish and grow your business."
        />
        <meta
          property="og:image"
          content="https://res.cloudinary.com/dcduojrhf/image/upload/v1728453321/Bannding_Strategy_t5dahx.png"
        />
        <meta
          property="og:url"
          content="https://www.bizzowl.com/services/business-branding-strategy-service"
        />
        <meta name="twitter:card" content="summary_large_image" />
        <meta
          name="twitter:title"
          content="Business Branding Strategy Service | Bizowl"
        />
        <meta
          name="twitter:description"
          content="Bizowl’s branding strategy service helps businesses define their brand voice, identity, and market positioning for long-term success."
        />
        <meta
          name="twitter:image"
          content="https://res.cloudinary.com/dcduojrhf/image/upload/v1728453321/Bannding_Strategy_t5dahx.png"
        />
      </Helmet>
      <div className="bg-[#F5F5F5] w-[100vw] overflow-x-hidden ">
        <NavBar />
        <div className=" md:!p-16 p-3 pt-0  w-[100%] md:w-[80%] mx-auto bg-[#F5F5F5]  mb-2">
          <ServicePage serviceObj={BrandingStrategyServiceObj} />
        </div>
        <Footer />
        <Bottom serviceObj={BrandingStrategyServiceObj} />
      </div>
    </>
  );
};

export default BrandingStrategy;
