export const BusinessConsultancyObj={
    serviceName: "Business Consulting Service",
    icon: "/Images/selfService/BusinessConsultancyImages/flow_chart.png",

    heroSection: {
        image: "/Images/selfService/BusinessConsultancyImages/Business-Consultation-HeroImg.png",
        message: "Because expert Guidance Drives Growth",
        heading: "Maximize Success: Elevate your ",
        headingPortion: "Business with Expert Consultancy",
        highlightedText: "Navigating Business challenges without expert advice can hinder progress.",
        content:
          "A professional consultancy provides strategic insights,tailored soluitons, and actionable plans to help you overcome obstacles and unlock growth opportunities efficiently.",
      },

      aboutService: {
        content1:
          "Our Business Consultation service is aimed at startups and entrepreneurs who need expert guidance in refining their business models, operations, and overall strategy. ",
        content2:
          "With a focus on long-term success, we provide actionable insights and strategic advice tailored to your specific needs.",
      },

      features: [
        {
          icon: "/Images/selfService/MarketEntryImages/MarketEntryFeatures1/Agile.png",
          title: "Business Strategy Development",
          description:
            "Work with our experts to refine your overall business strategy and ensure alignment with your long-term goals.",
        },
        {
          icon: "/Images/selfService/MarketEntryImages/MarketEntryFeatures1/Vip.png",
          title: "Operational Guidance",
          description:
            "Receive advice on streamlining business operations for efficiency and scalability.",
        },
        {
            icon: "/Images/selfService/MarketEntryImages/MarketEntryFeatures1/hugeicons_resources-add.png",
            title: "Business Model Refinement",
            description:
              "Fine-tune your business model to ensure sustainability and profitability.",
          },
        {
          icon: "/Images/selfService/MarketEntryImages/MarketEntryFeatures1/Regulatory.png",
          title: "Legal & Compliance Assistance",
          description:
            "Guidance on legal structure, contracts, and industry-specific regulations.",
        },
        {
          icon: "/Images/selfService/MarketEntryImages/MarketEntryFeatures1/Competition.png",
          title: "Risk Management",
          description:
            "Identify potential risks in your business and receive strategies to mitigate them.",
        },
        {
          icon: "/Images/selfService/MarketEntryImages/MarketEntryFeatures1/Partnership handshake.png",
          title: "Pricing & Revenue Model assistance",
          description:
            "Will closely work with you find the best revenue and pricing model for your business.",
        },
        
      ],

      payemnetSummary: {
        totalPayment: "5049",
        gst: "908",
        Payable: "5958",
      },

      benefits: {
        title: "Benefits of Business Consulting Service",
        data: [
          {
            title: "Expert Guidance",
            description:
              "Work with seasoned professionals to get the strategic advice you need.",
            sno: "01",
          },
          {
            title: "Improve Efficiency",
            description:
              "Streamline your business operations to reduce waste and increase profitability.",
            sno: "02",
          },
          {
            title: "Reduce Risks",
            description:
              "Identify and mitigate risks before they become critical issues.",
            sno: "03",
          },
          {
            title: "Business model efficiency",
            description: "Build a sustainable business model with expert help.",
            sno: "04",
          },
          {
            title: "Long-Term Success",
            description: "Ensure that your business is positioned for long-term growth.",
            sno: "05",
          },
          {
            title: "Custom Solutions",
            description: "Get personalized advice tailored to your specific business needs.",
            sno: "06",
          },
        ],
      },







      featuresOverview: [
        {
          title: "Business strategy consulting",
          icon: "/Images/selfService/BusinessConsultancyImages/Business strategy.png",
        },
        {
          title: "Pricing & Revenue Model",
          icon: "/Images/selfService/BusinessConsultancyImages/Low prices.png",
        },
        {
          title: "Risk assessment & management",
          icon:  "/Images/selfService/BusinessConsultancyImages/Risk management.png",
         
        },
        {
          title: "Operational efficiency strategies",
          icon: "/Images/selfService/BusinessConsultancyImages/Guidance.png",
        },
        {
          title: "Business model development",
          icon: "/Images/selfService/BusinessConsultancyImages/Business model.png",
        },
        {
          title: "Compliance & legal guidance",
          icon: "/Images/selfService/BusinessConsultancyImages/Legal document.png",
        },
        {
          title: "Partnership & networking opportunities",
          icon: "/Images/selfService/BusinessConsultancyImages/Partnership handshake.png",
        },
        {
          title: "Custom reports & action plans",
          icon: "/Images/selfService/BusinessConsultancyImages/3d report.png",
        },
        {
          title: "Expert advisory team",
          icon: "/Images/selfService/BusinessConsultancyImages/Conversation.png",
        },
        {
          title: "Tailored consultation sessions",
          icon: "/Images/selfService/BusinessConsultancyImages/Consulting.png",
        },
      ],


      howItWorks: [
        {
          bg:"/Images/selfService/IdeaValidationImages/IdeaValWorks/fg1.png",
          fg:"/Images/selfService/howItWorks/InitialConsultation.png",
          step: "Initial Consultation",
          description:
            "We assess your business needs and current challenges.",
        },
        {
          bg:"/Images/selfService/IdeaValidationImages/IdeaValWorks/fg2.png",
          fg:"/Images/selfService/howItWorks/Strategic Review.png",
          step: "Strategic Review",
          description:
            "Our team analyses your business model, operations, and goals.",
        },
        {
          bg:"/Images/selfService/IdeaValidationImages/IdeaValWorks/fg3.png",
          fg:"/Images/selfService/howItWorks/Customised consulation.png",
          step: "Customised Consultation",
          description:
            "You receive a personalised action plan with expert recommendations.",
        },
        {
          bg:"/Images/selfService/IdeaValidationImages/IdeaValWorks/fg4.png",
          fg:"/Images/selfService/howItWorks/Results.png",
          step: "Ongoing Support",
          description:
            "We offer continued support as you implement the recommendations.",
        },
      ],

      targetAudience: [
        {
          icon: "/Images/servicePage/targeticon1.png",
          iconMobile: "/Images/servicePage/targeticonMobile1.png",
          title: "Aspiring Entrepreneurs",
          content1: "You’re passionate about starting your own business but need direction.",
          content2:
          "Our service helps you validate and refine your idea, ensuring it’s ready for market entry.",
        },
        {
          icon: "/Images/servicePage/targeticon2.png",
          iconMobile: "/Images/servicePage/targeticonMobile2.png",
          title: "Small Businesses",
          content1: "You’re looking to grow and expand into new markets.",
          content2:
          "We provide strategic insights to help you scale while minimizing risks and improving operations.",
        },
       
        
        {
          icon: "/Images/servicePage/intCompanies.png",
          iconMobile: "/Images/servicePage/intCompaniesmobile.png",
          title: "International Companies",
          content1: "You’re entering the Indian market and need a local strategy. ",
          content2:
            "We offer expert advice on navigating regulations, market conditions, and potential partnerships.",
        },
        {
          icon: "/Images/selfService/BusinessConsultancyImages/freelancer.png",
          iconMobile: "/Images/selfService/SWOTServiceImages/freelancerMobile.png",
          title: "Freelancers & Solo Entrepreneurs",
          content1:
          "You’re ready to take your freelance work to the next level.",
          content2:
          "Our service helps you transition into a structured business with clear strategic guidance.",
        },
      ],

      faq: [
        {
          que: "What type of businesses do you consult?",
          ans: "We consult businesses in various industries, including tech, retail, and services.",
        },
        {
          que: "Can I consult for specific challenges?",
          ans: "Yes, we offer tailored consultations based on your unique business challenges.",
        },
        {
          que: "How long are the consultations?",
          ans: "Consultation sessions are typically 1-2 hours, but we provide ongoing support.",
        },
        {
          que: "What does the consultation process involve?",
          ans: "It involves an in-depth review of your business strategy, operations, and financials.",
        },
        
      ],
};