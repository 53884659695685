import { create } from "zustand";

const useStore = create((set) => ({
  ideaDetails: {
    ideaAbout: "",
    targetAudience: "",
    problemSolved: "",
    marketResearch: "",
    timeline: "",
    competitors: "",
  },
  contactDetails: {
    fullName: "",
    phone: "",
    email: "",
    location: "",
  },
  setIdeaDetails: (details) =>
    set((state) => ({
      ideaDetails: { ...state.ideaDetails, ...details },
    })),
  setContactDetails: (details) =>
    set((state) => ({
      contactDetails: { ...state.contactDetails, ...details },
    })),
  resetForm: () =>
    set({
      ideaDetails: {
        ideaAbout: "",
        targetAudience: "",
        problemSolved: "",
        marketResearch: "",
        timeline: "",
        competitors: "",
      },
      contactDetails: {
        fullName: "",
        phone: "",
        email: "",
        location: "",
      },
    }),
}));

export default useStore;
