import React from "react";
import NavBar from "../Header/NavBar";
import Bottom from "./components/Bottom";
import Footer from "../Footer/Footer";
import { Helmet } from "react-helmet";
import ServicePage from "../../pages/ServicePage";
import { MarketEntryObj } from "../../data/MarketEntryStrategy/MarketEntryObj";

const MarketEntryPage = () => {
  return (
    <>
      <Helmet>
      <meta charset="UTF-8"/>
<meta name="viewport" content="width=device-width, initial-scale=1.0"/>
<title>Market Entry Strategy | Enter New Markets Confidently | Bizowl</title>
<meta name="description" content="Bizowl's Market Entry Strategy helps businesses enter new markets with expert insights on localization, competition, and regulations for successful expansion."/>
<meta name="keywords" content="Market Entry Strategy, Business Expansion Strategy, Enter New Markets, Market Localization, International Market Entry, Market Analysis, New Market Strategy, Market Penetration Strategy"/>
<meta name="robots" content="index, follow"/>
<meta name="author" content="Bizowl"/>
<meta http-equiv="Content-Language" content="en"/>
<link rel="canonical" href="https://www.bizzowl.com/services/market-entry-strategy" />
<meta property="og:title" content="Market Entry Strategy | Enter New Markets Confidently | Bizowl"/>
<meta property="og:description" content="Expand into new markets with Bizowl's expert Market Entry Strategy. Get insights on localization, competition, and regulations to succeed in global expansion."/>
<meta property="og:image" content="https://res.cloudinary.com/dcduojrhf/image/upload/v1728313919/Market_Entry_Strategy_zulnbw.png"/>
<meta property="og:url" content="https://www.bizzowl.com/services/market-entry-strategy"/>
<meta name="twitter:card" content="summary_large_image"/>
<meta name="twitter:title" content="Market Entry Strategy | Enter New Markets Confidently | Bizowl"/>
<meta name="twitter:description" content="Enter new markets with ease using Bizowl's Market Entry Strategy service. Tailor your business approach with insights on competition, regulations, and more."/>
<meta name="twitter:image" content="https://res.cloudinary.com/dcduojrhf/image/upload/v1728313919/Market_Entry_Strategy_zulnbw.png"/>
        <script type="application/ld+json">
                {`
         {
  "@context": "https://schema.org",
  "@type": "Product",
  "name": "Market Entry Strategy",
  "description": "Bizowl's Market Entry Strategy helps businesses enter new markets with insights on localization, competition, and regulations.",
  "brand": {
    "@type": "Organization",
    "name": "Bizowl",
    "sameAs": "https://bizzowl.com",
    "logo": "https://www.bizzowl.com/Images/logos/logo-of-BIZOWL--Business-Services.png"
  },
  "offers": {
    "@type": "Offer",
    "url": "https://bizzowl.com/services/market-entry-strategy",
    "priceCurrency": "INR",
    "price": "5958",
    "priceValidUntil": "2024-12-31",
    "availability": "https://schema.org/InStock",
    "itemCondition": "https://schema.org/NewCondition"
  },
  "review": {
    "@type": "Review",
    "reviewRating": {
      "@type": "Rating",
      "ratingValue": "4.8",
      "bestRating": "5"
    },
    "author": {
      "@type": "Person",
      "name": "Team Bizowl"
    }
  },
  "aggregateRating": {
    "@type": "AggregateRating",
    "ratingValue": "4.7",
    "reviewCount": "38"
  },
  "category": "Market Entry Strategy"
}
        `}
        </script>
      </Helmet>
      <div className="bg-[#F5F5F5] w-[100vw] overflow-x-hidden ">
        <NavBar />
        <div className=" md:!p-16 p-3 pt-0  w-[100%] md:w-[80%] mx-auto bg-[#F5F5F5]  mb-2">
          <ServicePage serviceObj={MarketEntryObj} />
         
        </div>
        <Footer />

        <Bottom serviceObj={MarketEntryObj}/>

      </div>
    </>
  );
};

export default MarketEntryPage;
