import React from "react";
import CircleContainer from "./CircleContainer";
import { useMediaQuery } from "@mui/material";

const ImageContainer4 = () => {
  
  const isMobile = useMediaQuery("(max-width:724px)");
  const isTab = useMediaQuery("(max-width:1024px)");
  const isLaptop = useMediaQuery("(max-width:1224px)");
  return (
    <div
      className="relative shadow-lg"
      style={{
        borderRadius:isMobile?"10px": "20px",
        backgroundColor: "#F3F9FF",
        width: isMobile ? "130px" :"210px",
        height: isMobile ? "70px" :"125px",top:isMobile?"55px":"120px",left:isMobile?"65px":"50px"}}
    >
    
      <div className="flex justify-center p-2" style={{marginTop:"15px"}}>
        <img src="Images/guidanceSection/Image4.png" className="relative" style={{width:isMobile?"40px":"80px", height:isMobile?"40px":"80px" ,bottom:isMobile?"6px":"5px", right:isMobile?"2px":""}} alt="" />
        <img className="relative" src="Images/guidanceSection/text.png" style={{width:isMobile?"70px":"98px", height:isMobile?"35px":"50px",bottom:isMobile?"5px":"", left:isMobile?"2px":"", padding: "2px"}} alt="" />
      </div>

      <CircleContainer
        width={isMobile?"8px":"11px"}
        height={isMobile?"8px":"11px"}
        color={"#1C6ED0"}
        bottom={isMobile?"65px":"105px"} 
        left={isMobile?"5px":"10px"}
      />
      <CircleContainer
        width={isMobile?"4px":"6px"}
        height={isMobile?"4px":"6px"}
        color={"#1C6ED0"}
        bottom={isMobile?"70px":"110px"} 
        left={isMobile?"25px":"50px"}
      />
      <CircleContainer
        width={isMobile?"4px":"6px"}
        height={isMobile?"4px":"6px"}
        color={"#1C6ED0"}
        bottom={isMobile?"20px":"25px"} 
        left={isMobile?"8px":"20px"}
      />
      <CircleContainer
        width={isMobile?"2px":"3px"}
        height={isMobile?"2px":"3px"}
        color={"#1C6ED0"}
        bottom={isMobile?"35px":"40px"} 
        left={isMobile?"24px":"40px"}
      />
      <CircleContainer
        width={isMobile?"6px":"7px"}
        height={isMobile?"6px":"7px"}
        color={"#fbd883"}
        bottom={isMobile?"72px":"110px"} 
        left={isMobile?"15px":"20px"}
      />
      <CircleContainer
        width={isMobile?"6px":"11px"}
        height={isMobile?"6px":"11px"}
        color={"#fbd883"}
        bottom={isMobile?"35px":"45px"} 
        left={isMobile?"30px":"50px"}
      />
    </div>
  );
};

export default ImageContainer4;
