import './rating_box.css'
import Rating from '@mui/material/Rating';



const Rating_box=({ firstPart,middleLetter, lastPart})=>{
    return(
        <div className="rating-container">
            <div id='heading'><p>{firstPart}<span id='x'>{middleLetter}</span>{lastPart}</p></div> 
             <div className='rating-items' id='ratings'><p>Ratings</p><div id='stars'><Rating /></div></div>
            <div className='rating-items-clickable' style={{marginTop:"3rem",
            marginBottom:"3rem"}}>          
            </div>
        </div>
    )
}

export default Rating_box;