import React from "react";
import SecondSection_img1 from './assets/successful_img.png'
import SecondSection_img2 from './assets/owner_img.png'
import SecondSection_img3 from './assets/employee_card_img.png'
import SecondSection_img4 from './assets/real_estate_agency_img.png'
import Arrow_img from './assets/Arrow_img.png'
import { useMediaQuery } from "@mui/material";
import { useNavigate } from "react-router-dom";

function SecondHeroSection(){
    const navigate = useNavigate()
    let isTab=useMediaQuery('(max-width:1100px)');
    let isMobile=useMediaQuery('(max-width:700px)');
    return(
        <div className="flex items-center h-max w-full " style={{background:"linear-gradient(108.6deg, #FFFFFF 41.86%, rgba(64, 123, 255, 0.15) 67.35%, rgba(255, 199, 65, 0.15) 80.53%)",flexDirection:isMobile&&"column"||"row",
        paddingInline
        :"5%"}}>
            {/* left */}
        <div className="flex flex-col  h-max" style={{width:isMobile&&"100%"||isTab&&"50%"||"50%",paddingLeft:isMobile&&"10px"||"64px",paddingTop:isMobile&&"20px"||"32px",paddingBottom:isMobile&&"10px"||"32px", alignItems:"start"}}>
            {/* HeroSection Title*/}
                <div className="flex h-auto mb-4 font-medium text-left" style={{marginBottom:isMobile&&"5px"||"16px",
                fontFamily:"cabin"
                ,fontSize:isMobile&&"18px"||isTab&&"27px"||"35px",lineHeight:isMobile&&"30px"||isTab&&"35px"||"50px",color:"rgba(38, 50, 56, 1)",width:isMobile&&"100%"||"90%"}}><p><span style={{color:"rgba(28, 110, 208, 1)"}}>Customer Categories</span> We Serve</p></div>
            {/* HeroSection description */}
            <div className="flex h-auto mb-4 font-[500]" style={{marginBottom:isMobile&&"30px"||"16px",fontSize:isMobile&&"12px"||isTab&&"15px"||"15px",lineHeight:isMobile&&"15px"||isTab&&"20px"||"30px",color:"rgba(38, 50, 56, 0.75)",textAlign:"left",width:isMobile&&"100%"||"95%"}}>
                <p style={{marginRight:"40px"}}>Whether you're starting out or looking to grow, our tailored solutions will help you succeed. Visit us if you one of the following.</p>
            </div>
            {/* Download Button */}
            <div onClick={()=>navigate("/services")} className="flex items-center justify-center text-base bg-blue-700 text-white hover:scale-105 cursor-pointer" style={{height:"auto",width:isMobile&&"120px"||isTab&&"160px"||"200px",padding:isMobile&&"8px"||isTab&&"12px"||"10px",borderRadius:isMobile&&"8px"||isTab&&"12px"||"16px"}}>
                <p className="h-auto w-auto text mr-2" style={{fontSize:isMobile&&"10px"||isTab&&"12px"||"15px"}}>Explore Services</p>
                <img src={Arrow_img} style={{height:isMobile&&"20px"||isTab&&"30px"||"40px",width:isMobile&&"20px"||isTab&&"30px"||"40px"}}/>
            </div>
            </div>

            {/* Right */}
            {/* Mobile view */}
            {isMobile?<div className="flex h-auto mt-5 w-full overflow-x-scroll scrollbar-hide" style={{ scrollbarWidth: "none", msOverflowStyle: "none" }}>
                <div className="flex h-max shadow-lg flex-col items-center pt-2 pb-2 px-2 mr-4 bg-white cursor-pointer hover:scale-105 transition-all" style={{ width: "200px", flexShrink: 0 }}>
                    <p className="font-semibold mb-4 text-[#263238] " style={{fontSize: "18px", }}>Entrepreneurs</p>
                    <div className="flex">
                        <img src={SecondSection_img1} style={{ marginBottom: "20px", height: "auto", width: "100%" }}/>
                    </div>
                    <p className="font-medium text-center text-[#263238]" style={{ fontSize: "12px", letterSpacing: "0px" }}>Entrepreneurs seeking growth, join us.</p>
                </div>

                <div className="flex h-max shadow-lg flex-col items-center pt-2 pb-2 px-2 mr-4 bg-white cursor-pointer hover:scale-105 transition-all" style={{ width: "200px", flexShrink: 0 }}>
                    <p className="font-semibold mb-4 text-[#263238]" style={{ fontSize: "18px" }}>SMB’s</p>
                    <div className="flex">
                        <img src={SecondSection_img2} style={{ marginBottom: "20px", height: "auto", width: "100%" }}/>
                    </div>
                    <p className="font-medium text-center text-[#263238]" style={{ fontSize: "12px", letterSpacing: "0px" }}>SMBs aiming to scale, connect with us.</p>
                </div>

                <div className="flex h-max shadow-lg flex-col items-center pt-2 pb-2 px-2 mr-4 bg-white cursor-pointer hover:scale-105 transition-all" style={{ width: "200px", flexShrink: 0 }}>
                    <p className="font-semibold mb-4 text-[#263238]" style={{ fontSize: "18px" }}>Employed People</p>
                    <div className="flex">
                        <img src={SecondSection_img3} style={{ marginBottom: "20px", height: "auto", width: "100%" }}/>
                    </div>
                    <p className="font-medium text-center text-[#263238]" style={{ fontSize: "12px", letterSpacing: "0px" }}>Employed professionals looking to start, Visit us.</p>
                </div>

                <div className="flex h-max shadow-lg flex-col items-center pt-2 pb-2 px-2 mr-4 bg-white cursor-pointer hover:scale-105 transition-all" style={{ width: "200px", flexShrink: 0 }}>
                    <p className="font-semibold mb-4 text-[#263238]" style={{ fontSize: "18px" }}>Agency Owner</p>
                    <div className="flex">
                        <img src={SecondSection_img4} style={{ marginBottom: "20px", height: "auto", width: "100%" }} />
                    </div>
                    <p className="font-medium text-center text-[#263238]" style={{ fontSize: "12px", letterSpacing: "0px" }}>Agency owners needing solutions, reach out to us.</p>
                </div>
            </div>:<div className="grid relative h-max w-max mt-10 mb-10" style={{gridTemplateRows:"repeat(2,0.5fr)",gridTemplateColumns:"repeat(2,0.5fr)",rowGap:"30px",columnGap:"30px",left:isTab&&"10px"||"150px"}}>
            {/* cards */}
            <div className="flex h-[270px] w-[270px] shadow-lg flex-col items-center pt-2 pb-2 px-2 bg-white cursor-pointer hover:scale-110 transition-all" style={{borderTopLeftRadius:"10%",width:isTab&&"170px"||"220px"}}>
                <p className="font-semibold mb-4 text-[#263238]" style={{fontSize:isTab&&"18px"||"25px"}}>Entrepreneurs</p>
                <div className="flex h-[40%] w-[40%]"><img src={SecondSection_img1} style={{marginBottom:"20px",height:"auto",width:"100%"}}/></div>
                <p className="font-medium text-center text-[#263238]" style={{fontSize:isTab&&"14px"||"17px",letterSpacing:"0px"}}>Entrepreneurs seeking growth, join us.</p>
            </div>

            <div className="flex h-[270px] w-[270px] shadow-lg flex-col items-center pt-2 pb-2 px-2 bg-white cursor-pointer hover:scale-110 transition-all" style={{borderTopRightRadius:"10%",width:isTab&&"170px"||"220px"}}>
                <p className="font-semibold mb-4 text-[#263238]" style={{fontSize:isTab&&"18px"||"25px"}}>SMB’s</p>
                <div className="flex h-[40%] w-[40%]"><img src={SecondSection_img2} style={{marginBottom:"20px",height:"auto",width:"100%"}}/></div>
                <p className="font-medium text-center text-[#263238]" style={{fontSize:isTab&&"14px"||"17px",letterSpacing:"0px"}}>SMBs aiming to scale,connect with us</p>
            </div>

            <div className="flex h-[270px] w-[270px] shadow-lg flex-col items-center pt-2 pb-2 px-2 bg-white cursor-pointer hover:scale-110 transition-all" style={{borderBottomLeftRadius:"10%",width:isTab&&"170px"||"220px"}}>
                <p className="font-semibold mb-4 text-[#263238]" style={{fontSize:isTab&&"18px"||"25px"}}>Employed People</p>
                <div className="flex h-[40%] w-[40%]"><img src={SecondSection_img3} style={{marginBottom:"20px",height:"auto",width:"100%"}}/></div>
                <p className="font-medium text-center text-[#263238]" style={{fontSize:isTab&&"14px"||"17px",letterSpacing:"0px"}}>Employed professionals looking to start, Visit us</p>
            </div>

              <div className="flex h-[270px] w-[270px] shadow-lg flex-col items-center pt-2 pb-2 px-2 bg-white cursor-pointer hover:scale-110 transition-all" style={{borderBottomRightRadius:"10%",width:isTab&&"170px"||"220px"}}>
                <p className="font-semibold mb-4 text-[#263238]" style={{fontSize:isTab&&"18px"||"25px"}}>Agency Owner</p>
                <div className="flex h-[40%] w-[40%]"><img src={SecondSection_img4} style={{marginBottom:"20px",height:"auto",width:"100%"}}/></div>
                <p className="font-medium text-center text-[#263238]" style={{fontSize:isTab&&"14px"||"17px",letterSpacing:"0px"}}>Agency owners needing solutions, reach out to us</p>
            </div>
                 
            </div>}
           
        </div>
    )
}

export default SecondHeroSection;