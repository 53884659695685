import './booking_container.css'

const Booking_container=({firstPart, middleLetter,lastPart,index})=>{
    return(
        <div className='booking-container'>
            <div className='booking-title'>
                <div className="heading-content">
                    <div id='logo-border1'>
                        <div id="logo-border2"><p>{firstPart}<span>{middleLetter}</span>{lastPart}</p></div>
                    </div>
                    <p>Booking Details</p> 
                </div>
            </div>
            <div className='booking-content'>

                <div className='booking-inputs' id='top-input'>
                    <input type="text" className='input-box margin-left' placeholder='Full Name' />
                    <input type="text" className='input-box margin-right' placeholder='Contact Number'/>
                </div>
                <div className='booking-inputs'>
                    <input type="text" className='input-box margin-left' placeholder='Email Address' />
                    <input type="text" className='input-box margin-right' placeholder='Whatsapp Number' />

                </div>
                <div className='booking-inputs'>
                    <input type="text" className='input-box margin-left' placeholder='Name on Invoice' />
                    <input type="text" className='input-box margin-right' placeholder='GST number' />
                </div> 
                <div className='comment-area'>
                <input type="text" className='text-area' placeholder='Full Address'/>
                </div>
            </div>
        </div>
    );

}

export default Booking_container;