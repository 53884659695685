import React, { useEffect, useState } from "react";
import Viewer_HeroSection from '../../Components/pdfViewer/Viewer_HeroSection';
import PDF_Slider from '../../Components/pdfViewer/PDF_Slider'
import { useParams, useSearchParams } from "react-router-dom";

const data = [
    {
        id:"pet-care-sector",
        titleColored:"India's Booming Pet Care Sector:",
        titleBlack:"Comprehensive Market Insights and Predictions",
        descripition:"Discover the comprehensive analysis of the rapidly expanding pet care industry in India with our in-depth report. This presentation covers the latest trends, market size, growth drivers, challenges, and future opportunities in the Indian pet care sector, including pet food, grooming, healthcare, and accessories."
    }
    
    ]




function PDF_Viewer(){
    const [searchParams]= useSearchParams();
    const id = searchParams.get('id');

    const [dataToShow, setDatatoShow] = useState(null);

    useEffect(()=>{
        const matchedData= data.find((item)=>item.id===id);
        setDatatoShow(matchedData);


    },[id])
    console.log(id)
    return(
        <div className="flex flex-col font-cabin w-full" style={{height:"max-content"}}>
            <Viewer_HeroSection
            id={id}
            titleColored={dataToShow?.titleColored}
            titleBlack={dataToShow?.titleBlack}
            description={dataToShow?.description}
            

            />
            <PDF_Slider
                id={id}
            />
        </div>
    )
}

export default PDF_Viewer;