import React from "react";
import Herosection from "../Components/SelfServicePage/components/heroSection";
import About from "../Components/SelfServicePage/components/About";
import Benefits from "../Components/SelfServicePage/components/Benefits";
import Features from "../Components/SelfServicePage/components/Features";
import Works from "../Components/SelfServicePage/components/Works";

import Service from "../Components/SelfServicePage/components/Service";
import Faq from "../Components/SelfServicePage/components/Faq";

const ServicePage = ({serviceObj}) => {
  const {
    serviceName,
    icon,
    heroSection,
    aboutService,
    features,
    payemnetSummary,
    benefits,
    featuresOverview,
    faq,
    howItWorks,
    targetAudience,
  } = serviceObj;
  return (
    <div>
      <Herosection
        serviceName={serviceName}
        icon={icon}
        heroSection={heroSection}
      />
      <About
        aboutService={aboutService}
        features={features}
        serviceName={serviceName}
        payemnetSummary={payemnetSummary}
      />
      <Benefits benefits={benefits} />
      <Features featuresOverview={featuresOverview} serviceName={serviceName} />
      <Works works={howItWorks}/>
      <Service targetAudience={targetAudience} />
      <Faq faq={faq} />
    </div>
  );
};

export default ServicePage;
