export const BusinessFeasibilityObj={
    serviceName: "Business Feasibility Analysis",
    icon: "/Images/selfService/BusinessFeasibilityImages/businessFeasibility-icon.png",

    heroSection: {
        image: "/Images/selfService/BusinessFeasibilityImages/Business-Feasibility-HeroImg.png",
        message: "Every Idea Needs A Strong Foundation",
        heading: "Validate your Business Idea:",
        headingPortion: "Business Feasibility Analysis",
        highlightedText: "Assessing your business feasibility ensures you understand",
        content:
          "market conditions, financial viability, and operational needs before launching.",
      },

      aboutService: {
        content1:
          "Our Business Feasibility service provides aspiring entrepreneurs and small business owners with a thorough analysis of their business ideas. ",
        content2:
          "We help you evaluate market conditions, financial viability, and operational requirements, ensuring your concept stands a strong chance of success.",
      },

      features: [
        {
          icon: "/Images/selfService/BusinessFeasibilityImages/details/det1.png",
          title: "Feasibility Analysis",
          description:
            "A comprehensive review to assess the viability of your business idea.",
        },
        {
          icon: "/Images/selfService/BusinessFeasibilityImages/details/det4.png",
          title: "Market Research",
          description:
            "Analyze market trends and customer needs to inform your feasibility study.",
        },
        {
            icon: "/Images/selfService/BusinessFeasibilityImages/details/det2.png",
            title: "Financial Projections",
            description:
              "Develop detailed financial forecasts to understand potential profitability.",
          },
        {
          icon: "/Images/selfService/BusinessFeasibilityImages/details/det5.png",
          title: "Risk Assessment",
          description:
            "Identify potential risks and develop strategies to mitigate them.",
        },
        {
          icon: "/Images/selfService/BusinessFeasibilityImages/details/det3.png",
          title: "Operational Planning",
          description:
            "Outline the operational needs to execute your business plan effectively.",
        },
        {
          icon: "/Images/selfService/BusinessFeasibilityImages/details/det6.png",
          title: "Compliance Check",
          description:
            "Ensure your business complies with industry regulations and standards.",
        },
        
      ],

      payemnetSummary: {
        totalPayment: "5049",
        gst: "908",
        Payable: "5958",
      },

      benefits: {
        title: "Benefits of Business Feasibility Service",
        data: [
          {
            title: "Informed Decisions",
            description:
              "Maximize your chances of success with data-driven insights.",
            sno: "01",
          },
          {
            title: "Financial Clarity",
            description:
              "Understand potential revenue and expenses clearly.",
            sno: "02",
          },
          {
            title: "Minimized Risks",
            description:
              "Identify and address potential issues early on.",
            sno: "03",
          },
          {
            title: "Market Understanding",
            description: "Gain valuable insights into market needs and competition.",
            sno: "04",
          },
          {
            title: "Operational Readiness",
            description: "Prepare for effective implementation of your business plan.",
            sno: "05",
          },
          {
            title: "Expert Guidance",
            description: "Benefit from our team's industry knowledge and expertise.",
            sno: "06",
          },
        ],
      },







      featuresOverview: [
        {
          title: "Comprehensive Feasibility Reports",
          icon: "/Images/selfService/BusinessFeasibilityImages/features/ft1.png",
        },
        {
          title: "Detailed Market Research",
          icon: "/Images/selfService/BusinessFeasibilityImages/features/ft6.png",
        },
        {
          title: "Financial Projections",
          icon:  "/Images/selfService/BusinessFeasibilityImages/features/ft2.png",
         
        },
        {
          title: "Risk Management Strategies",
          icon: "/Images/selfService/BusinessFeasibilityImages/features/ft7.png",
        },
        {
          title: "Operational Planning Support",
          icon: "/Images/selfService/BusinessFeasibilityImages/features/ft3.png",
        },
        {
          title: "Compliance Analysis",
          icon: "/Images/selfService/BusinessFeasibilityImages/features/ft8.png",
        },
        {
          title: "Expert Consultation",
          icon: "/Images/selfService/BusinessFeasibilityImages/features/ft4.png",
        },
        {
          title: "Customizable Reports",
          icon: "/Images/selfService/BusinessFeasibilityImages/features/ft9.png",
        },
        {
          title: "Actionable Insights",
          icon: "/Images/selfService/BusinessFeasibilityImages/features/ft5.png",
        },
        {
          title: "Ongoing Support",
          icon: "/Images/selfService/BusinessFeasibilityImages/features/ft10.png",
        },
      ],


      howItWorks: [
        {
          bg:"/Images/selfService/IdeaValidationImages/IdeaValWorks/fg1.png",
          fg:"/Images/selfService/howItWorks/InitialConsultation.png",
          step: "Initial Consultation",
          description:
            "Discuss your business idea and objectives.",
        },
        {
          bg:"/Images/selfService/IdeaValidationImages/IdeaValWorks/fg2.png",
          fg:"/Images/selfService/howItWorks/MarketResearch.png",
          step: "Market Research",
          description:
            "Conduct market research and feasibility studies.",
        },
        {
          bg:"/Images/selfService/IdeaValidationImages/IdeaValWorks/fg3.png",
          fg:"/Images/selfService/howItWorks/ReportCreation.png",
          step: "Report Creation",
          description:
            "Create a detailed feasibility report outlining findings.",
        },
        {
          bg:"/Images/selfService/IdeaValidationImages/IdeaValWorks/fg4.png",
          fg:"/Images/selfService/howItWorks/StrategicReview2.png",
          step: "Strategic Review",
          description:
            "Review results and adjust your business plan accordingly.",
        },
      ],

      targetAudience: [
        {
          icon: "/Images/servicePage/targeticon1.png",
          iconMobile: "/Images/servicePage/targeticonMobile1.png",
          title: "Aspiring Entrepreneurs",
          content1: " Validate your business ideas before launching.",
          content2:
          "",
        },
        {
          icon: "/Images/servicePage/targeticon2.png",
          iconMobile: "/Images/servicePage/targeticonMobile2.png",
          title: "Small Business Owners",
          content1: "Assess new product or service launches.",
          content2:
          "",
        },
       
        
        
        {
         
          icon: "/Images/servicePage/targeticon3.png",
          iconMobile: "/Images/servicePage/targeticonMobile3.png",
          title: "Startups",
          content1:
          "Establish a solid foundation for your business strategy.",
          content2:
          "",
        },
        {
            icon: "/Images/servicePage/AngelInvestors.png",
            iconMobile: "/Images/servicePage/angelInvestorMobile.png",
            title: "Angel Investors",
            content1: "Ensure potential investments are viable. ",
            content2:
              "",
          },
      ],

      faq: [
        {
          que: "What is a business feasibility study?",
          ans: "A business feasibility study evaluates the viability of a business idea by analyzing market conditions, financial aspects, and operational requirements.",
        },
        {
          que: "Why is a feasibility analysis important?",
          ans: "It helps identify potential challenges and opportunities, ensuring informed decision-making before launching a business.",
        },
        {
          que: "How long does a feasibility study take?",
          ans: "Typically, it takes 2-4 weeks, depending on the complexity of the business idea and market conditions.",
        },
        {
          que: "Can this service help secure funding?",
          ans: "Yes, a thorough feasibility study can provide potential investors with the confidence needed to fund your project.",
        },
        {
            que: "What industries do you support?",
            ans: "We work with various industries, including tech, retail, and services, tailoring our approach to fit your specific needs.",
          },
          {
            que: "Do you provide ongoing support?",
            ans: "Yes, we offer ongoing consultation to help you implement the findings of your feasibility study effectively.",
          },
        
      ],
};