import { useMediaQuery } from "@mui/material";
import React from "react";

const ServicePageExperienceSection = () => {
  const isMobile = useMediaQuery("(max-width:547px)");
  const isTab = useMediaQuery("(max-width:1024px)");
  return (
    <div className="flex h-max w-full justify-around" style={{ gap:(isTab || isMobile)? "18px" : "198px",
     flexDirection:(isTab || isMobile) && "column-reverse" ,
     marginBottom:"84px",
     padding:(isMobile||isTab)&&"10px 10px"||"64px 64px"
     }}>
      <div>
        <img
          src="/Images/servicePage/experinceSection/banner2.png"
          alt="banner2"
        />
      </div>
      <div style={{ width: (isTab || isMobile)? "100%" : "400px"}}>
        <h2 style={{ fontSize:isMobile ? "18px" :"35px", fontWeight: "500", textAlign:isMobile? "center" : "start", marginBottom:isMobile? "25px" :"50px",}}>
          Your Bizowl
          <span style={{color:"#407BFF"}}> Experience</span>
        </h2>
        <div className="flex" style={{marginBottom:isMobile?"15px" : "35px", gap:"14px"}}>
          <img
            src="/Images/servicePage/experinceSection/checkCircle.png"
            alt=""
          style={{width:isMobile?"25px" : "25px", height:isMobile?"25px" : "25px"}}
          />
          <h2 style={{textAlign:"start", fontSize:isMobile? "12px" : "15px", color:"#455A64",fontFamily:"'Inter', sans-serif"}}>
            Access India’s only dedicated platform for entrepreneurship support
            and services.
          </h2>
        </div>
        <div className="flex" style={{marginBottom:isMobile?"18px" :"35px", gap:"14px"}}>
          <img
            src="/Images/servicePage/experinceSection/checkCircle.png"
            alt=""
            style={{width:isMobile?"25px" : "25px", height:isMobile?"25px" : "25px"}}
            />
          <h2 style={{textAlign:"start", fontSize:isMobile? "12px" : "15px", color:"#455A64",fontFamily:"'Inter', sans-serif"}}>
            Get All fundamental business services that prevent common startup
            failures.
          </h2>
        </div>
        <div className="flex" style={{marginBottom:isMobile?"18px" :"35px", gap:"14px"}}>
          <img
            src="/Images/servicePage/experinceSection/checkCircle.png"
            alt=""
            style={{width:isMobile?"25px" : "25px", height:isMobile?"25px" : "25px"}}
            />
          <h2 style={{textAlign:"start", fontSize:isMobile? "12px" : "15px", color:"#455A64",fontFamily:"'Inter', sans-serif"}}>
            Receive tailored services for every stage, from ideation to growth.
          </h2>
        </div>
        <div className="flex" style={{marginBottom:isMobile?"18px" :"35px", gap:"14px"}}>
          <img
            src="/Images/servicePage/experinceSection/checkCircle.png"
            alt=""
            style={{width:isMobile?"25px" : "25px", height:isMobile?"25px" : "25px"}}
          />
          <h2 style={{textAlign:"start", fontSize:isMobile? "12px" : "15px", color:"#455A64",fontWeight:"500",fontFamily:"'Inter', sans-serif"}}>
            Enjoy a seamless, user-friendly interface and exceptional user
            experience in every aspect of Bizowl's platform.
          </h2>
        </div>
      </div>
    </div>
  );
};

export default ServicePageExperienceSection;
