import React from "react";
import NavBar from "../Header/NavBar";
import Bottom from "./components/Bottom";
import Footer from "../Footer/Footer";
import { Helmet } from "react-helmet";
import ServicePage from "../../pages/ServicePage";
import { ProductDevelopmentObj } from "../../data/ProductDevelopment/ProductDevelopmentObj";

const ProductDevelopmentService = () => {
  return (
    <>
      <Helmet>
        <meta charset="UTF-8" />
        <meta name="viewport" content="width=device-width, initial-scale=1.0" />
        <title>
          Business Model Canvas | Build Your Business Model | Bizowl
        </title>
        <meta 
          name="description" 
          content="Create a customized business model canvas with Bizowl. Develop your strategy, customer segments, value propositions, and revenue streams to build a successful business." 
        />
        <meta 
          name="keywords" 
          content="Business Model Canvas, Business Model Strategy, Business Canvas, Business Model Canvas Online, Business Plan Canvas, Lean Startup Model Canvas, Business Model Framework, Business Model Development, Business Revenue Model, Build Business Strategy" 
        />
        <meta name="robots" content="index, follow" />
        <meta name="author" content="Bizowl" />
        <meta http-equiv="Content-Language" content="en" />
        <link rel="canonical" href="https://www.bizzowl.com/services/business-model-canvas" />
        <meta property="og:title" content="Business Model Canvas | Build Your Business Model | Bizowl" />
        <meta 
          property="og:description" 
          content="Create a professional business model canvas with Bizowl. Design your business strategy, customer segments, and revenue models for success." 
        />
        <meta 
          property="og:image" 
          content="https://res.cloudinary.com/dcduojrhf/image/upload/v1728309795/Business_Model_Canvas_nvcl0b.png" />
        <meta property="og:url" content="https://www.bizzowl.com/services/business-model-canvas" />
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:title" content="Business Model Canvas | Build Your Business Model | Bizowl" />
        <meta 
          name="twitter:description" 
          content="Design your business model canvas with Bizowl and refine your strategy for growth, profitability, and success." 
        />
        <meta 
          name="twitter:image" 
            content="https://res.cloudinary.com/dcduojrhf/image/upload/v1728309795/Business_Model_Canvas_nvcl0b.png" />
        <script type="application/ld+json">
          {`
  "@context": "https://schema.org",
  "@type": "Product",
  "name": "Business Model Canvas",
  "description": "Bizowl's Business Model Canvas helps startups create a structured business model, focusing on key areas like value proposition, revenue streams, and customer segments.",
  "brand": {
    "@type": "Organization",
    "name": "Bizowl",
    "sameAs": "https://bizzowl.com",
    "logo": "https://www.bizzowl.com/Images/logos/logo-of-BIZOWL--Business-Services.png"
  },
  "offers": {
    "@type": "Offer",
    "url": "https://bizzowl.com/services/business-model-canvas",
    "priceCurrency": "INR",
    "price": "5958",
    "priceValidUntil": "2024-12-31",
    "availability": "https://schema.org/InStock",
    "itemCondition": "https://schema.org/NewCondition"
  },
  "review": {
    "@type": "Review",
    "reviewRating": {
      "@type": "Rating",
      "ratingValue": "4.8",
      "bestRating": "5"
    },
    "author": {
      "@type": "Person",
      "name": "Team Bizowl"
    }
  },
  "aggregateRating": {
    "@type": "AggregateRating",
    "ratingValue": "4.7",
    "reviewCount": "38"
  },
  "category": "Business Model Development"
`}
        </script>
      </Helmet>
      <div className="bg-[#F5F5F5] w-[100vw] overflow-x-hidden ">
        <NavBar />
        <div className=" md:!p-16 p-3 pt-0  w-[100%] md:w-[80%] mx-auto bg-[#F5F5F5]  mb-2">
          <ServicePage serviceObj={ProductDevelopmentObj} />
        </div>
        <Footer />
        <Bottom serviceObj={ProductDevelopmentObj} />
      </div>
    </>
  );
};

export default ProductDevelopmentService;
