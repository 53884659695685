import { useMediaQuery } from "@mui/material";
import NavBar from "../Header/NavBar";
import likeSvg from "./assets/like.svg";
import Footer from "../Footer/Footer";
import { Helmet } from "react-helmet";

const ComingSoon = () => {
  const isMobile = useMediaQuery("(max-width:768px)");
  const isTab = useMediaQuery("(max-width:1024px)");

  return (
    <>
      <Helmet>
        <meta name="robots" content="noindex, nofollow" />
      </Helmet>
      <div className="bg-gradient-to-r from-blue-100 overflow-x-hidden">
        <NavBar />
        <div
          style={{ fontFamily: "'Cabin', sans-serif" }}
          className="flex flex-col justify-center items-center py-20"
        >
          <div>
            <img
              src={likeSvg}
              style={{
                width: isMobile ? "140px" : isTab && "150px",
                height: isMobile ? "140px" : isTab && "150px",
              }}
              className="w-60 h-60"
            />
          </div>
          <div>
            <p
              style={{ fontSize: isMobile ? "14px" : isTab && "16px" }}
              className="font-medium text-md my-2"
            >
              We are Still
            </p>
            <h1
              style={{ fontSize: isMobile ? "32px" : isTab && "42px" }}
              className="text-[#1C6ED0] text-5.5xl font-bold"
            >
              Working on This Service.
            </h1>
            <div
              style={{
                fontSize: isMobile ? "12px" : isTab && "18px",
                padding: isMobile ? "0rem 3rem" : isTab && "0rem 0.5rem",
              }}
              className="max-w-sm mx-auto font-medium text-md text-center py-4 leading-tight"
            >
              <p>
                We are working on this service, we shall be live with this
                pretty soon. Meanwhile you can explore other services and
                features.{" "}
              </p>
            </div>
          </div>

          <div
            style={{ padding: isMobile ? "0 6rem" : isTab && "0rem 4rem" }}
            className="w-[600px] flex justify-evenly my-2"
          >
            <button className="bg-[#1C6ED0] text-white rounded-xl px-6 py-2">
              Explore More
            </button>
            <button className="border-[3px] border-[#1C6ED0] border-solid bg-transparent text-[#1C6ED0] rounded-xl px-6 py-2">
              Talk to Someone
            </button>
          </div>
        </div>
        <Footer />
      </div>
    </>
  );
};

export default ComingSoon;
