import React from "react";

const PaymentSummaryCard = ({ totalPayment, gst }) => {
  const totalAmount = totalPayment + gst;

  return (
    <div className="bg-white shadow-md rounded-3xl text-blue-900">
      <div className="bg-blue-100 p-2 rounded-t-xl flex justify-center items-center">
        <h2 className="text-lg font-semi-bold">Payment Summary</h2>
      </div>
      <div className="p-4">
        <div className="flex justify-center mt-2 mb-2">
        <img src="/Images/logos/logo-of-BIZOWL--Business-Services.png" alt="logo" width="120px"/>
        </div>
        <h2 className="text-lg font-bold text-left">Idea Validation</h2>
        <div className="flex justify-between mb-2 mt-2">
          <p>Total Payment</p>
          <p>₹{totalPayment}</p>
        </div>
        <div className="flex justify-between mb-2">
          <p>GST (18%)</p>
          <p>₹{gst}</p>
        </div>
        <div className="flex justify-between gap-4 font-bold">
          <p>Payable Amount</p>
          <p>₹ {totalAmount.toFixed(2)}</p>
        </div>
      </div>
    </div>
  );
};

export default PaymentSummaryCard;
