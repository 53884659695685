export const supportPageMain = [
  {
    img: "Images/supportPage/supportMain1.png",
    head: "General Queries",
    data: "Whether it's a question or a concern, we're here to provide the support you need!",
    button: "Ask us anything!",
  },
  {
    img: "Images/supportPage/supportMain2.png",
    head: "Support",
    data: "Looking for assistance? You're in the right place. Let us know how we can help!",
    button: "Get Support",
  },
  {
    img: "Images/supportPage/supportMain3.png",
    head: "Sales Queries",
    data: "Sales inquiries? Consider them sorted with our expert assistance!",
    button: "Contact Sales Team",
  },
  {
    img: "Images/supportPage/supportMain4.png",
    head: "Partner Support",
    data: "Partnerships make us stronger! Connect with our support team for partner inquiries.",
    button: "Ask us anything!",
  },
];
export const supportPageMobobile = [
  {
    img: "Images/supportPage/supportMobile1.png",
    head: "General Queries",
    data: "Whether it's a question or a concern, we're here to provide the support you need!",
    button: "Ask us anything!",
  },
  {
    img: "Images/supportPage/supportMobile2.png",
    head: "Support",
    data: "Looking for assistance? You're in the right place. Let us know how we can help!",
    button: "Get Support",
  },
  {
    img: "Images/supportPage/supportMobile3.png",
    head: "Sales Queries",
    data: "Sales inquiries? Consider them sorted with our expert assistance!",
    button: "Contact Sales Team",
  },
  {
    img: "Images/supportPage/supportMobile4.png",
    head: "Partner Support",
    data: "Partnerships make us stronger! Connect with our support team for partner inquiries.",
    button: "Ask us anything!",
  },
];

