import useServicesStore from '../../Views/Services/storePr'
import './summary.css'
import {useNavigate} from "react-router-dom"

const Pm_summary=({firstPart,middleLetter, lastPart, index})=>{
    const {servicesData, setServicesData } = useServicesStore();
    const data= Object.entries(servicesData[0].packages).map(([pkgIdx,pkgData])=> pkgData.features)
    
    const navigate = useNavigate();
    return(
        <div id='summary-container'>
            <div id='summary-heading'>
                <p>Payment summary</p>
            </div> 
 
            <div className='summary-items'>
            <div className='summary-content'>
            <div className='summary-logo'><p>{firstPart}<span>{middleLetter}</span>{lastPart}</p></div>
            </div> 
            <div className='press-release-content'>
            <div className='press-release-section'><p>Press Release Distribution</p></div>
            </div>
            <div className='summary-content'>
            <div className='summary-item1'><p>Total Payment</p></div>
            <div className='summary-item2'><p>₹{data[0].price}/-</p></div> 
            </div>
            <div className='summary-content'>
            <div className='summary-item1'><p>GST (18%)</p></div>
            <div className='summary-item2'><p>₹{((data[0].price * 18)/100).toFixed(2)}/-</p></div>
            </div>
            </div>
            <div id='total'>
            <div className='total-item1'><p>Payable amount</p></div>
            <div className='total-item2'><p>₹{(((data[0].price * 18)/100)+data[0].price).toFixed(2)}/-</p></div>
            </div> 
            <div id='btn-container'
            onClick={()=>navigate(`/services/press-release-distribution/quote-details/service-summary/book-service/?id=${servicesData[0].id}&index=${index}`)}>
            <button id='proceed'>Proceed</button>
            </div>
        </div>
    );
}

export default Pm_summary;